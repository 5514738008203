<template>
  <div id="app">
    <div class="px-4 py-2">
      <label for="crud-form-boat-name" class="form-label"
        >Boat Name <span class="text-theme-6">*</span></label
      >
      <input
        v-model="validate.boatName.$model"
        id="crud-form-boat-name"
        type="text"
        class="form-control w-full"
        placeholder="Boat name"
      />
    </div>
    <div class="px-4 py-2">
      <label for="crud-form-boat-code" class="form-label">Boat Code</label>
      <input
        v-model="formData.boatCode"
        id="crud-form-boat-code"
        type="text"
        class="form-control w-full"
        placeholder="Boat code"
      />
    </div>
    <div class="px-4 py-2">
      <label for="crud-form-boat-status" class="form-label">Status</label>
      <v-select
        id="crud-form-boat-status"
        v-model="formData.status"
        :reduce="(option) => option?.label"
        :options="[{ label: 'in use' }, { label: 'out of use' }]"
      >
      </v-select>
    </div>
    <div class="px-4 py-2">
      <label for="crud-form-1" class="form-label">Crew Weight</label>
      <input
        v-model="formData.crewWeight"
        id="crud-form-1"
        type="text"
        class="form-control w-full"
        placeholder="Crew weight"
      />
    </div>
    <div class="px-4 py-2">
      <label for="crud-form-1" class="form-label"
        >Boat Type <span class="text-theme-6">*</span></label
      >
      <TomSelect
        v-model="validate.boatType.$model"
        :options="{
          placeholder: 'Select Boat Type',
          row: 1
        }"
        class="w-full"
        :class="{
          'border-theme-6': validate.boatType.$error
        }"
      >
        <div v-for="type in boatTypes" :key="type.id">
          <option :value="type.boatType">
            Type: {{ type.boatType }} | <span v-if="type.cox">Coxed</span
            ><span v-else>Not Coxed</span>
            |
            <span v-if="type.sweep">Sweep</span><span v-else>Scull</span> | Crew
            Size:
            {{ type.crewSize }}
          </option>
        </div>
      </TomSelect>
    </div>
    <div class="px-4 py-2">
      <label for="crud-form-make" class="form-label">Make</label>
      <input
        v-model="formData.make"
        id="crud-form-make"
        type="text"
        class="form-control w-full"
      />
    </div>
    <div class="px-4 py-2">
      <label for="crud-form-owner" class="form-label">Owner</label>
      <v-select
        id="crud-form-owner"
        v-model="formData.owner"
        label="fullName"
        :reduce="(option) => option?.fullName"
        :options="athletes"
      >
      </v-select>
    </div>
    <div class="px-4 py-2">
      <label for="crud-form-note" class="form-label">Note</label>
      <input
        v-model="formData.note"
        id="crud-form-note"
        type="text"
        class="form-control w-full"
      />
    </div>
    <div class="px-4 py-2">
      <label for="crud-form-year" class="form-label">Year</label>
      <input
        v-model="formData.year"
        id="crud-form-year"
        type="text"
        class="form-control w-full"
      />
    </div>
    <div class="font-light text-theme-6 px-4 py-2">
      <p>Fields marked with '*' are required</p>
    </div>
    <div
      v-if="isAvailable"
      role="button"
      class="text-right p-4"
      @click="disable()"
    >
      <button
        @click="addInventory()"
        type="submit"
        id="submit"
        class="btn btn-primary w-24"
      >
        Save
        <LoadingIcon
          v-if="loading.value"
          icon="oval"
          class="w-4 h-4 ml-4 text-white"
        />
      </button>
    </div>
  </div>
  <!-- BEGIN: Failed Notification Content -->
  <div id="failed-notification-content" class="toastify-content hidden flex">
    <XCircleIcon class="text-theme-6" />
    <div class="ml-4 mr-4">
      <div class="font-medium">Please fill requirements</div>
      <div class="text-gray-600 mt-1">Please check the form.</div>
    </div>
  </div>
  <!-- END: Failed Notification Content -->
</template>

<script>
import { computed, onMounted, reactive, toRefs } from 'vue'
import { useStore } from '@/store'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import Toastify from 'toastify-js'

export default {
  name: 'add inventory - boats',
  data() {
    return {
      type: 'boats'
    }
  },
  props: {
    boatTypes: Object,
    isAvailable: Boolean,
    athletes: Object
  },
  methods: {
    disable() {
      if (this.formData.boatName !== '' && this.formData.boatType !== '') {
        this.loading.value = true
        document.getElementById('submit').disabled = true
      }
    }
  },
  watch: {
    boatTypes() {
      if (this.boatTypes.length > 0) {
        this.formData.boatType = this.boatTypes[0].boatType
      }
    }
  },
  setup() {
    const store = useStore()
    const role = computed(() => store.state.profile.user?.userRole?.name)
    var loading = reactive({ value: false })
    onMounted(() => {
      store.dispatch('athletes/getAthletesData')
      store.dispatch('profile/getProfileData')
      store.dispatch('inventories/getBoatTypes')
    })
    const formData = reactive({
      boatName: '',
      boatCode: '',
      status: 'in use',
      crewWeight: '',
      boatType: '',
      make: '',
      owner: '',
      note: '',
      year: ''
    })
    const rulesOfBoat = {
      boatName: {
        required
      },
      boatType: {
        required
      }
    }
    const validate = useVuelidate(rulesOfBoat, toRefs(formData))
    const addInventory = () => {
      validate.value.$touch()
      if (validate.value.$invalid) {
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
      } else {
        store.dispatch('inventories/addBoats', formData)
      }
    }
    return {
      formData,
      validate,
      loading,
      addInventory,
      role
    }
  }
}
</script>
