import axios from 'axios'
import Toastify from 'toastify-js'
export const ATHLETES = 'ATHLETES'
export const ATHLETE_DETAIL = 'ATHLETE_DETAIL'
export const UPDATE_ATHLETE_DETAIL = 'UPDATE_ATHLETE_DETAIL'
export const UPDATE_ROLE = 'UPDATE_ROLE'

const state = () => {
  return {
    athletes: [],
    athleteDetail: {},
    athleteDetailLoaded: { message: null },
    athletesLoaded: {
      message: null
    }
  }
}

// getters
const getters = {
  athletes: state => {
    return state.athletes
  },
  athleteDetail: state => {
    return state.athleteDetail
  }
}

// actions
const actions = {
  getAthletesData({ commit }, data) {
    commit(ATHLETES, data)
  },
  getAthleteDetail({ commit }, data) {
    commit(ATHLETE_DETAIL, data)
  },
  updateAthleteDetail({ commit }, params) {
    commit(UPDATE_ATHLETE_DETAIL, params)
  },
  updateRole({ commit }, params) {
    commit(UPDATE_ROLE, params)
  }
}

// mutations
const mutations = {
  [ATHLETES](state, data) {
    axios
      .get(process.env.VUE_APP_API_BASE_URL + '/api/user/list')
      .then(response => {
        if (response.data.success) {
          state.athletes = response.data.data
        }
        if (response.status == 200) {
          state.athletesLoaded.message = true
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
  [ATHLETE_DETAIL](state, id) {
    axios
      .get(process.env.VUE_APP_API_BASE_URL + '/api/user/' + id)
      .then(response => {
        if (response.data.success) {
          state.athleteDetail = response.data.data
        }
        if (response.status == 200) {
          state.athleteDetailLoaded.message = true
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
  [UPDATE_ATHLETE_DETAIL](state, params) {
    axios.put(process.env.VUE_APP_API_BASE_URL + '/api/athlete/' + params.id, {
      role: params.role,
      phoneNumber: params.phoneNumber,
      emergencyNumber: params.emergencyNumber,
      emergencyName: params.emergencyName,
      address: params.address,
      email: params.email
    })
      .then(response => {
        if (response.data.message) {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#91C714',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        } else {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#D32929',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        }
      })
      .catch(err => {
        console.log(err)
      })
  },
  [UPDATE_ROLE](state, params) {
    axios.put(process.env.VUE_APP_API_BASE_URL + '/api/user/role?userId=' + params.id, {
      code: params.role
    })
      .then(response => {
        if (response.data.message) {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#91C714',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        } else {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#D32929',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        }
      })
      .catch(err => {
        console.log(err)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
