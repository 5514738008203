<template>
  <div id="app">
    <div class="w-full">
      <div class="my-2">
        <span class="inline-flex justify-center mx-2">
          <router-link :to="{ path: '/your-events' }">
            <div
              class="inline-block gap-1 border border-1 border-black rounded"
            >
              <ArrowLeftIcon />
            </div>
            <span class="inline-block mx-2 align-top mt-1">Your Events</span>
          </router-link>
        </span>
        <div>
          <div class="intro-y flex items-center mt-8">
            <!-- START: loading animation -->
            <div v-if="!isLoaded" disabled>
              <div class="flex-row">
                <div
                  class="py-1 text-lg bg-theme-8 my-2 px-2 w-72 h-6 blob"
                ></div>
              </div>
            </div>
            <!-- END: loading animation -->
            <h2 v-if="isLoaded" class="flex text-xl font-medium mr-auto px-2">
              {{ event?.eventDate }}
              <ArrowRightIcon class="mx-2 pt-1" /> {{ event?.eventEndDate }}
            </h2>
          </div>
          <div class="grid grid-cols-12 gap-6">
            <!-- START: Side Menu -->
            <div
              class="col-span-12 lg:col-span-4 2xl:col-span-3 flex lg:block flex-col-reverse"
            >
              <div class="intro-y box mt-5">
                <div class="relative flex items-center p-5">
                  <div class="w-12 h-12 text-theme-1">
                    <h3 v-if="event.type == 'ergo'">
                      <img src="../../assets/images/ergo.png" alt="ergo icon" class="w-12">
                    </h3>
                    <h3 v-if="event.type == 'water'">
                      <img src="../../assets/images/water.png" alt="water icon" class="w-12">
                    </h3>
                    <h3 v-if="event.type == 'training'">
                      <SettingsIcon class="w-6 h-6 mt-3 ml-3" />
                    </h3>
                    <h3 v-if="event.type == 'race'">
                      <img src="../../assets/images/race.png" alt="race icon" class="w-12">
                    </h3>
                    <h3 v-if="event.type == 'social'">
                      <UsersIcon class="w-6 h-6 mt-3 ml-4"/>
                    </h3>
                  </div>
                  <div class="mr-auto">
                    <div v-if="!isLoaded" disabled>
                      <div class="flex-row">
                        <div
                          class="py-1 text-lg bg-theme-1 px-2 w-64 h-4 blob"
                        ></div>
                      </div>
                    </div>
                    <div
                      v-if="isLoaded"
                      class="font-medium text-base text-theme-1 px-2"
                    >
                      {{ event.name }}
                      <span
                        class="font-normal text-sm"
                        :class="{
                          //loading state
                          hidden:
                            event.type == '' ||
                            event.type == null ||
                            event.type == undefined
                        }"
                        >({{ event.type }})</span
                      >
                    </div>
                  </div>
                </div>
                <div class="p-5 border-t border-gray-200 dark:border-dark-5">
                  <span
                    v-on:click="this.type = 'information'"
                    :class="
                      type === 'information'
                        ? 'text-theme-1 border-r-2 border-theme-1'
                        : 'text-theme-16 border-r-2 border-transparent hover:border-theme-3 hover:text-theme-3 cursor-pointer'
                    "
                    class="inline-block px-2 p-2 text-md font-medium w-full"
                  >
                    <InfoIcon class="w-4 h-4 mr-2 inline-block" /> Event
                    Information
                  </span>
                  <span
                    v-on:click="this.type = 'line-up'"
                    :class="
                      type === 'line-up'
                        ? 'text-theme-1 border-r-2 border-theme-1'
                        : 'text-theme-16 border-r-2 border-transparent hover:border-theme-3 hover:text-theme-3 cursor-pointer'
                    "
                    class="inline-block px-2 p-2 text-md font-medium w-full"
                  >
                    <TrelloIcon class="w-4 h-4 mr-2 inline-block" /> Event
                    Line-up
                  </span>
                </div>
              </div>
            </div>
            <!-- END: Side Menu -->
            <div class="col-span-12 lg:col-span-8 2xl:col-span-9">
              <!-- START: Event Information -->
              <div
                v-if="this.type == 'information'"
                class="intro-y box lg:mt-5"
              >
                <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                  <div class="float-right" :disabled="event.locked">
                    <span v-for="rsvp in event.rsvps" :key="rsvp.id">
                      <span v-if="rsvp.userId == userId">
                        <button class="text-sm text-center text-theme-9">
                          <div
                            class="dropdown inline-block"
                            data-placement="bottom"
                          >
                            <button
                              v-if="rsvp.rsvp == 'Y'"
                              id="toggle"
                              class="btn btn-success w-32"
                              :disabled="event.locked"
                              :class="
                                event.locked == true
                                  ? 'text-white'
                                  : 'text-white dropdown-toggle'
                              "
                            >
                              <a class="flex">
                                going
                                <LoadingIcon
                                  v-if="loading?.value"
                                  icon="oval"
                                  class="flex w-4 h-4 ml-2 mt-1 text-white"
                                />
                              </a>
                            </button>
                            <button
                              v-if="rsvp.rsvp == 'N'"
                              id="toggle"
                              class="btn btn-danger w-32"
                              :disabled="event.locked"
                              :class="
                                event.locked == true
                                  ? 'text-white'
                                  : 'text-white dropdown-toggle'
                              "
                            >
                              <a class="flex">
                                not going
                                <LoadingIcon
                                  v-if="loading?.value"
                                  icon="oval"
                                  class="flex w-4 h-4 ml-2 mt-1 text-white"
                                />
                              </a>
                            </button>
                            <button
                              v-if="rsvp.rsvp == 'NR'"
                              id="toggle"
                              class="btn btn-primary w-32"
                              :disabled="event.locked"
                              :class="
                                event.locked == true
                                  ? 'text-white'
                                  : 'text-white dropdown-toggle'
                              "
                            >
                              <a class="flex">
                                no response
                                <LoadingIcon
                                  v-if="loading?.value"
                                  icon="oval"
                                  class="flex w-4 h-4 ml-2 mt-1 text-white"
                                />
                              </a>
                            </button>
                            <button
                              v-if="rsvp.rsvp == 'T'"
                              id="toggle"
                              class="btn btn-warning w-32"
                              :disabled="event.locked"
                              :class="
                                event.locked == true
                                  ? 'text-white'
                                  : 'text-white dropdown-toggle'
                              "
                            >
                              <a class="flex">
                                tentative
                                <LoadingIcon
                                  v-if="loading?.value"
                                  icon="oval"
                                  class="flex w-4 h-4 ml-2 mt-1 text-white"
                                />
                              </a>
                            </button>
                            <div class="dropdown-menu w-40">
                              <div
                                @click="disable()"
                                class="dropdown-menu__content box dark:bg-dark-1"
                              >
                                <button
                                  @click="updateRsvp((formData.rsvp = 'Y'))"
                                  class="block p-2 w-full transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                                  data-dismiss="dropdown"
                                >
                                  going
                                </button>
                                <button
                                  @click="updateRsvp((formData.rsvp = 'N'))"
                                  class="block p-2 w-full transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                                  data-dismiss="dropdown"
                                >
                                  not going
                                </button>
                                <button
                                  @click="updateRsvp((formData.rsvp = 'NR'))"
                                  class="block p-2 w-full transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                                  data-dismiss="dropdown"
                                >
                                  no response
                                </button>
                                <button
                                  @click="updateRsvp((formData.rsvp = 'T'))"
                                  class="block p-2 w-full transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                                  data-dismiss="dropdown"
                                >
                                  tentative
                                </button>
                              </div>
                            </div>
                          </div>
                        </button>
                      </span>
                    </span>
                  </div>
                  <h2 class="font-medium text-base mr-auto">
                    Event Information
                  </h2>
                </div>
                <EventInformation />
              </div>
              <div v-if="this.type == 'information'" class="mt-3">
                <div v-if="isAvailable" class="flex justify-end mt-4 px-2 mb-4">
                  <a
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-modal-preview"
                    @click="this.selectedEvent = event.id"
                    class="text-theme-6 flex items-center mr-4"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" /> Delete Event
                  </a>
                  <a
                    v-if="event.recurring"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-all-modal-preview"
                    @click="this.selectedRecurringEvent = event.parentEventId"
                    class="text-theme-6 flex items-center"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" /> Delete All Recurring
                    Events
                  </a>
                </div>
              </div>
              <!-- END: Event Information -->
              <div v-if="this.type == 'line-up'" class="intro-y box lg:mt-5">
                <LineUp :event="event" />
              </div>
            </div>
            <!-- START: DELETE EVENT-->
            <div id="delete-modal" class="p-5">
              <div class="preview">
                <!-- START: Modal Content -->
                <div
                  id="delete-modal-preview"
                  class="modal"
                  tabindex="-1"
                  aria-hidden="true"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-body p-0">
                        <div class="p-5 text-center">
                          <XCircleIcon
                            class="w-16 h-16 text-theme-6 mx-auto mt-3"
                          />
                          <div class="text-3xl mt-5">Are you sure?</div>
                          <div class="text-gray-600 mt-2">
                            This process cannot be undone.
                          </div>
                        </div>
                        <div class="px-5 pb-8 text-center">
                          <button
                            type="button"
                            data-dismiss="modal"
                            class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1"
                          >
                            Cancel
                          </button>
                          <button
                            @click="deleteEvent(this.selectedEvent)"
                            type="button"
                            class="btn btn-danger w-24"
                            data-dismiss="modal"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END: Modal Content -->
              </div>
            </div>
            <!-- END: DELETE ALL RECURRING EVENTS -->
            <div id="delete-modal-2" class="p-5">
              <div class="preview">
                <!-- START: Modal Content -->
                <div
                  id="delete-all-modal-preview"
                  class="modal"
                  tabindex="-1"
                  aria-hidden="true"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-body p-0">
                        <div class="p-5 text-center">
                          <XCircleIcon
                            class="w-16 h-16 text-theme-6 mx-auto mt-3"
                          />
                          <div class="text-3xl mt-5">Are you sure?</div>
                          <div class="text-gray-600 mt-2">
                            This process cannot be undone.
                          </div>
                        </div>
                        <div class="px-5 pb-8 text-center">
                          <button
                            type="button"
                            data-dismiss="modal"
                            class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1"
                          >
                            Cancel
                          </button>
                          <button
                            @click="
                              deleteAllRecurringEvents(
                                this.selectedRecurringEvent
                              )
                            "
                            type="button"
                            class="btn btn-danger w-24"
                            data-dismiss="modal"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END: Modal Content -->
              </div>
            </div>
            <!-- END: DELETE ALL RECURRING EVENTS -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.blob {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
</style>

<script>
import EventInformation from './EventInformation.vue'
import LineUp from './LineUp.vue'
import { computed, onMounted, reactive, watch } from 'vue'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'

export default {
  name: 'Event Detail',
  components: {
    EventInformation,
    LineUp
  },
  data() {
    return {
      type: 'information',
      isLoaded: false
    }
  },
  methods: {
    disable() {
      this.loading.value = true
      document.getElementById('toggle').disabled = true
    }
  },
  watch: {
    isSuccess() {
      this.loading.value = false
      document.getElementById('toggle').disabled = false
    },
    deleteSuccess() {
      if (this.deleteSuccess) {
        this.$router.back()
      }
    },
    deleteAllRecurringsSuccess() {
      if (this.deleteAllRecurringEvents) {
        this.$router.back()
      }
    },
    event() {
      if (this.event.eventDate.length > 0) {
        this.isLoaded = true
      }
    }
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const event = computed(() => store.state.events.eventDetail)
    const role = computed(() => store.state.profile.user?.userRole?.name)
    let myEvent = reactive({
      detail: event
    })
    const isSuccess = computed(() => store.state.events.updateRsvp.isSuccess)
    const isAvailable = computed(
      () =>
        store.state.profile.user?.userRole?.name == 'Coach' ||
        store.state.profile.user?.userRole?.name == 'Super Admin'
    )

    const deleteSuccess = computed(
      () => store.state.events.deleteEvent.isSuccess
    )
    const deleteAllRecurringsSuccess = computed(
      () => store.state.events.deleteAllRecurringEvents.isSuccess
    )
    const userId = computed(() => store.state.profile.user?.id)
    var loading = reactive({ value: false })
    onMounted(() => {
      store.dispatch('profile/getProfileData')
      store.dispatch('inventories/getBoatsData')
      store.dispatch('inventories/getBoatTypes')
      store.dispatch('events/getEventDetail', route.params.id)
    })
    const formData = reactive({
      id: route.params.id,
      rsvp: ''
    })
    const updateRsvp = () => {
      store.dispatch('events/updateEvent', formData)
    }
    const deleteEvent = (eventId) => {
      store.dispatch('events/deleteEvent', eventId)
      store.dispatch('events/getEventLineUps', route.params.id)
    }
    const deleteAllRecurringEvents = (eventId) => {
      store.dispatch('events/deleteAllRecurringEvents', eventId)
    }
    watch(
      () => formData.rsvp,
      (rsvp, previous) => {
        myEvent = store.state.events.eventDetail
        const index = myEvent.rsvps.findIndex(
          (x) => x.userId == store.state.profile.user?.id
        )
        myEvent.rsvps[index].rsvp = formData.rsvp
      }
    )
    return {
      formData,
      updateRsvp,
      deleteEvent,
      deleteAllRecurringEvents,
      deleteSuccess,
      deleteAllRecurringsSuccess,
      route,
      userId,
      isSuccess,
      event,
      loading,
      myEvent,
      role,
      isAvailable
    }
  }
}
</script>
