<template>
  <!-- START: loading animation -->
  <div v-if="!isLoaded" disabled>
    <ul v-for="el in 8" :key="el.id" class="w-full py-2 mx-4 border-b">
      <li>
        <div class="flex-row">
      <li class="py-1 text-lg bg-theme-1 my-2 px-2 w-64 h-6 blob"></li>
  </div>
  </li>
  <li>
    <span v-for="el in 2" :key="el.id" class="inline-block">
  <li class="py-1 text-sm bg-theme-8 mr-2 px-2 w-32 h-6 blob"></li>
  </span>
  </li>
  <li>
    <span v-for="el in 3" :key="el.id" class="inline-block">
      <p class="py-1 text-sm bg-theme-8 mr-2 px-2 w-48 h-6 blob"></p>
    </span>
  </li>
  </ul>
  </div>
  <!-- END: loading animation -->
</template>

<script>
export default {
  name: 'LoadingAnimation',
  props: {
    isLoaded: Boolean
  }
}
</script>
<style scoped>
.blob {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
</style>
