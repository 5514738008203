<template>
  <div class="px-2 py-2">
    <!-- router -->
    <div v-if="isAvailable" class="float-right mx-2 mt-2">
      <router-link
        :to="{ path: '/add-group' }"
        class="
          btn
          border border-black
          font-bold
          px-2
          py-1
          text-sm
          outline-none
          rounded
          focus:outline-none
          ease-linear
          transition-all
          duration-150
        "
      >
        <div class="flex gap-1"><PlusIcon />Add Group</div>
      </router-link>
    </div>
  </div>
  <div v-if="role == 'Athlete'" class="p-10">
    <div class="flex justify-center">
      <AlertCircleIcon class="w-8 h-8 my-4" />
    </div>
    <div class="flex justify-center text-center">
      You do not have permission to view this page
    </div>
  </div>
  <div v-if="isAvailable" class="flex px-2 my-4 py-8 w-full">
    <ul class="w-full">
      <span class="flex justify-center font-bold p-2">Group Name</span>
      <!-- START: EMPTY LIST -->
      <div v-if="isLoaded && this.userGroups.length < 1">
        <div class="w-full p-4">
          <div class="flex justify-center mt-6">
            <UserXIcon class="w-12 h-12" />
          </div>
          <div class="flex justify-center text-lg p-4">
            You are not added to any groups yet
          </div>
        </div>
      </div>
      <!-- END: EMPTY LIST -->
      <div v-if="!isLoaded">
        <li v-for="el in 7" :key="el.id" class="rounded-lg p-2 m-2">
          <span class="w-full">
            <p class="py-1 text-sm bg-theme-8 mr-2 px-2 h-6 blob"></p>
          </span>
        </li>
      </div>
      <div v-if="isLoaded">
        <li
          v-for="group in userGroups"
          :key="group.id"
          class="border border-theme-15 bg-white rounded-lg p-2 m-2"
        >
          <router-link :to="{ path: '/group-detail/' + group.id }">
            <div class="flex justify-center">{{ group.groupName }}</div>
          </router-link>
        </li>
      </div>
    </ul>
  </div>
</template>

<style scoped>
.blob {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
</style>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from '@/store'

export default {
  name: 'user-groups',
  data() {
    return {
      isLoaded: false
    }
  },
  watch: {
    userGroups() {
      if (this.$store.state.groups.groupsLoaded.message) {
        this.isLoaded = true
      }
    }
  },
  setup() {
    const store = useStore()
    const role = computed(() => store.state.profile.user?.userRole?.name)
    const userGroups = computed(() => store.state.groups.groups)
    const isAvailable = computed(() => (store.state.profile.user?.userRole?.name == 'Coach' || store.state.profile.user?.userRole?.name == 'Super Admin'))

    onMounted(() => {
      store.dispatch('profile/getProfileData')
      store.dispatch('groups/getGroupsData')
    })
    return {
      userGroups,
      role,
      isAvailable
    }
  }
}
</script>
