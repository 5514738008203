import axios from 'axios'
import Toastify from 'toastify-js'
export const GROUPS = 'GROUPS'
export const GROUP_DETAIL = 'GROUP_DETAIL'
export const ATHLETES_IN_GROUP = 'ATHLETES_IN_GROUP'
export const DELETE_GROUP_FROM_ATHLETE = 'DELETE_GROUP_FROM_ATHLETE'
export const ADD_ATHLETES_TO_GROUP = 'ADD_ATHLETES_TO_GROUP'
export const ADD_GROUP = 'ADD_GROUP'
export const DELETE_USER_GROUP = 'DELETE_USER_GROUP'

const state = () => {
  return {
    groups: [],
    groupDetail: {},
    athletesInGroup: [],
    addGroup: {
      isSuccess: null
    },
    addAthletesToGroup: {
      isSuccess: null
    },
    deleteGroup: {
      isSuccess: null
    },
    detailLoaded: {
      message: null
    },
    groupsLoaded: {
      message: null
    }
  }
}

// getters
const getters = {
  groups: state => {
    return state.groups
  },
  athletesInGroup: state => {
    return state.athletesInGroup
  },
  groupDetail: state => {
    return state.groupDetail
  }
}

// actions
const actions = {
  getGroupsData({ commit }, data) {
    commit(GROUPS, data)
  },
  getGroupDetail({ commit }, id) {
    commit(GROUP_DETAIL, id)
  },
  getAthletesData({ commit }, id) {
    commit(ATHLETES_IN_GROUP, id)
  },
  deleteGroupFromAthlete({ commit }, params) {
    commit(DELETE_GROUP_FROM_ATHLETE, params)
  },
  addAthletesToGroup({ commit }, params) {
    commit(ADD_ATHLETES_TO_GROUP, params)
  },
  addGroup({ commit }, params) {
    commit(ADD_GROUP, params)
  },
  deleteUserGroup({ commit }, params) {
    commit(DELETE_USER_GROUP, params)
  }
}

// mutations
const mutations = {
  [GROUPS](state, data) {
    axios
      .get(process.env.VUE_APP_API_BASE_URL + '/api/user-groups')
      .then(response => {
        state.groups = response.data
        if (response.status == 200) {
          state.groupsLoaded.message = true
        }
        state.detailLoaded.message = false
      })
      .catch(error => {
        console.log(error)
      })
  },
  [GROUP_DETAIL](state, id) {
    axios
      .get(process.env.VUE_APP_API_BASE_URL + '/api/user-group/' + id)
      .then(response => {
        state.groupDetail = response.data
        if (response.status == 200) {
          state.detailLoaded.message = true
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
  [ATHLETES_IN_GROUP](state, id) {
    axios
      .get(process.env.VUE_APP_API_BASE_URL + '/api/user-group/athlete/list/' + id)
      .then(response => {
        state.athletesInGroup = response.data.data
      })
      .catch(error => {
        console.log(error)
      })
  },
  [ADD_GROUP](state, params) {
    axios
      .post(process.env.VUE_APP_API_BASE_URL + '/api/user-group', {
        groupName: params.name
      })
      .then(response => {
        if (response.data.message) {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#91C714',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        } else {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#D32929',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        }
        state.addGroup.isSuccess = response.data.success
      })
      .catch(error => {
        console.log(error)
      })
    state.addGroup.isSuccess = null
  },
  [DELETE_GROUP_FROM_ATHLETE](state, params) {
    axios.delete(process.env.VUE_APP_API_BASE_URL + '/api/athlete/user-group/',
      {
        data:
        {
          userId: params.userId,
          groupId: params.groupId
        }
      })
      .then((result) => {
        state.athletesInGroup.map((athlete, index) => {
          if (params.userId == athlete.userIdentifier) {
            state.athletesInGroup[index].userGroups =
              state.athletesInGroup[index].userGroups.filter((group) => {
                return group.id != params.groupId
              })
          }
        })
        state.athletesInGroup
        if (result.data.message) {
          Toastify({
            duration: 2000,
            text: result.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#91C714',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        } else {
          Toastify({
            duration: 2000,
            text: result.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#D32929',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        }
      })
  },
  [DELETE_USER_GROUP](state, params) {
    axios.delete(process.env.VUE_APP_API_BASE_URL + '/api/user-group/' + params)
      .then((response) => {
        state.groups.filter((group) => {
          return group.id != params
        })
        state.groups
        if (response.data.message) {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#91C714',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        } else {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#D32929',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        }
        state.deleteGroup.isSuccess = response.data.success
      })
    state.deleteGroup.isSuccess = null
  },
  [ADD_ATHLETES_TO_GROUP](state, params) {
    for (var i = 0; i < params.len; i++) {
      axios
        .post(process.env.VUE_APP_API_BASE_URL + '/api/user-group/add-athlete', {
          userId: params.users[i],
          groupId: params.groupId
        })
        .then(response => {
          if (response.data.message) {
            Toastify({
              duration: 2000,
              text: response.data.message,
              newWindow: true,
              style: {
                backgroundColor: '#91C714',
                color: 'white',
                padding: '10px'
              },
              gravity: 'top',
              position: 'right',
              stopOnFocus: true
            }).showToast()
          } else {
            Toastify({
              duration: 2000,
              text: response.data.message,
              newWindow: true,
              style: {
                backgroundColor: '#D32929',
                color: 'white',
                padding: '10px'
              },
              gravity: 'top',
              position: 'right',
              stopOnFocus: true
            }).showToast()
          }
          state.addAthletesToGroup.isSuccess = response.data.message
        })
        .catch(error => {
          console.log(error)
        })
    }
    state.addAthletesToGroup.isSuccess = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
