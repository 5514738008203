<template>
  <div class="p-2">
    <span class="inline-flex justify-center mx-2">
      <router-link :to="{ path: '/inventory' }">
        <div class="inline-block gap-1 border border-1 border-black rounded">
          <ArrowLeftIcon />
        </div>
        <span class="inline-block mx-2 align-top mt-1">Inventory</span>
      </router-link>
    </span>
  </div>
  <div class="bg-white rounded-lg">
    <div class="col-span-12 lg:col-span-8 2xl:col-span-9">
      <!-- BEGIN: Display Information -->
      <div class="intro-y box lg:mt-5">
        <div
          class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
        >
          <h2
            class="font-medium text-base mr-auto"
            :class="{
              //loading state
              hidden:
                boatDetail?.name == 'undefined undefined' ||
                boatDetail?.name == 'undefined' ||
                boatDetail?.name == undefined
            }"
          >
            {{ boatDetail?.name }}'s Details
          </h2>
        </div>
        <div class="px-5">
          <div class="flex xl:flex-row flex-col">
            <div class="flex-1 mt-6 xl:mt-0">
              <div class="grid grid-cols-12 gap-x-5">
                <div class="col-span-12 2xl:col-span-6">
                  <div>
                    <label for="boat-form-boat-name" class="form-label mt-5"
                      >Name<span v-if="isAvailable" class="text-theme-6"
                        >*</span
                      ></label
                    >
                    <!-- START: loading animation -->
                    <div v-if="!isLoaded">
                      <p class="bg-theme-8 px-2 w-full h-8 blob"></p>
                    </div>
                    <!-- END: loading animation -->
                    <input
                      v-if="isLoaded"
                      id="boat-form-boat-name"
                      type="text"
                      class="form-control"
                      placeholder="no value entered yet"
                      v-model="boatDetail.name"
                      :disabled="isFieldDisabled"
                    />
                  </div>
                  <div class="my-2">
                    <label for="boat-form-boat-code" class="form-label"
                      >Boat Code</label
                    >
                    <!-- START: loading animation -->
                    <div v-if="!isLoaded">
                      <p class="bg-theme-8 px-2 w-full h-8 blob"></p>
                    </div>
                    <!-- END: loading animation -->
                    <input
                      v-if="isLoaded"
                      id="boat-form-boat-code"
                      type="text"
                      class="form-control"
                      placeholder="no value entered yet"
                      v-model="boatDetail.boatCode"
                      :disabled="isFieldDisabled"
                    />
                  </div>
                  <div class="my-2">
                    <label for="boat-form-crew-weight" class="form-label"
                      >Crew Weight</label
                    >
                    <!-- START: loading animation -->
                    <div v-if="!isLoaded">
                      <p class="bg-theme-8 px-2 w-full h-8 blob"></p>
                    </div>
                    <!-- END: loading animation -->
                    <input
                      v-if="isLoaded"
                      id="boat-form-crew-weight"
                      type="text"
                      class="form-control"
                      placeholder="no value entered yet"
                      v-model="boatDetail.crewWeight"
                      :disabled="isFieldDisabled"
                    />
                  </div>
                  <div v-if="role == 'Athlete'" class="my-2">
                    <label for="boat-form-crew-size" class="form-label"
                      >Crew Size</label
                    >
                    <!-- START: loading animation -->
                    <div v-if="!isLoaded">
                      <p class="bg-theme-8 px-2 w-full h-8 blob"></p>
                    </div>
                    <!-- END: loading animation -->
                    <input
                      v-if="isLoaded"
                      id="boat-form-crew-size"
                      type="text"
                      class="form-control"
                      placeholder="no value entered yet"
                      v-model="boatDetail.boatType.crewSize"
                      disabled
                    />
                  </div>
                  <div class="my-2">
                    <label for="boat-form-insurance-value" class="form-label"
                      >Boat Value</label
                    >
                    <!-- START: loading animation -->
                    <div v-if="!isLoaded">
                      <p class="bg-theme-8 px-2 w-full h-8 blob"></p>
                    </div>
                    <!-- END: loading animation -->
                    <input
                      v-if="isLoaded"
                      v-model="boatDetail.insuranceValue"
                      id="boat-form-insurance-value"
                      type="text"
                      class="form-control"
                      placeholder="no value entered yet"
                      :disabled="isFieldDisabled"
                    />
                  </div>
                  <div v-if="role == 'Athlete'" class="my-2">
                    <label
                      for="boat-form-boat-type"
                      class="form-label"
                    ></label>
                    <!-- START: loading animation -->
                    <div v-if="!isLoaded">
                      <p class="bg-theme-8 px-2 w-full h-8 blob"></p>
                    </div>
                    <!-- END: loading animation -->
                    <label for="boat-form-boat-type" class="form-label"
                      >Boat Type</label
                    >
                    <input
                      v-if="isLoaded && boatDetail.boatType.cox"
                      id="boat-form-boat-type"
                      type="text"
                      class="form-control"
                      value="Coxed"
                      disabled
                    />
                    <input
                      v-if="isLoaded && !boatDetail.boatType.cox"
                      id="update-profile-form-1"
                      type="text"
                      class="form-control"
                      value="Not Coxed"
                      disabled
                    />
                  </div>
                  <div v-if="role == 'Athlete'" class="my-2">
                    <label
                      for="boat-form-boat-sweep"
                      class="form-label"
                    ></label>
                    <!-- START: loading animation -->
                    <div v-if="!isLoaded">
                      <p class="bg-theme-8 px-2 w-full h-8 blob"></p>
                    </div>
                    <!-- END: loading animation -->
                    <input
                      v-if="isLoaded && boatDetail.boatType.sweep"
                      id="boat-form-boat-sweep"
                      type="text"
                      class="form-control"
                      value="Sweep"
                      disabled
                    />
                    <input
                      v-if="isLoaded && !boatDetail.boatType.sweep"
                      id="boat-form-boat-sweep"
                      type="text"
                      class="form-control"
                      value="Scull"
                      disabled
                    />
                  </div>
                  <div v-if="isAvailable" class="my-2">
                    <label for="boat-form-boat-type-select" class="form-label"
                      >Boat Type:<span v-if="isAvailable" class="text-theme-6"
                        >*</span
                      ></label
                    >
                    <!-- START: loading animation -->
                    <div v-if="!isLoaded">
                      <p class="bg-theme-8 px-2 w-full h-8 blob"></p>
                    </div>
                    <!-- END: loading animation -->
                    <TomSelect
                      v-model="boatType"
                      :options="{
                        placeholder: 'Select Boat Type'
                      }"
                      class="w-auto"
                    >
                      <div v-for="(type, index) in boatTypes" :key="index">
                        <option :value="type?.boatType">
                          Type: {{ type?.boatType }} |
                          <span v-if="type.cox">Coxed</span
                          ><span v-else>Not Coxed</span>
                          |
                          <span v-if="type.sweep">Sweep</span
                          ><span v-else>Scull</span> | Crew Size:
                          {{ type?.crewSize }}
                        </option>
                      </div>
                    </TomSelect>
                  </div>
                </div>
                <div class="col-span-12 2xl:col-span-6 mt-3">
                  <div class="my-2">
                    <label for="update-profile-form-1" class="form-label"
                      >Make</label
                    >
                    <!-- START: loading animation -->
                    <div v-if="!isLoaded">
                      <p class="bg-theme-8 px-2 w-full h-8 blob"></p>
                    </div>
                    <!-- END: loading animation -->
                    <input
                      v-if="isLoaded"
                      v-model="boatDetail.make"
                      id="update-profile-form-1"
                      type="text"
                      class="form-control"
                      placeholder="no value entered yet"
                      :disabled="isFieldDisabled"
                    />
                  </div>
                  <div class="my-2">
                    <label for="boat-form-boat-note" class="form-label"
                      >Note</label
                    >
                    <!-- START: loading animation -->
                    <div v-if="!isLoaded">
                      <p class="bg-theme-8 px-2 w-full h-8 blob"></p>
                    </div>
                    <!-- END: loading animation -->
                    <input
                      v-if="isLoaded"
                      v-model="boatDetail.note"
                      id="boat-form-boat-note"
                      type="text"
                      class="form-control"
                      placeholder="no value entered yet"
                      :disabled="isFieldDisabled"
                    />
                  </div>
                  <div class="my-2">
                    <label for="boat-form-boat-owner" class="form-label"
                      >Owner</label
                    >
                    <!-- START: loading animation -->
                    <div v-if="!isLoaded">
                      <p class="bg-theme-8 px-2 w-full h-8 blob"></p>
                    </div>
                    <!-- END: loading animation -->
                    <input
                      v-if="isLoaded"
                      v-model="boatDetail.owner"
                      id="boat-form-boat-owner"
                      type="text"
                      class="form-control"
                      placeholder="no value entered yet"
                      :disabled="isFieldDisabled"
                    />
                  </div>
                  <div class="my-2">
                    <label for="boat-form-year" class="form-label"
                      >Year</label
                    >
                    <!-- START: loading animation -->
                    <div v-if="!isLoaded">
                      <p class="bg-theme-8 px-2 w-full h-8 blob"></p>
                    </div>
                    <!-- END: loading animation -->
                    <input
                      v-if="isLoaded"
                      v-model="boatDetail.year"
                      id="boat-form-year"
                      type="text"
                      class="form-control"
                      placeholder="no value entered yet"
                      :disabled="isFieldDisabled"
                    />
                  </div>
                  <div class="my-2">
                    <label for="boat-form-boat-status" class="form-label"
                      >Status</label
                    >
                    <!-- START: loading animation -->
                    <div v-if="!isLoaded">
                      <p class="bg-theme-8 px-2 w-full h-8 blob"></p>
                    </div>
                    <!-- END: loading animation -->
                    <v-select
                      :disabled="isFieldDisabled"
                      v-if="isLoaded"
                      v-model="boatDetail.status"
                      :reduce="(option) => option?.label"
                      :options="[{ label: 'in use' }, { label: 'out of use' }]"
                    >
                    </v-select>
                  </div>
                </div>
              </div>
              <div v-if="isAvailable" class="font-light text-theme-6">
                <p>Fields marked with '*' are required</p>
              </div>
              <div
                v-if="isAvailable"
                class="inline-block py-2"
                role="button"
                @click="submit()"
              >
                <input
                  type="submit"
                  class="rounded-md bg-theme-1 text-white font-semibold w-20 h-8"
                  value="save"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Display Information -->
    </div>
    <div class="p-2">
      <section>
        <div class="container mx-auto">
          <div class="flex flex-wrap">
            <div class="w-full">
              <div class="pt-4 bg-white rounded border border-blue-50">
                <div class="px-6 border-b border-blue-50">
                  <!-- router -->
                  <div v-if="isAvailable" class="float-right px-1 py-2">
                    <router-link
                      :to="{ path: '/add-repair/' + boatDetail.id }"
                      class="btn float-right border border-black font-bold px-2 py-1 text-sm outline-none rounded focus:outline-none ease-linear transition-all duration-150"
                    >
                      <div class="flex gap-1"><PlusIcon />Add Repair</div>
                    </router-link>
                  </div>
                  <div class="flex flex-wrap items-center mb-4">
                    <div>
                      <h3 class="text-2xl font-bold px-1 py-2">Repairs</h3>
                    </div>
                  </div>
                </div>
                <div v-if="isLoaded && boatDetail?.boatRepairs?.length < 1">
                  <div class="w-full p-4">
                    <div class="flex justify-center mt-6">
                      <ToolIcon class="w-12 h-12" />
                    </div>
                    <div class="flex justify-center text-center text-lg p-4">
                      No Repair found for this Boat
                    </div>
                  </div>
                </div>
                <div class="hidden lg:block">
                  <table class="w-full">
                    <thead
                      class="bg-gray-50"
                      v-if="isLoaded && boatDetail?.boatRepairs?.length > 0"
                    >
                      <tr class="border-b text-sm border-blue-50">
                        <th class="p-2 text-theme-1">date</th>
                        <th class="p-2 text-theme-1">cost</th>
                        <th class="p-2 text-theme-1">notes</th>
                        <th class="p-2 text-theme-1" v-if="isAvailable">
                          actions
                        </th>
                      </tr>
                    </thead>
                    <!-- START: loading animation for L views -->
                    <tbody v-if="!isLoaded">
                      <tr
                        v-for="el in 4"
                        :key="el.id"
                        class="w-full p-2 mx-4 my-6 border-b"
                      >
                        <td
                          class="w-1/4 text-center border-b lg:table-cell static m-4 my-2 px-2"
                        >
                          <p class="px-2 mx-4 w-full h-6 blob bg-theme-1"></p>
                        </td>
                        <td
                          class="w-1/7 text-center border-b lg:table-cell static m-4 my-2 px-2"
                        >
                          <p class="px-2 mx-4 w-full h-6 blob bg-theme-6"></p>
                        </td>
                        <td
                          class="w-1/4 text-center border-b lg:table-cell static m-4 my-2 px-2"
                        >
                          <p class="px-2 mx-4 w-full h-6 blob bg-theme-8"></p>
                        </td>
                        <td
                          v-if="isAvailable"
                          class="w-1/7 text-center border-b lg:table-cell static m-4 my-2 px-2"
                        >
                          <p class="px-2 mx-4 w-full h-6 blob bg-theme-6"></p>
                        </td>
                      </tr>
                    </tbody>
                    <!-- END: loading animation for L views -->
                    <tbody v-if="isLoaded">
                      <tr
                        v-for="(repair, index) in boatDetail.boatRepairs"
                        :key="index"
                        class="w-full p-2 mx-4 my-6 border-b hover:bg-theme-14"
                      >
                        <td
                          class="w-1/7 text-theme-1 text-center border-b lg:table-cell static"
                        >
                          <p class="py-1 px-2 text-sm mx-4 pt-2">
                            {{ repair.dateAdded }}
                          </p>
                        </td>
                        <td
                          class="w-1/7 text-theme-6 text-center border-b lg:table-cell static"
                        >
                          <p class="py-1 px-2 text-sm mx-4 pt-2">
                            {{ repair.cost }}
                          </p>
                        </td>
                        <td
                          class="w-1/7 text-theme-1 text-center border-b lg:table-cell static"
                        >
                          <p class="py-1 px-2 text-sm mx-4 pt-2">
                            {{ repair.note }}
                          </p>
                        </td>
                        <td
                          v-if="isAvailable"
                          class="w-1/7 text-theme-1 text-center border-b lg:table-cell static"
                        >
                          <a
                            class="flex justify-center py-1 px-2 text-blue-400 text-sm"
                            href="javascript:;"
                            data-toggle="modal"
                            data-target="#delete-modal-preview"
                            @click="deleteRepair(boatDetail.id, index)"
                          >
                            <Trash2Icon class="text-theme-6 p-1" />
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="lg:hidden xl:hidden p-2">
                  <!-- START: loading animation for S-M views  -->
                  <div v-if="!isLoaded" disabled>
                    <ul v-for="el in 4" :key="el.id" class="w-full py-2 mx-4">
                      <li>
                        <div class="flex-row">
                          <li
                            class="py-1 text-lg bg-theme-1 my-2 px-2 w-64 h-6 blob"
                          ></li>
                        </div>
                      </li>
                      <li>
                        <span class="inline-block">
                          <li
                            class="py-1 text-sm bg-theme-8 mr-2 px-2 w-32 h-6 blob"
                          ></li>
                        </span>
                      </li>
                      <li>
                        <span class="inline-block">
                          <p class="py-1 text-sm bg-theme-8 mr-2 px-2 blob"></p>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <!-- END: loading animation for S-M views  -->
                  <div class="p-2">
                    <ul
                      v-for="(repair, index) in boatDetail.boatRepairs"
                      :key="index"
                      class="border border-1 border-theme-14 rounded-md my-2"
                    >
                      <div>
                        <li class="bg-theme-14 p-2">
                          <div v-if="isAvailable" class="float-right">
                            <a
                              class="py-1 text-blue-400 text-sm"
                              href="javascript:;"
                              data-toggle="modal"
                              data-target="#delete-modal-preview"
                              @click="deleteRepair(boatDetail.id, index)"
                            >
                              <Trash2Icon class="text-theme-6 p-1" />
                            </a>
                          </div>
                          <div>
                            <span class="text-md font-semibold text-theme-19">{{
                              repair.dateAdded
                            }}</span>
                          </div>
                        </li>
                        <li class="px-2 py-1">
                          <div>
                            <p class="text-md">
                              <span class="text-md font-semibold text-theme-19"
                                >Cost: </span
                              >{{ repair.cost }}
                            </p>
                          </div>
                        </li>
                        <li class="px-2 py-1">
                          <div>
                            <p class="text-md">
                              <span class="text-md font-semibold text-theme-19"
                                >Note:
                              </span>
                              {{ repair.note }}
                            </p>
                          </div>
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<style scoped>
.blob {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
</style>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'
import Toastify from 'toastify-js'

export default {
  data() {
    return {
      isLoaded: false
    }
  },
  methods: {},
  watch: {
    boatDetail() {
      if (this.$store.state.inventories.boatDetailLoaded.message) {
        this.isLoaded = true
      }
    }
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const boatDetail = computed(() => store.state.inventories.boatDetail)
    const boatType = computed(
      () => store.state.inventories.boatDetail?.boatType?.boatType
    )
    const boatTypes = computed(() => store.state.inventories.boatTypes)
    const role = computed(() => store.state.profile.user?.userRole?.name)
    const clubName = computed(() => store.state.profile.user?.club.name)
    const isFieldDisabled = computed(
      () =>
        !(
          store.state.profile.user?.userRole?.name == 'Coach' ||
          store.state.profile.user?.userRole?.name == 'Super Admin'
        )
    )
    const isAvailable = computed(
      () =>
        store.state.profile.user?.userRole?.name == 'Coach' ||
        store.state.profile.user?.userRole?.name == 'Super Admin'
    )

    onMounted(() => {
      store.dispatch('profile/getProfileData')
      store.dispatch('inventories/getBoatDetail', route.params.id)
      store.dispatch('inventories/getBoatTypes')
    })
    const deleteRepair = (id, index) => {
      store.dispatch('inventories/deleteRepair', { id, index })
    }
    const submit = () => {
      if (
        store.state.inventories.boatDetail.boatType.boatType !== '' &&
        store.state.inventories.boatDetail.name !== ''
      ) {
        store.dispatch(
          'inventories/updateBoat',
          store.state.inventories.boatDetail
        )
      } else {
        Toastify({
          duration: 2000,
          text: 'Please fill in the required fields',
          newWindow: true,
          style: {
            backgroundColor: '#D32929',
            color: 'white',
            padding: '10px'
          },
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
      }
    }
    return {
      boatDetail,
      boatTypes,
      boatType,
      clubName,
      deleteRepair,
      submit,
      role,
      isFieldDisabled,
      isAvailable
    }
  }
}
</script>
