import moment from 'moment'
import axios from 'axios'
export const EVENTS = 'EVENTS'

const state = () => {
  return {
    events: {
    }
  }
}

// getters
const getters = {
  events: state => {
    return state.events
  }
}

// actions
const actions = {
  getEventsData({ commit }, data) {
    commit(EVENTS, data)
  }
}

// mutations
const mutations = {
  [EVENTS](state, data) {
    axios
      .get(process.env.VUE_APP_API_BASE_URL + '/api/me/event/list')
      .then(response => {
        state.events = response.data.data
        for (var i = 0; i < state.events.length; i++) {
          state.events[i].eventDate = moment(state.events[i].eventDate * 1000).format('YYYY-MM-DD')
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
