<template>
  <div>
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Register Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="" class="text-3xl -intro-x flex items-center pt-5">
            <img
              alt="Rowerhub"
              class="-intro-x w-40 mt-20"
              :src="require(`@/assets/images/rowerhub-dark-logo.png`)"
            />
          </a>
          <div class="my-auto">
            <img
              alt="Rowerhub"
              class="-intro-x w-1/2 -mt-16"
              :src="require(`@/assets/images/illustration.svg`)"
            />
            <div
              class="
                -intro-x
                text-white
                font-medium
                text-4xl
                leading-tight
                mt-10
              "
            >
              A few more clicks to <br />
              sign up to your Rowerhub account.
            </div>
          </div>
        </div>
        <!-- END: Register Info -->
        <!-- BEGIN: Register Form -->
        <div class="h-screen xl:h-auto xl:py-0 xl:my-0 xl:flex">
          <div class="flex justify-center my-5 xl:hidden w-full">
            <a href="" class="text-3xl -intro-x flex items-center pt-5">
              <img
                alt="Rowerhub"
                class="-intro-x w-40 -mt-20"
                :src="require(`@/assets/images/rowerhub-dark-logo.png`)"
              />
            </a>
          </div>
          <div
            class="
              my-auto
              mx-auto
              xl:ml-20
              bg-white
              dark:bg-dark-1
              xl:bg-transparent
              px-5
              sm:px-8
              py-8
              xl:p-0
              rounded-md
              shadow-md
              xl:shadow-none
              w-full
              sm:w-3/4
              lg:w-2/4
              xl:w-auto
            "
          >
            <div v-show="isFormVisible">
              <h2
                class="
                  intro-x
                  font-bold
                  text-2xl
                  xl:text-3xl
                  text-center
                  xl:text-left
                "
              >
                Sign Up
              </h2>
              <div
                class="
                  intro-x
                  mt-2
                  text-gray-500
                  dark:text-gray-500
                  xl:hidden
                  text-center
                "
              >
                A few more clicks to sign in to your Rowerhub account.
              </div>
              <!-- Signup Errors -->
              <div
                class="alert alert-danger show mb-2 mt-4"
                role="alert"
                id="registration-errors"
                v-if="registrationErrorMessage"
              >
                {{ registrationErrorMessage }}
              </div>
              <div class="intro-x mt-8" id="form-fields">
                <!-- Name !-->
                <label
                  for="name"
                  class="form-label w-full flex flex-col mt-4 sm:flex-row"
                  >First Name</label
                >
                <input
                  type="text"
                  name="name"
                  class="
                    intro-x
                    login__input
                    form-control
                    py-3
                    px-4
                    border-gray-300
                    block
                  "
                  v-model.trim="validate.name.$model"
                  placeholder="First Name"
                />
                <template v-if="validate.name.$error">
                  <div
                    v-for="(error, index) in validate.name.$errors"
                    :key="index"
                    class="text-theme-6 mt-2"
                  >
                    {{ error.$message }}
                  </div>
                </template>

                <!-- Lastname !-->
                <label
                  for="lastname"
                  class="form-label w-full flex flex-col mt-4 sm:flex-row"
                  >Last Name</label
                >
                <input
                  type="text"
                  name="lastname"
                  class="
                    intro-x
                    login__input
                    form-control
                    py-3
                    px-4
                    border-gray-300
                    block
                  "
                  v-model.trim="validate.lastname.$model"
                  placeholder="Last Name"
                />
                <template v-if="validate.lastname.$error">
                  <div
                    v-for="(error, index) in validate.lastname.$errors"
                    :key="index"
                    class="text-theme-6 mt-2"
                  >
                    {{ error.$message }}
                  </div>
                </template>

                <!-- email !-->
                <label
                  for="email"
                  class="form-label w-full flex flex-col mt-4 sm:flex-row"
                  >E-mail</label
                >
                <input
                  type="text"
                  name="email"
                  class="
                    intro-x
                    login__input
                    form-control
                    py-3
                    px-4
                    border-gray-300
                    block
                  "
                  v-model.trim="validate.email.$model"
                  placeholder="E-mail"
                />
                <template v-if="validate.email.$error">
                  <div
                    v-for="(error, index) in validate.email.$errors"
                    :key="index"
                    class="text-theme-6 mt-2"
                  >
                    {{ error.$message }}
                  </div>
                </template>

                <!-- password !-->
                <label
                  for="password"
                  class="form-label w-full flex flex-col mt-4 sm:flex-row"
                  >Password</label
                >
                <input
                  type="password"
                  name="password"
                  class="
                    intro-x
                    login__input
                    form-control
                    py-3
                    px-4
                    border-gray-300
                    block
                  "
                  v-model.trim="validate.password.$model"
                  placeholder="Password"
                />
                <template v-if="validate.password.$error">
                  <div
                    v-for="(error, index) in validate.password.$errors"
                    :key="index"
                    class="text-theme-6 mt-2"
                  >
                    {{ error.$message }}
                  </div>
                </template>
              </div>
              <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
                <button
                  class="
                    btn btn-primary
                    py-3
                    px-4
                    w-full
                    mt-2
                    xl:w-32 xl:mr-3
                    align-top
                  "
                  v-on:click="submit()"
                >
                  Register
                </button>
                <router-link
                  :to="{ name: 'login' }"
                  tag="button"
                  class="
                    btn btn-outline-secondary
                    py-3
                    px-4
                    mt-2
                    w-full
                    xl:w-32 xl:mr-3
                    align-top
                  "
                >
                  Sign in
                </router-link>
              </div>
            </div>
            <!-- Success Message -->
            <div
              v-show="showSuccessMessage"
              class="
                h-screen
                flex-col
                xl:h-auto xl:py-0 xl:my-0 xl:flex
                text-green
              "
            >
              <CheckCircleIcon class="block mx-auto w-12 h-12 text-theme-9" />
              <h2
                class="
                  -intro-x
                  mt-5
                  text-lg text-gray-800 text-center
                  dark:text-gray-500
                "
              >
                You've successfully registered. Please check your inbox(<strong
                  >{{ formData.email }}</strong
                >) to validate your email address
              </h2>
            </div>
          </div>
        </div>
        <!-- END: Register Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, computed } from 'vue'
import { required, minLength, maxLength, email } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { useStore } from '@/store'

export default {
  setup() {
    const store = useStore()
    const isFormVisible = computed(
      () => !store.state.auth.registration.isSuccess
    )
    const registrationErrorMessage = computed(
      () => store.state.auth.registration.errorMessage
    )
    const showSuccessMessage = computed(
      () => store.state.auth.registration.isSuccess
    )

    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    })

    const formData = reactive({
      name: '',
      lastname: '',
      email: '',
      password: ''
    })

    const rules = {
      name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(10)
      },
      lastname: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(10)
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(15)
      }
    }

    const validate = useVuelidate(rules, toRefs(formData))
    const submit = () => {
      store.dispatch('auth/setRegistration', formData)
    }
    return {
      validate,
      formData,
      isFormVisible,
      showSuccessMessage,
      registrationErrorMessage,
      submit
    }
  }
}
</script>
