<template>
  <!-- BEGIN: Top Bar -->
  <div class="top-bar">
    <!-- BEGIN: Breadcrumb -->
    <div class="-intro-x breadcrumb mr-auto hidden sm:flex">
      <p class="breadcrumb--active font-bold text-xl mx-2 mt-4">
        {{clubName}}
      </p>
    </div>
    <!-- END: Breadcrumb -->
    <!-- BEGIN: Account Menu -->
    <div class="intro-x dropdown">
      <div
        class="
          dropdown-toggle
          w-12
          h-12
          rounded-full
          overflow-hidden
          shadow-lg
          image-fit
          zoom-in
          bg-theme-1
        "
        role="button"
        aria-expanded="false"
      > <div class="text-white text-center text-2xl p-2">{{profile}}</div>
      </div>
      <div class="dropdown-menu w-56">
        <div
          class="
            dropdown-menu__content
            box
            bg-theme-26
            dark:bg-dark-6
            text-white
          "
        >
          <div class="p-4 border-b border-theme-27 dark:border-dark-3">
            <div class="font-medium">{{ fullName }}</div>
            <div class="text-xs text-theme-28 mt-0.5 dark:text-gray-600">
              {{clubName}}
            </div>
          </div>
          <div class="p-2">
            <a
              class="
                flex
                items-center
                block
                p-2
                transition
                duration-300
                ease-in-out
                hover:bg-theme-1
                dark:hover:bg-dark-3
                rounded-md
              "
            >
            <router-link :to="{ path: '/profile'}">
              <div class="flex gap-1">
                <UserIcon class="w-4 h-4 mr-2" /> Profile
              </div>
            </router-link>
            </a>
            <a
              href=""
              class="
                flex
                items-center
                block
                p-2
                transition
                duration-300
                ease-in-out
                hover:bg-theme-1
                dark:hover:bg-dark-3
                rounded-md
              "
            >
              <LockIcon class="w-4 h-4 mr-2" /> Reset Password
            </a>
            <a
              href=""
              class="
                flex
                items-center
                block
                p-2
                transition
                duration-300
                ease-in-out
                hover:bg-theme-1
                dark:hover:bg-dark-3
                rounded-md
              "
            >
              <HelpCircleIcon class="w-4 h-4 mr-2" /> Help
            </a>
          </div>
          <div class="p-2 border-t border-theme-27 dark:border-dark-3">
            <a
              href="#"
              class="
                flex
                items-center
                block
                p-2
                transition
                duration-300
                ease-in-out
                hover:bg-theme-1
                dark:hover:bg-dark-3
                rounded-md
              "
              @click="logout()"
            >
              <ToggleRightIcon class="w-4 h-4 mr-2" /> Logout
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Account Menu -->
  </div>
  <!-- END: Top Bar -->
</template>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from '@/store'

export default {
  data: function () {
    return {
      profile: ''
    }
  },
  watch: {
    fullName() {
      this.profile =
        this.$store.state.profile.user?.firstName.slice(0, 1) +
        this.$store.state.profile.user?.lastName.slice(0, 1)
    }
  },
  setup() {
    const store = useStore()

    const logout = () => {
      store.dispatch('auth/loggedOut')
    }

    onMounted(() => {
      store.dispatch('profile/getProfileData')
    })

    const fullName = computed(() => store.state.profile.user?.firstName + ' ' + store.state.profile.user?.lastName)
    const clubName = computed(() => store.state.profile.user?.club.name)

    return {
      logout,
      fullName,
      clubName
    }
  }
}
</script>
