<template>
  <div id="app">
    <div class="w-full px-2 py-2">
      <div>
        <span>
          <!-- router -->
          <div class="px-2 py-2">
            <router-link v-if="isAvailable" :to="{ path: '/add-event' }" class="
                    btn
                    float-right
                    border border-black
                    font-bold
                    px-2
                    py-1
                    text-sm
                    outline-none
                    rounded
                    focus:outline-none
                    ease-linear
                    transition-all
                    duration-150
                  ">
              <div class="flex gap-1">
                <PlusIcon />Add Event
              </div>
            </router-link>
            <div class="text-xl font-bold">Events</div>
            <div class="text-xs font-thin" :class="{
              hidden:
                fullName == 'undefined undefined' ||
                fullName == 'undefined' ||
                fullName == undefined
            }">
              {{ fullName }}’s Events
            </div>
          </div>
          <!-- router -->
        </span>
      </div>
      <!-- START: EMPTY LIST -->
      <div v-if="isLoaded && events.length < 1">
        <div class="w-full p-4">
          <div class="flex justify-center mt-6">
            <LifeBuoyIcon class="w-12 h-12" />
          </div>
          <div class="flex justify-center text-lg p-4">
            You have not been added to any events yet.
          </div>
        </div>
      </div>
      <!-- END: EMPTY LIST -->
      <!-- START: events list for M-L views  -->
      <div class="hidden md:block lg:block">
        <!-- START: loading animation for M-L views  -->
        <div v-if="!isLoaded" disabled>
          <ul v-for="el in 8" :key="el.id" class="w-full py-2 mx-4 border-b border-black">
            <li>
              <div class="flex-row">
            <li class="py-1 text-lg bg-theme-1 my-2 px-2 w-64 h-6 blob"></li>
        </div>
        </li>
        <li>
          <span v-for="el in 2" :key="el.id" class="inline-block">
        <li class="py-1 text-sm bg-theme-8 mr-2 px-2 w-32 h-6 blob"></li>
        </span>
        </li>
        <li>
          <span v-for="el in 2" :key="el.id" class="inline-block">
            <p class="py-1 text-sm bg-theme-8 mr-2 px-2 blob"></p>
          </span>
        </li>
        <li>
          <span v-for="el in 3" :key="el.id" class="inline-block">
            <p class="py-1 text-sm bg-theme-8 mr-2 px-2 w-48 h-6 blob"></p>
          </span>
        </li>
        </ul>
      </div>
      <!-- END: loading animation for M-L views  -->
      <div v-if="isLoaded">
        <ul v-for="(element, index) in events" :key="element.id" class="w-full py-2 mx-4 border-b border-black">
          <li>
            <div class="flex-row">
              <p class="py-1 text-lg font-semibold text-blue-300 flex">
                {{ element.eventDate }}
                <ArrowRightIcon class="mx-2 pt-1" /> {{ element?.eventEndDate }}
              </p>
            </div>
          </li>
          <li>
            <span>
              <span>
                <UpdateRsvp :element="element" :index="index" />
              </span>
            </span>
            <span v-for="group in element.userGroups" :key="group.id" class="inline-block text-theme-1">
              <p class="
                        py-1
                        text-sm
                        bg-theme-8
                        text-blue-300
                        mr-2
                        px-2
                        rounded-md
                      ">
                {{ group.groupName }}
              </p>
            </span>
          </li>
          <li>
            <router-link :to="{ path: '/event-detail/' + element.id }">
              <div class="flex flex-row mt-4">
                <div>
                  <span class="px-2 text-sm flex" v-if="element.type == 'ergo'">
                    <img src="../../assets/images/ergo.png" alt="ergo icon" class="w-12">
                  </span>
                  <span class="text-sm flex" v-if="element.type == 'water'">
                    <img src="../../assets/images/water.png" alt="water icon" class="w-12">
                  </span>
                  <span class="px-2 py-3.5 text-sm flex" v-if="element.type == 'training'">
                    <SettingsIcon />
                  </span>
                  <span class="text-sm flex" v-if="element.type == 'race'">
                    <img src="../../assets/images/race.png" alt="race icon" class="w-12">
                  </span>
                  <span class="px-2 py-3.5 text-sm flex" v-if="element.type == 'social'">
                    <UsersIcon />
                  </span>
                </div>
                <div>
                  <span class="text-theme-1 inline-block align-middle py-3.5">
                   <span>{{ element.name }}</span>
                    <sup>
                      <InfoIcon class="inline-block w-3 h-3" />
                    </sup></span>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <!-- END: events list for M-L views  -->
    <!-- START: events list for S view  -->
    <div class="md:hidden lg:hidden xl:hidden">
      <!-- START: loading animation for S view  -->
      <div v-if="!isLoaded" disabled>
        <ul v-for="el in 6" :key="el.id" class="border border-1 border-theme-13 rounded-md px-2 my-6">
          <li>
            <div class="flex justify-center m-2">
          <li class="bg-theme-1 w-64 h-6 p-2 blob"></li>
      </div>
      </li>
      <li>
        <div v-for="el in 2" :key="el.id" class="flex justify-center m-2">
      <li class="bg-theme-8 w-32 h-6 blob p-2"></li>
    </div>
    </li>
    <li>
      <div class="flex justify-center m-2">
    <li class="bg-theme-12 w-32 h-6 p-2 blob"></li>
  </div>
  </li>
  </ul>
  </div>
  <!-- END: loading animation for S view  -->
  <div v-if="isLoaded">
    <ul v-for="(element, index) in events" :key="index" class="border border-1 border-theme-13 rounded-md px-2 my-6">
      <div class="p-2">
        <li>
          <div class="flex justify-center">
            <p class="text-md text-center items-center font-semibold text-theme-19">
              {{ element.eventDate }}
              <ArrowDownIcon class="mx-2 pt-1 mr-auto ml-auto" /> {{ element?.eventEndDate }}
            </p>
            <span> </span>
          </div>
        </li>
        <li>
          <div v-for="group in element.userGroups" :key="group.id" class="flex justify-center">
            <p class="text-sm bg-theme-1 text-white p-2 mt-1 rounded-md">
              {{ group.groupName }}
            </p>
          </div>
        </li>
        <li>
          <router-link :to="{ path: '/event-detail/' + element.id }">
            <div class="flex flex-row justify-center py-2 mt-2">
              <div>
                  <span class="px-2 text-sm flex" v-if="element.type == 'ergo'">
                    <img src="../../assets/images/ergo.png" alt="ergo icon" class="w-12">
                  </span>
                  <span class="text-sm flex" v-if="element.type == 'water'">
                    <img src="../../assets/images/water.png" alt="water icon" class="w-12">
                  </span>
                  <span class="px-2 py-3.5 text-sm flex" v-if="element.type == 'training'">
                    <SettingsIcon />
                  </span>
                  <span class="text-sm flex" v-if="element.type == 'race'">
                    <img src="../../assets/images/race.png" alt="race icon" class="w-12">
                  </span>
                  <span class="px-2 py-3.5 text-sm flex" v-if="element.type == 'social'">
                    <UsersIcon />
                  </span>
                </div>
                <div>
                  <span class="text-theme-1 inline-block align-middle py-3.5">
                   <span>{{ element.name }}</span>
                    <sup>
                      <InfoIcon class="inline-block w-3 h-3" />
                    </sup></span>
                </div>
            </div>
          </router-link>
        </li>
        <li>
          <div class="flex justify-center">
            <p class="text-sm border-b border-theme-13 font-thin">
              Your Status
            </p>
          </div>
          <div class="flex justify-center">
            <span>
              <UpdateRsvp :element="element" :index="index" />
            </span>
          </div>
        </li>
      </div>
    </ul>
  </div>
  </div>
  <!-- END: events list for S view  -->
  <div v-if="isLoadMore" class="mt-4">
    <button @click="loadMoreEvents()" class="btn btn-outline-secondary py-4 w-full">
      Show More
      <LoadingIcon icon="oval" class="w-4 h-4 ml-4 text-gray-600" v-if="isLoadMoreActive" />
    </button>
  </div>
  </div>
</div>
</template>
<style scoped>
.blob {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
</style>

<script>
import { computed, onMounted, ref } from 'vue'
import { useStore } from '@/store'
import UpdateRsvp from './UpdateRsvp.vue'

export default {
  name: 'Your Events',
  components: { UpdateRsvp },
  data() {
    return {
      disabled: false,
      isLoaded: false
    }
  },
  watch: {
    '$store.state.events.eventsLoaded.message'() {
      if (this.$store.state.events.eventsLoaded.message) {
        this.isLoaded = true
      }
    }
  },
  setup() {
    const offset = ref(0)
    const store = useStore()
    const events = computed(() => store.state.events.events)
    const response = computed(() => store.state.events.updateRsvp.response)
    const role = computed(() => store.state.profile.user?.userRole?.name)
    const isLoadMoreActive = computed(() => store.state.events.isLoadMoreActive)
    const isLoadMore = computed(() => store.state.events.isLoadMore)
    const isAvailable = computed(() => (store.state.profile.user?.userRole?.name == 'Coach' || store.state.profile.user?.userRole?.name == 'Super Admin'))

    const fullName = computed(
      () =>
        store.state.profile.user?.firstName +
        ' ' +
        store.state.profile.user?.lastName
    )
    const userId = computed(() => store.state.profile.user?.id)
    onMounted(() => {
      store.dispatch('events/getEventsData', { offset: this?.offset ?? 0, user: userId, func: 'mounted' })
      store.dispatch('profile/getProfileData')
    })
    const loadMoreEvents = () => {
      offset.value += 10
      store.dispatch('events/getEventsData', { offset: offset.value, user: userId, func: 'loadMore' })
    }
    return {
      loadMoreEvents,
      response,
      userId,
      fullName,
      events,
      offset,
      isLoadMoreActive,
      isLoadMore,
      role,
      isAvailable
    }
  }
}
</script>
