<template>
  <div id="app">
    <div class="text-xl font-bold px-2 py-4">Dashboard</div>
    <div class="flex w-full px-2 py-2 main">
      <div class="px-2 py-2">
        <div class="px-2">
          <div class="
              col-span-12
              lg:col-span-4
              2xl:col-span-3
              flex
              lg:block
              flex-col-reverse
            ">
            <div class="intro-y box mt-5 weather">
              <div class="float-right text-md text-theme-16 p-5">
                {{ this.locationName }}
                <div role="button" @click="updateLocation()" class="text-sm text-theme-16 float-right px-2 align-top">
                  <RefreshCwIcon class="inline-block icon-update" />
                </div>
              </div>
              <div class="relative flex items-center p-5">
                <div class="mr-auto">
                  <div class="font-medium text-base text-theme-1">
                    Weather Forecast
                  </div>
                </div>
              </div>
              <div class="
                  text-xl
                  border-t border-gray-200
                  dark:border-dark-5
                  flex-rows
                ">
                <div v-if="weatherStatus == ''" class="loading">
                  <div class="blob">
                    <div class="flex justify-center">
                      <CloudOffIcon class="w-auto h-auto p-4" />
                    </div>
                    <div class="flex justify-center text-primary-6 text-md">
                      Loading...
                    </div>
                  </div>
                </div>
                <div class="px-8 py-4" v-if="weatherStatus !== ''">
                  <div class="text-sm">{{ this.today }}</div>
                  <div class="flex justify-center">
                    <div class="flex text-2xl">
                      <span class="text-3xl pt-4 mt-8">
                        {{ Math.round(this.todaysWeather1?.main?.temp) }}°
                      </span>
                      <img class="w-32 h-32" :src="icon" />
                    </div>
                  </div>
                  <div class="flex justify-center text-theme-1 w-full">
                    {{ this.locationName }} ({{
                        this.todaysWeather1.weather[0]?.main
                    }})
                  </div>
                  <div class="flex justify-center">
                    <span class="text-theme-1">{{ Math.round(this.todaysWeather1?.main?.temp) }}°</span>
                    <span class="text-theme-16">
                      / Feels like:
                      {{ Math.round(this.todaysWeather1?.main?.feels_like) }}°
                    </span>
                  </div>
                  <div id="faq-accordion-1" class="accordion py-4">
                    <div class="accordion-item">
                      <div id="faq-accordion-content-1" class="accordion-header">
                        <button class="accordion-button text-spec" type="button" data-bs-toggle="collapse"
                          data-bs-target="#faq-accordion-collapse-1" aria-expanded="true"
                          aria-controls="faq-accordion-collapse-1">
                          {{ this.today }}
                        </button>
                      </div>
                      <div id="faq-accordion-collapse-1" class="accordion-collapse collapse show"
                        aria-labelledby="faq-accordion-content-1" data-bs-parent="#faq-accordion-1">
                        <div class="
                            accordion-body
                            text-gray-700
                            dark:text-gray-600
                            leading-relaxed
                            flex
                            overflow-x-auto
                            overscroll-auto
                          ">
                          <div v-for="(forecast, index) in todaysWeathers" :key="index"
                            class="inline-block justify-center py-2">
                            <div>
                              <div class="flex flex-col">
                                <div class="border rounded-md shadow-md mx-2 w-32">
                                  <div class="p-4 text-center">
                                    <div class="flex justify-center text-2xl">
                                      <img class="w-20 h-20" :src="
                                        'https://openweathermap.org/img/wn/' +
                                        forecast.weather[0].icon +
                                        '@2x.png'
                                      " />
                                    </div>
                                  </div>
                                  <div class="text-sm px-2">
                                    <div class="float-right text-theme-1">
                                      {{ Math.round(forecast?.main?.temp) }}°
                                    </div>
                                    <div class="text-theme-1">
                                      {{ forecast?.dt_txt.slice(-8, -3) }}
                                    </div>
                                  </div>
                                  <div class="text-sm px-2">
                                    <div class="float-right">
                                      {{ forecast?.main?.humidity }}%
                                    </div>
                                    <div class="text-theme-1">humidity</div>
                                  </div>
                                  <div class="text-sm px-2">
                                    <div class="float-right">
                                      {{ forecast?.wind?.speed }}km/h
                                    </div>
                                    <div class="text-theme-1">wind</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <div id="faq-accordion-content-2" class="accordion-header">
                        <button class="accordion-button collapsed text-spec" type="button" data-bs-toggle="collapse"
                          data-bs-target="#faq-accordion-collapse-2" aria-expanded="false"
                          aria-controls="faq-accordion-collapse-2">
                          {{ this.tomorrow }}
                        </button>
                      </div>
                      <div id="faq-accordion-collapse-2" class="accordion-collapse collapse"
                        aria-labelledby="faq-accordion-content-2" data-bs-parent="#faq-accordion-1">
                        <div class="
                            accordion-body
                            text-gray-700
                            dark:text-gray-600
                            leading-relaxed
                            flex
                            overflow-x-auto
                            overscroll-auto
                          ">
                          <div v-for="(forecast, index) in tomorrowsWeathers" :key="index"
                            class="flex justify-center py-2">
                            <div>
                              <div class="flex flex-col">
                                <div class="border rounded-md shadow-md mx-2 w-32">
                                  <div class="p-4 text-center">
                                    <div class="flex justify-center text-2xl">
                                      <img class="w-20 h-20" :src="
                                        'https://openweathermap.org/img/wn/' +
                                        forecast.weather[0].icon +
                                        '@2x.png'
                                      " />
                                    </div>
                                  </div>
                                  <div class="text-sm px-2">
                                    <div class="float-right text-theme-1">
                                      {{ Math.round(forecast?.main?.temp) }}°
                                    </div>
                                    <div class="text-theme-1">
                                      {{ forecast?.dt_txt.slice(-8, -3) }}
                                    </div>
                                  </div>
                                  <div class="text-sm px-2">
                                    <div class="float-right">
                                      {{ forecast?.main?.humidity }}%
                                    </div>
                                    <div class="text-theme-1">humidity</div>
                                  </div>
                                  <div class="text-sm px-2">
                                    <div class="float-right">
                                      {{ forecast?.wind?.speed }}km/h
                                    </div>
                                    <div class="text-theme-1">wind</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <div id="faq-accordion-content-3" class="accordion-header">
                        <button class="accordion-button collapsed text-spec" type="button" data-bs-toggle="collapse"
                          data-bs-target="#faq-accordion-collapse-3" aria-expanded="false"
                          aria-controls="faq-accordion-collapse-3">
                          {{ this.theDayAfterTomorrow }}
                        </button>
                      </div>
                      <div id="faq-accordion-collapse-3" class="accordion-collapse collapse"
                        aria-labelledby="faq-accordion-content-3" data-bs-parent="#faq-accordion-1">
                        <div class="
                            accordion-body
                            text-gray-700
                            dark:text-gray-600
                            leading-relaxed
                            flex
                            overflow-x-auto
                            overscroll-auto
                          ">
                          <div v-for="(
                              forecast, index
                            ) in theDayAfterTomorrowsWeathers" :key="index" class="inline-block justify-center py-2">
                            <div>
                              <div class="flex flex-col">
                                <div class="border rounded-md shadow-md mx-2 w-32">
                                  <div class="p-4 text-center">
                                    <div class="flex justify-center text-2xl">
                                      <img class="w-20 h-20" :src="
                                        'https://openweathermap.org/img/wn/' +
                                        forecast.weather[0].icon +
                                        '@2x.png'
                                      " />
                                    </div>
                                  </div>
                                  <div class="text-sm px-2">
                                    <div class="float-right text-theme-1">
                                      {{ Math.round(forecast?.main?.temp) }}°
                                    </div>
                                    <div class="text-theme-1">
                                      {{ forecast?.dt_txt.slice(-8, -3) }}
                                    </div>
                                  </div>
                                  <div class="text-sm px-2">
                                    <div class="float-right">
                                      {{ forecast?.main?.humidity }}%
                                    </div>
                                    <div class="text-theme-1">humidity</div>
                                  </div>
                                  <div class="text-sm px-2">
                                    <div class="float-right">
                                      {{ forecast?.wind?.speed }}km/h
                                    </div>
                                    <div class="text-theme-1">wind</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hidden lg:block w-1/2 px-4 py-2 events-parent">
        <div class="col-span-12 lg:col-span-4 2xl:col-span-3">
          <div class="intro-y box mt-5">
            <div class="items-center p-5">
              <div class="mr-auto">
                <div class="font-medium text-base capitalize text-theme-1 px-2">
                  <p>
                    <span :class="{
                      hidden:
                        fullName == 'undefined undefined' ||
                        fullName == 'undefined' ||
                        fullName == undefined
                    }">
                      {{ fullName }}'s
                    </span>
                    Upcoming Events
                  </p>
                </div>
              </div>
            </div>
            <div class="p-5 border-t border-gray-200 dark:border-dark-5">
              <div class="overflow-y-auto overscroll-auto upcoming-events">
                <div v-if="!upcomingEventsLoaded" class="upcoming-events blob">
                  <div>
                    <div class="flex justify-center align-center p-4">
                      <LifeBuoyIcon class="w-8 h-8" />
                    </div>
                    <div class="flex justify-center text-primary-6 text-md">
                      Loading...
                    </div>
                  </div>
                </div>
                <div v-if="upcomingEventsLoaded && upcomingEvents.length < 1">
                  <div class="blob">
                    <div class="flex justify-center my-4 py-4">
                      <UsersIcon class="w-8 h-8" />
                    </div>
                    <div class="flex justify-center text-primary-6 w-full text-md">
                      You have no upcoming events.
                    </div>
                  </div>
                </div>
                <div v-if="upcomingEventsLoaded">
                  <ul v-for="element in upcomingEvents" :key="element.id" class="py-2 mx-4 border rounded-md mb-2">
                    <li>
                      <div class="flex-row">
                        <p class="
                            py-1
                            text-lg
                            font-semibold
                            text-blue-300 text-center
                          ">
                          {{ element.eventDate }}
                          <ArrowDownIcon class="mx-2 pt-1 mr-auto ml-auto" /> {{ element?.eventEndDate }}
                        </p>
                      </div>
                    </li>
                    <li>
                      <div class="flex justify-center">
                        <p>
                          <span class="w-8 h-8" v-if="element.type == 'ergo'">
                            <LifeBuoyIcon class="w-12 h-12 border rounded-full p-2" />
                          </span>
                          <span class="w-8 h-8" v-if="element.type == 'water'">
                            <AnchorIcon class="w-12 h-12 border rounded-full p-2" />
                          </span>
                          <span class="w-8 h-8" v-if="element.type == 'training'">
                            <SettingsIcon class="w-12 h-12 border rounded-full p-2" />
                          </span>
                          <span class="w-8 h-8" v-if="element.type == 'race'">
                            <ZapIcon class="w-12 h-12 border rounded-full p-2" />
                          </span>
                          <span class="w-8 h-8" v-if="element.type == 'social'">
                            <UsersIcon class="w-12 h-12 border rounded-full p-2" />
                          </span>
                        </p>
                      </div>
                    </li>
                    <li>
                      <router-link :to="{ path: '/event-detail/' + element.id }">
                        <span class="
                            text-theme-1
                            flex
                            justify-center
                            pt-2
                            mb-2
                            font-semibold
                          ">
                          {{ element.name }}
                          <sup>
                            <InfoIcon class="w-3 h-3 inline-block" />
                          </sup></span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lg:hidden block px-4 py-2 events-parent">
      <div class="col-span-12 lg:col-span-4 2xl:col-span-3">
        <div class="intro-y box mt-5">
          <div class="items-center p-5">
            <div class="mr-auto">
              <div class="font-medium text-base capitalize text-theme-1 px-2">
                <p>
                  <span :class="{
                    hidden:
                      fullName == 'undefined undefined' ||
                      fullName == 'undefined' ||
                      fullName == undefined
                  }">
                    {{ fullName }}'s
                  </span>
                  Upcoming Events
                </p>
              </div>
            </div>
          </div>
          <div class="p-5 border-t border-gray-200 dark:border-dark-5">
            <div class="overflow-y-auto overscroll-auto h-96">
              <div v-if="!upcomingEventsLoaded">
                <div class="blob">
                  <div class="flex justify-center">
                    <LifeBuoyIcon class="w-auto h-auto" />
                  </div>
                  <div class="flex justify-center text-primary-6 text-md">
                    Loading...
                  </div>
                </div>
              </div>
              <div v-if="upcomingEventsLoaded && upcomingEvents.length < 1">
                <div class="blob">
                  <div class="flex justify-center my-4 py-4">
                    <UsersIcon class="w-8 h-8 mt-10" />
                  </div>
                  <div class="flex justify-center text-primary-6 text-md ">
                    You have no upcoming events.
                  </div>
                </div>
              </div>
              <div v-if="upcomingEventsLoaded">
                <ul v-for="element in upcomingEvents" :key="element.id" class="py-2 mx-4 border rounded-md mb-2">
                  <li>
                    <div class="flex-row">
                      <p class="
                          py-2
                          text-lg
                          font-semibold
                          text-blue-300 text-center text-spec
                        ">
                        {{ element.eventDate }}
                        <ArrowDownIcon class="mx-2 pt-1 mr-auto ml-auto" /> {{ element?.eventEndDate }}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div class="flex justify-center">
                      <p>
                        <span class="w-8 h-8" v-if="element.type == 'ergo'">
                          <LifeBuoyIcon class="w-12 h-12 border rounded-full p-2" />
                        </span>
                        <span class="w-8 h-8" v-if="element.type == 'water'">
                          <AnchorIcon class="w-12 h-12 border rounded-full p-2" />
                        </span>
                        <span class="w-8 h-8" v-if="element.type == 'training'">
                          <SettingsIcon class="w-12 h-12 border rounded-full p-2" />
                        </span>
                        <span class="w-8 h-8" v-if="element.type == 'race'">
                          <ZapIcon class="w-12 h-12 border rounded-full p-2" />
                        </span>
                        <span class="w-8 h-8" v-if="element.type == 'social'">
                          <UsersIcon class="w-12 h-12 border rounded-full p-2" />
                        </span>
                      </p>
                    </div>
                  </li>
                  <li>
                    <router-link :to="{ path: '/event-detail/' + element.id }">
                      <span class="
                          text-theme-1
                          flex
                          justify-center
                          pt-2
                          mb-2
                          font-semibold
                        ">
                        {{ element.name }}
                        <sup>
                          <InfoIcon class="w-3 h-3 inline-block" />
                        </sup></span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.icon-update {
  width: 14px;
  margin-top: -2px;
}

@media only screen and (min-width: 1200px) {
  .main {
    display: flex;
    justify-content: center;
  }

  .upcoming-events {
    height: 580px;
  }

  .weather {
    height: 685px;
    width: 600px;
  }
}

@media only screen and (min-width: 734px) and (max-width: 1200px) {
  .main {
    display: block;
  }

  .upcoming-events {
    height: 580px;
    width: 100%;
  }

  .events-parent {
    width: 100%;
  }
}

@media only screen and (max-width: 734px) {
  .text-spec {
    font-size: 15px;
  }

  .weather {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .weather {
    display: block;
    max-width: 320px;
    margin: -15px 0px 0px -15px;
  }
}

@media only screen and (max-width: 320px) {
  .text-spec {
    font-size: 15px;
  }

  .weather {
    max-width: 260px;
  }
}

.blob {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@media only screen and (min-width: 734px) {
  .blob {
    padding: 130px 100px 20px 100px;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
</style>

<script>
import { computed, onMounted } from 'vue'
import { mapState } from 'vuex'
import { useStore } from '@/store'
import moment from 'moment'

export default {
  data: function () {
    return {
      locationLatLong: {},
      getLocation: false,
      cityName: '',
      errors: null,
      onLine: navigator.onLine,
      showBackOnline: false,
      weatherStatus: '',
      icon: '',
      todaysWeathers: [],
      tomorrowsWeathers: [],
      theDayAfterTomorrowsWeathers: [],
      todaysWeather1Icon: '',
      upcomingEventsLoaded: false,
      today: '',
      tomorrow: '',
      theDayAfterTomorrow: '',
      locationName: ''
    }
  },
  created() {
    const vm = this
    var latitude = localStorage.getItem('latitude')
    var longitude = localStorage.getItem('longitude')
    if (latitude == null || longitude == null) {
      vm.getWeatherByLocation()
    } else {
      this.$store.dispatch('dashboard/getWeatherData', {
        lat: `${latitude}`,
        lon: `${longitude}`
      })
    }
  },
  methods: {
    updateLocation() {
      localStorage.clear()
      this.getWeatherByLocation()
      this.refresh()
    },
    getWeatherByLocation() {
      const { setErrors, $store } = this
      if (!('geolocation' in navigator)) {
        setErrors('Geolocation is not available.')
      } else {
        this.getLocation = true
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            this.getLocation = false
            this.locationLatLong = pos
            localStorage.setItem(
              'latitude',
              JSON.stringify(this.locationLatLong.coords.latitude)
            )
            localStorage.setItem(
              'longitude',
              JSON.stringify(this.locationLatLong.coords.longitude)
            )
            $store.dispatch('dashboard/getWeatherData', {
              lat: `${pos.coords.latitude}`,
              lon: `${pos.coords.longitude}`
            })
          },
          (err) => {
            this.getLocation = false
            setErrors(err.message)
          }
        )
      }
    },
    refresh() {
      return this.$router.go()
    },
    setErrors(errors) {
      this.errors = errors
    },
    updateOnlineStatus(e) {
      const { type } = e
      this.onLine = type === 'online'
    }
  },
  watch: {
    weatherData() {
      this.locationName =
        this.$store.state.dashboard.weather.city.name +
        ', ' +
        this.$store.state.dashboard.weather.city.country
      this.today = moment().format('LLLL').slice(0, -8)
      this.tomorrow = moment(moment(this.today, 'LLLL').add(1, 'days'))
        .format('LLLL')
        .slice(0, -8)
      this.theDayAfterTomorrow = moment(
        moment(this.tomorrow, 'LLLL').add(1, 'days')
      )
        .format('LLLL')
        .slice(0, -8)
      var step1 = 0
      var step2 = 0
      var step3 = 0
      while (step1 < 9) {
        if (
          moment(this.today).format('D') ==
          moment(this.$store.state.dashboard.weather.list[step1].dt_txt).format(
            'D'
          )
        ) {
          this.todaysWeathers.push(
            this.$store.state.dashboard.weather.list[step1]
          )
          step1++
        } else {
          break
        }
      }
      step2 = step1 + 8
      while (step1 < step2) {
        if (
          moment(this.tomorrow).format('D') ==
          moment(this.$store.state.dashboard.weather.list[step1].dt_txt).format(
            'D'
          )
        ) {
          this.tomorrowsWeathers.push(
            this.$store.state.dashboard.weather.list[step1]
          )
          step1++
        } else {
          break
        }
      }
      step3 = step2 + 8
      while (step2 < step3) {
        if (
          moment(this.theDayAfterTomorrow).format('D') ==
          moment(this.$store.state.dashboard.weather.list[step2].dt_txt).format(
            'D'
          )
        ) {
          this.theDayAfterTomorrowsWeathers.push(
            this.$store.state.dashboard.weather.list[step2]
          )
          step2++
        } else {
          break
        }
      }
      this.todaysWeather1 = this.$store.state.dashboard.weather.list[0]
      this.weatherStatus = this.$store.state.dashboard.weather
      this.icon =
        'https://openweathermap.org/img/wn/' +
        this.todaysWeather1.weather[0].icon +
        '@2x.png'
      this.todaysWeather1Icon =
        'https://openweathermap.org/img/wn/' +
        this.$store.state.dashboard.weather.list[0].weather[0].icon +
        '@2x.png'
    },
    upcomingEvents() {
      if (this.$store.state.events.upcomingEventsLoaded.message) {
        this.upcomingEventsLoaded = true
      }
    }
  },
  mounted() {
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)
  },
  beforeUnmount() {
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
  },
  computed: {
    ...mapState({
      city: (state) => state.currentCity.data
    })
  },
  setup() {
    const upcomingEvents = computed(() => store.state.events.upcomingEvents)
    const fullName = computed(
      () =>
        store.state.profile.user?.firstName +
        ' ' +
        store.state.profile.user?.lastName
    )
    const store = useStore()
    onMounted(() => {
      store.dispatch('events/getUpcomingEvents')
      store.dispatch('profile/getProfileData')
    })

    const weatherData = computed(() => store.state.dashboard.weather)
    return {
      upcomingEvents,
      fullName,
      weatherData
    }
  }
}
</script>
