import axios from 'axios'
import Toastify from 'toastify-js'
import moment from 'moment'
import { useStore } from '@/store'
export const GET_SESSIONS = 'GET_SESSIONS'
export const DELETE_BOOKING = 'DELETE_BOOKING'
export const ADD_BOOKING = 'ADD_BOOKING'
export const GET_BOAT_BOOKINGS = 'GET_BOAT_BOOKINGS'
export const GET_ALL_BOOKINGS = 'GET_ALL_BOOKINGS'
export const GET_MY_BOOKINGS = 'GET_MY_BOOKINGS'

const state = () => {
  return {
    boatBookings: [
    ],
    allBookings: [
    ],
    myBookings: [
    ],
    sessions: [
    ],
    addBooking: {
      isSuccess: null
    },
    myBookingsLoaded: {
      message: null
    }
  }
}

// getters
const getters = {
  boatBookings: state => {
    return state.boatBookings
  },
  allBookings: state => {
    return state.allBookings
  },
  myBookings: state => {
    return state.myBookings
  },
  sessions: state => {
    return state.sessions
  }
}

// actions
const actions = {
  getBoatBookings({ commit }, data) {
    commit(GET_BOAT_BOOKINGS, data)
  },
  getAllBookings({ commit }, data) {
    commit(GET_ALL_BOOKINGS, data)
  },
  getMyBookings({ commit }, data) {
    commit(GET_MY_BOOKINGS, data)
  },
  getSessions({ commit }, data) {
    commit(GET_SESSIONS, data)
  },
  addBoatBooking({ commit }, params) {
    commit(ADD_BOOKING, params)
  },
  deleteBooking({ commit }, params) {
    commit(DELETE_BOOKING, params)
  }
}

// mutations
const mutations = {
  [GET_BOAT_BOOKINGS](state, params) {
    axios
      .get(process.env.VUE_APP_API_BASE_URL + '/api/book-boat/' + params.id + '?startDate=' + +params.today + '&endDate=' + +params.nextWeek)
      .then(response => {
        state.boatBookings = response.data
        if (state.boatBookings.length > 0) {
          for (var i = 0; i < state.boatBookings.length; i++) {
            state.boatBookings[i].startDate = moment(state.boatBookings[i].startDate * 1000).format('lll')
            state.boatBookings[i].endDate = moment(state.boatBookings[i].endDate * 1000).format('lll')
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
  [GET_ALL_BOOKINGS](state, params) {
    axios
      .get(process.env.VUE_APP_API_BASE_URL + '/api/boat-bookings/')
      .then(response => {
        state.allBookings = response.data.data
        if (state.allBookings.length > 0) {
          for (var i = 0; i < state.allBookings.length; i++) {
            state.allBookings[i].startDate = moment(state.allBookings[i].startDate * 1000).format('lll')
            state.allBookings[i].endDate = moment(state.allBookings[i].endDate * 1000).format('hh:mm A')
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
  [GET_MY_BOOKINGS](state, params) {
    axios
      .get(process.env.VUE_APP_API_BASE_URL + '/api/me/boat-bookings')
      .then(response => {
        state.myBookings = response.data.data
        state.myBookingsLoaded.message = true

        if (state.myBookings.length > 0) {
          for (var i = 0; i < state.myBookings.length; i++) {
            state.myBookings[i].startDate = moment(state.myBookings[i].startDate * 1000).format('lll')
            state.myBookings[i].endDate = moment(state.myBookings[i].endDate * 1000).format('hh:mm A')
          }
          if (response.status == 200) {
            state.addBooking.isSuccess = null
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
  [GET_SESSIONS](state, params) {
    axios
      .get(process.env.VUE_APP_API_BASE_URL + '/api/book-boat/timeslots/' + params.id + '?day=' + params.date)
      .then(response => {
        state.sessions = response.data
        if (state.sessions.length > 0) {
          for (var i = 0; i < state.sessions.length; i++) {
            state.sessions[i].start = moment(state.sessions[i].startDate * 1000).format('hh:mm a')
            state.sessions[i].end = moment(state.sessions[i].endDate * 1000).format('hh:mm a')
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
  [ADD_BOOKING](state, params) {
    const store = useStore()
    axios.post(process.env.VUE_APP_API_BASE_URL + '/api/book-boat', {
      boatId: params.boat,
      startDate: params.startTime,
      endDate: params.endTime
    })
      .then(response => {
        if (response.data.message) {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#91C714',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        } else {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#D32929',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        }
        state.addBooking.isSuccess = response.data.message
      })
      .catch(error => {
        console.log(error)
      })
    store.dispatch('bookings/getBoatBookings', { id: params.boat, today: params.startTime, nextWeek: params.endTime })
    params.startTime = moment(params.startTime * 1000).format('YYYY-MM-DD')
    store.dispatch('bookings/getSessions', { id: params.boat, date: params.startTime })
  },
  [DELETE_BOOKING](state, id) {
    axios.delete(process.env.VUE_APP_API_BASE_URL + '/api/book-boat/' + id)
      .then((response) => {
        state.myBookings.map((booking) => {
          if (id == booking.id) {
            state.myBookings =
              state.myBookings.filter((booking) => {
                return booking.id != id
              })
          }
        })
        state.allBookings.map((booking) => {
          if (id == booking.id) {
            state.allBookings =
              state.allBookings.filter((booking) => {
                return booking.id != id
              })
          }
        })
        state.myBookings
        state.allBookings
        if (response.data.message) {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#91C714',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        } else {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#D32929',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        }
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
