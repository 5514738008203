<template>
  <section>
    <div class="container mx-auto px-2 py-4">
      <div class="flex flex-wrap">
        <div class="w-full">
          <div class="pt-4 bg-white rounded">
            <div class="px-6 border-b border-blue-50">
              <div class="py-2">
                <label for="crud-form-1" class="form-label flex">
                  <span
                    class="
                      inline-flex
                      justify-center
                      border border-1 border-black
                      rounded
                    "
                  >
                    <router-link :to="{ path: '/user-groups' }">
                      <div class="flex gap-1">
                        <ArrowLeftIcon />
                      </div>
                    </router-link>
                  </span>
                  <span class="flex items-center px-2">User Groups</span>
                </label>
              </div>
              <!-- router -->
              <div v-if="isAvailable" class="float-right px-1 py-2">
                <router-link
                  :to="{
                    path: '/add-athlete-to-group',
                    query: { groupId: this.$route.params.id }
                  }"
                  class="
                    btn
                    float-right
                    border border-black
                    font-bold
                    px-2
                    py-1
                    text-sm
                    outline-none
                    rounded
                    focus:outline-none
                    ease-linear
                    transition-all
                    duration-150
                  "
                >
                  <div class="flex gap-1"><PlusIcon />Add Athlete</div>
                </router-link>
              </div>
              <div class="flex flex-wrap items-center">
                <div v-if="isLoaded">
                  <h3
                    class="text-xl font-bold px-1 py-2"
                    :class="{
                      //loading state
                      hidden:
                        groupDetail?.groupName == 'undefined undefined' ||
                        groupDetail?.groupName == 'undefined' ||
                        groupDetail?.groupName == undefined
                    }"
                  >
                    Athletes in {{ groupDetail?.groupName }}
                  </h3>
                </div>
              </div>
              <div class="w-56 relative text-gray-700 dark:text-gray-300 py-2">
                <input
                  type="text"
                  class="form-control w-56 box pr-10 placeholder-theme-13"
                  placeholder="Search..."
                  v-model="search.filter"
                />
                <SearchIcon
                  class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
                />
              </div>
            </div>
            <!-- START: EMPTY LIST -->
            <div v-if="isLoaded && filteredItems.length < 1">
              <div class="w-full p-4">
                <div class="flex justify-center mt-6">
                  <UserXIcon class="w-12 h-12" />
                </div>
                <div class="flex justify-center text-lg p-4">
                  There aren't any users in this group.
                </div>
              </div>
            </div>
            <!-- END: EMPTY LIST -->
            <div class="hidden lg:block">
              <table class="w-full">
                <thead
                  v-if="isLoaded && filteredItems.length > 0"
                  class="bg-gray-50"
                >
                  <tr class="border-b text-sm border-blue-50">
                    <th class="p-2 text-theme-1">User Name</th>
                    <th class="p-2 text-theme-1">Status</th>
                    <th class="p-2 text-theme-1">User Groups</th>
                  </tr>
                </thead>
                <!-- START: loading animation for L views -->
                <tbody v-if="!isLoaded">
                  <tr
                    v-for="el in 4"
                    :key="el.id"
                    class="w-full p-2 mx-4 my-6 border-b"
                  >
                    <td
                      class="
                        w-1/4
                        text-center
                        border-b
                        lg:table-cell
                        static
                        m-4
                        my-2
                        px-2
                      "
                    >
                      <p class="px-2 mx-4 w-full h-6 blob bg-theme-1"></p>
                    </td>
                    <td
                      class="
                        w-1/7
                        text-center
                        border-b
                        lg:table-cell
                        static
                        m-4
                        my-2
                        px-2
                      "
                    >
                      <p class="px-2 mx-4 w-full h-6 blob bg-theme-8"></p>
                    </td>
                    <td
                      class="
                        w-1/4
                        text-center
                        border-b
                        lg:table-cell
                        static
                        m-4
                        my-2
                        px-2
                      "
                    >
                      <span>
                        <p class="px-4 mx-2 w-full h-6 blob bg-theme-8"></p>
                      </span>
                    </td>
                  </tr>
                </tbody>
                <!-- END: loading animation for L views -->
                <tbody
                  v-if="
                    isLoaded &&
                    filteredItems.length > 0 &&
                    groupDetail?.groupName.length > 0
                  "
                >
                  <tr
                    v-for="element in filteredItems"
                    :key="element.id"
                    class="w-full p-2 mx-4 my-6 border-b hover:bg-theme-14"
                  >
                    <td
                      class="
                        w-1/7
                        text-theme-1 text-center
                        border-b
                        lg:table-cell
                        static
                      "
                    >
                      <p class="py-1 px-2 text-sm mx-4 pt-2">
                        {{ element.firstName + ' ' + element.lastName }}
                      </p>
                    </td>
                    <td
                      v-if="element.active"
                      class="
                        w-1/7
                        text-theme-9 text-center
                        border-b
                        lg:table-cell
                        static
                      "
                    >
                      <p class="py-1 px-2 text-sm mx-4 pt-2">active</p>
                    </td>
                    <td
                      v-else
                      class="
                        w-1/7
                        text-theme-6 text-center
                        border-b
                        lg:table-cell
                        static
                      "
                    >
                      <p class="py-1 px-2 text-sm mx-4 pt-2">passive</p>
                    </td>
                    <td
                      class="
                        w-1/7
                        text-theme-1 text-center
                        border-b
                        lg:table-cell
                        static
                      "
                    >
                      <span class="py-1 px-2 text-sm mx-4 pt-2">
                        <div class="flex flex-wrap justify-center">
                          <span
                            v-for="group in element.userGroups"
                            :key="group.id"
                            class="
                              px-2
                              py-1
                              rounded-lg
                              bg-theme-8
                              text-theme-1
                              mr-1
                              m-2
                            "
                          >
                            {{ group.groupName }}
                            <a
                              v-if="isAvailable"
                              href="javascript:;"
                              data-toggle="modal"
                              data-target="#delete-modal-preview"
                              class="flex justify-center"
                              @click="
                                ;(this.selectedGroup = group.id),
                                  (this.selectedElement = element.id)
                              "
                            >
                              <Trash2Icon class="text-theme-6 p-1" />
                            </a>
                          </span>
                        </div>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- START: DELETE USER GROUP | M-L view -->
            <div class="hidden lg:block px-2 mt-4">
              <div class="float-right px-2">
                <a
                  v-if="isAvailable"
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#delete-group-preview"
                  @click="this.selectedGroupId = this.$route.params.id"
                  class="text-theme-6 flex items-center"
                >
                  <Trash2Icon class="w-4 h-4 mr-1" /> Delete User Group
                </a>
              </div>
            </div>
            <!-- END: DELETE USER GROUP | M-L view -->
            <div class="lg:hidden xl:hidden p-2">
              <!-- START: loading animation for S-M views  -->
              <div v-if="!isLoaded && filteredItems.length > 0" disabled>
                <ul
                  v-for="el in 4"
                  :key="el.id"
                  class="w-full border-b py-2 mx-4"
                >
                  <li>
                    <div class="flex-row">
                      <li
                        class="py-1 text-lg bg-theme-1 my-2 px-2 w-64 h-6 blob"
                      ></li>
                    </div>
                  </li>
                  <li>
                    <span class="inline-block">
                      <li
                        class="py-1 text-sm bg-theme-8 mr-2 px-2 w-32 h-6 blob"
                      ></li>
                    </span>
                  </li>
                  <li>
                    <span class="inline-block">
                      <p class="py-1 text-sm bg-theme-8 mr-2 px-2 blob"></p>
                    </span>
                  </li>
                  <li>
                    <span class="inline-block">
                      <li
                        class="py-1 text-sm bg-theme-8 mr-2 px-2 w-32 h-6 blob"
                      ></li>
                    </span>
                  </li>
                </ul>
              </div>
              <!-- END: loading animation for S-M views  -->
              <div v-if="isLoaded" class="p-2">
                <ul
                  v-for="element in filteredItems"
                  :key="element.id"
                  class="border border-1 border-theme-14 rounded-md my-2"
                >
                  <div>
                    <li class="bg-theme-14 p-2">
                      <div>
                        <span class="text-md font-semibold text-theme-19">{{
                          element.firstName + ' ' + element.lastName
                        }}</span>
                      </div>
                    </li>
                    <li v-if="element.active" class="px-2 py-1">
                      <div>
                        <p class="text-md text-theme-9">
                          <span class="text-md font-semibold text-theme-19"
                            >Status: </span
                          >Active
                        </p>
                      </div>
                    </li>
                    <li v-else class="px-2 py-1">
                      <div>
                        <p class="text-md text-theme-6">
                          <span class="text-md font-semibold text-theme-19"
                            >Status: </span
                          >Passive
                        </p>
                      </div>
                    </li>
                    <li class="px-2 py-1">
                      <div>
                        <div class="flex flex-col">
                          <div
                            v-for="group in element.userGroups"
                            :key="group.id"
                            class="
                              flex-row
                              px-2
                              py-1
                              rounded-lg
                              bg-theme-8
                              text-theme-1
                              mr-1
                              m-2
                            "
                          >
                            {{ group.groupName }}
                            <a
                              v-if="isAvailable"
                              href="javascript:;"
                              data-toggle="modal"
                              data-target="#delete-modal-preview"
                              @click="
                                ;(this.selectedGroup = group.id),
                                  (this.selectedElement = element.id)
                              "
                            >
                              <Trash2Icon
                                class="float-right text-theme-6 p-1"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                  </div>
                </ul>
              </div>
              <div class="lg:hidden px-2 mt-4">
                <div class="float-right px-2">
                  <a
                    v-if="isAvailable"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-group-preview"
                    @click="this.selectedGroupId = this.$route.params.id"
                    class="text-theme-6 flex items-center"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" /> Delete User Group
                  </a>
                </div>
              </div>
            </div>
            <!-- START: DELETE GROUP-->
            <div id="delete-modal" class="p-5">
              <div class="preview">
                <!-- START: Modal Content -->
                <div
                  id="delete-group-preview"
                  class="modal"
                  tabindex="-1"
                  aria-hidden="true"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-body p-0">
                        <div class="p-5 text-center">
                          <XCircleIcon
                            class="w-16 h-16 text-theme-6 mx-auto mt-3"
                          />
                          <div class="text-3xl mt-5">Are you sure?</div>
                          <div class="text-gray-600 mt-2">
                            This process cannot be undone.
                          </div>
                        </div>
                        <div class="px-5 pb-8 text-center">
                          <button
                            type="button"
                            data-dismiss="modal"
                            class="
                              btn btn-outline-secondary
                              w-24
                              dark:border-dark-5 dark:text-gray-300
                              mr-1
                            "
                          >
                            Cancel
                          </button>
                          <button
                            @click="deleteGroup(this.selectedGroupId)"
                            type="button"
                            class="btn btn-danger w-24"
                            data-dismiss="modal"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END: Modal Content -->
              </div>
            </div>
            <!-- END: DELETE GROUP -->
            <div id="delete-modal" class="p-5">
              <div class="preview">
                <!-- BEGIN: Modal Content -->
                <div
                  id="delete-modal-preview"
                  class="modal"
                  tabindex="-1"
                  aria-hidden="true"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-body p-0">
                        <div class="p-5 text-center">
                          <XCircleIcon
                            class="w-16 h-16 text-theme-6 mx-auto mt-3"
                          />
                          <div class="text-3xl mt-5">Are you sure?</div>
                          <div class="text-gray-600 mt-2">
                            This process cannot be undone.
                          </div>
                        </div>
                        <div class="px-5 pb-8 text-center">
                          <button
                            type="button"
                            data-dismiss="modal"
                            class="
                              btn btn-outline-secondary
                              w-24
                              dark:border-dark-5 dark:text-gray-300
                              mr-1
                            "
                          >
                            Cancel
                          </button>
                          <button
                            @click="
                              deleteGroupFromAthlete(
                                this.selectedElement,
                                this.selectedGroup
                              )
                            "
                            type="button"
                            class="btn btn-danger w-24"
                            data-dismiss="modal"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END: Modal Content -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { computed, onMounted, reactive } from 'vue'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'

export default {
  name: 'Group Detail',
  data() {
    return {
      selectedGroup: '',
      selectedElement: '',
      isLoaded: false
    }
  },
  watch: {
    groupDetail() {
      if (this.$store.state.groups.detailLoaded.message) {
        this.isLoaded = true
      }
    },
    deleteSuccess() {
      if (this.deleteSuccess) {
        this.$router.back()
      }
    }
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const group = computed(() => route.params.id)
    const search = reactive({
      filter: ''
    })
    const groupDetail = computed(() => store.state.groups.groupDetail.data)
    const role = computed(() => store.state.profile.user?.userRole?.name)
    const athletes = computed(() => store.state.groups.athletesInGroup)
    const isAvailable = computed(() => (store.state.profile.user?.userRole?.name == 'Coach' || store.state.profile.user?.userRole?.name == 'Super Admin'))

    const deleteSuccess = computed(
      () => store.state.groups.deleteGroup.isSuccess
    )
    onMounted(() => {
      store.dispatch('profile/getProfileData')
      store.dispatch('groups/getAthletesData', route.params.id)
      store.dispatch('groups/getGroupDetail', route.params.id)
    })
    const filteredItems = computed(() => {
      return store.state.groups.athletesInGroup.filter((element) => {
        const fullName = element.firstName + ' ' + element.lastName
        return fullName.toLowerCase().includes(search.filter.toLowerCase())
      })
    })
    const deleteGroupFromAthlete = (userId, groupId) => {
      store.dispatch('groups/deleteGroupFromAthlete', { userId, groupId })
    }
    const deleteGroup = (groupId) => {
      store.dispatch('groups/deleteUserGroup', groupId)
    }
    return {
      athletes,
      search,
      filteredItems,
      deleteSuccess,
      deleteGroupFromAthlete,
      deleteGroup,
      group,
      groupDetail,
      role,
      isAvailable
    }
  }
}
</script>
