import { createApp } from 'vue'
import { initializeApp } from 'firebase/app'
import { onAuthStateChanged, getAuth, onIdTokenChanged } from 'firebase/auth'
import App from './App.vue'
import router from './router'
import store from './store'
import globalComponents from './global-components'
import utils from './utils'
import './libs'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import VCalendar from 'v-calendar'
import 'v-calendar/dist/style.css'

// SASS Theme
import './assets/sass/app.scss'
import axios from 'axios'

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAufx_zx4BAnAtffxhGYGCSktAShaoLK7E',
  authDomain: 'rowerhead-706c9.firebaseapp.com',
  projectId: 'rowerhead-706c9',
  storageBucket: 'rowerhead-706c9.appspot.com',
  messagingSenderId: '431151396617',
  appId: '1:431151396617:web:2d4a4780017191572c05d8'
}

initializeApp(firebaseConfig)

const auth = getAuth()
let app = null

onAuthStateChanged(auth, user => {
  if (!app) {
    app = createApp(App)
      .use(store)
      .use(router)
      .use(VCalendar, {})
    globalComponents(app)
    utils(app)
    app.component('v-select', vSelect)

    axios.interceptors.request.use(function(config) {
      config.headers.Authorization = user?.accessToken
      return config
    })
    app.mount('#app')
  }
})

onIdTokenChanged(auth, user => {
  axios.interceptors.request.use(function(config) {
    if (user?.accessToken !== undefined) {
      config.headers.Authorization = user?.accessToken
    }

    return config
  })
})
