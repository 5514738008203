import axios from 'axios'
import Toastify from 'toastify-js'
import { useStore } from '@/store'
export const USER = 'USER'
export const INVITE_USER = 'INVITE_USER'
export const ADD_USER = 'ADD_USER'

const state = () => {
  return {
    user: {},
    inviteAthlete: {
      isFormSubmitted: false,
      isSuccess: null
    }
  }
}

// getters
const getters = {
  user: state => {
    return state.user
  }
}

// actions
const actions = {
  getUserData({ commit }, id) {
    commit(USER, id)
  },
  inviteUser({ commit }, params) {
    commit(INVITE_USER, params)
  },
  addUser({ commit }, params) {
    commit(ADD_USER, params)
  }
}

// mutations
const mutations = {
  [USER](state, id) {
    axios
      .get(process.env.VUE_APP_API_BASE_URL + '/api/user/invite/' + id)
      .then(response => {
        state.user = response.data.data
      })
      .catch(error => {
        console.log(error)
      })
  },
  [INVITE_USER](state, params) {
    state.inviteAthlete.isFormSubmitted = true
    axios
      .post(process.env.VUE_APP_API_BASE_URL + '/api/user/invite', {
        email: params.email,
        firstName: params.firstName,
        lastName: params.lastName,
        userGroups: params.groups.map((groupId) => { return { id: groupId } })
      })
      .then(response => {
        if (response.data.message) {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#91C714',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        } else {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#D32929',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        }
        state.inviteAthlete.isSuccess = response.data.success
      })
      .catch(error => {
        console.log(error)
      })
    state.inviteAthlete.isSuccess = null
  },
  [ADD_USER](state, params, user) {
    const store = useStore()
    axios
      .post(process.env.VUE_APP_API_BASE_URL + '/api/user/invite/register/' + params.id, {
        password: params.password
      })
      .then(response => {
        const credential = { email: state.user.email, password: params.password }
        if (response.data.success == true) {
          store.dispatch('auth/setLoggedIn', credential)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
