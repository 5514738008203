import { createRouter, createWebHistory } from 'vue-router'
import SideMenu from '../layouts/side-menu/Main.vue'
import DashboardOverview1 from '../views/dashboard-overview-1/Main.vue'
import Calendar from '../components/calendar/Main.vue'
import YourEvents from '../components/your-events/Main.vue'
import EventDetail from '../components/your-events/EventDetail.vue'
import BoatBooking from '../components/boat-booking/BoatBooking.vue'
import Inventory from '../components/inventory/Main.vue'
import Athletes from '../components/athletes/Main.vue'
import AthleteDetail from '../components/athletes/AthleteDetail.vue'
import Announcements from '../components/announcements/Main.vue'
import BoatDetail from '../components/boat-detail/Main.vue'
import ErgoDetail from '../components/ergo-detail/Main.vue'
import OtherDetail from '../components/other-detail/Main.vue'
import UserGroups from '../components/user-groups/UserGroups.vue'
import GroupDetail from '../components/user-groups/GroupDetail.vue'
import RegisterFromInvite from '../components/club-manager-onboarding/RegisterFromInvite.vue'
import AddEvent from '../components/your-events/AddEvent.vue'
import AddBooking from '../components/boat-booking/AddBooking.vue'
import InviteAthlete from '../components/athletes/InviteAthlete.vue'
import AddInventory from '../components/inventory/AddInventory.vue'
import AddRepair from '../components/boat-detail/AddRepair.vue'
import AddAthleteToGroup from '../components/user-groups/AddAthleteToGroup'
import AddUserGroup from '../components/user-groups/AddUserGroup.vue'
import Profile from '../components/user/Profile.vue'
import Login from '../views/login/Main.vue'
import Register from '../views/register/Main.vue'
import RegisterForClub from '../views/register-for-club/Main.vue'
import ErrorPage from '../views/error-page/Main.vue'
import ChangePassword from '../views/change-password/Main.vue'
import ForgotPassword from '../views/forgot-password/Main.vue'
import { getAuth } from 'firebase/auth'
// import { getAuth } from 'firebase/auth'

const routes = [
  {
    path: '/',
    component: SideMenu,
    children: [
      {
        path: '/',
        name: 'side-menu-dashboard-overview-1',
        component: DashboardOverview1
      },
      {
        path: '/calendar',
        name: 'side-menu-calendar',
        component: Calendar
      },
      {
        path: '/your-events',
        name: 'side-menu-your-events',
        component: YourEvents
      },
      {
        path: '/event-detail/:id',
        name: 'side-menu-event-detail',
        component: EventDetail
      },
      {
        path: '/boat-booking/',
        name: 'side-menu-boat-booking',
        component: BoatBooking
      },
      {
        path: '/inventory',
        name: 'side-menu-inventory',
        component: Inventory
      },
      {
        path: '/athletes',
        name: 'side-menu-athletes',
        component: Athletes
      },
      {
        path: '/athlete-detail/:id',
        name: 'side-menu-athlete-detail',
        component: AthleteDetail
      },
      {
        path: '/announcements',
        name: 'side-menu-announcements',
        component: Announcements
      },
      {
        path: '/boat-detail/:id',
        name: 'side-menu-boat-detail',
        component: BoatDetail
      },
      {
        path: '/ergo-detail/:id',
        name: 'side-menu-ergo-detail',
        component: ErgoDetail
      },
      {
        path: '/other-detail/:id',
        name: 'side-menu-other-detail',
        component: OtherDetail
      },
      {
        path: '/user-groups/',
        name: 'side-menu-user-groups',
        component: UserGroups
      },
      {
        path: '/group-detail/:id',
        name: 'side-menu-group-detail',
        component: GroupDetail
      },
      {
        path: '/add-event/',
        name: 'side-menu-add-event',
        component: AddEvent
      },
      {
        path: '/invite-athlete/',
        name: 'side-menu-invite-athlete',
        component: InviteAthlete
      },
      {
        path: '/add-inventory/',
        name: 'side-menu-add-inventory',
        component: AddInventory
      },
      {
        path: '/add-group/',
        name: 'side-menu-add-group',
        component: AddUserGroup
      },
      {
        path: '/add-athlete-to-group/',
        name: 'side-menu-add-athlete-to-group',
        component: AddAthleteToGroup
      },
      {
        path: '/add-repair/:id',
        name: 'side-menu-add-repair',
        component: AddRepair
      },
      {
        path: '/add-booking/',
        name: 'side-menu-add-booking',
        component: AddBooking
      },
      {
        path: '/profile/',
        name: 'side-menu-profile',
        component: Profile
      },
      {
        path: 'change-password',
        name: 'side-menu-change-password',
        component: ChangePassword
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/register-for-club',
    name: 'register-for-club',
    component: RegisterForClub
  },
  {
    path: '/register/invite/:id',
    name: 'register-invite',
    component: RegisterFromInvite
  },
  {
    path: '/error-page',
    name: 'error-page',
    component: ErrorPage
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword
  },
  {
    path: '/:pathMatch(.*)*',
    component: ErrorPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  }
})

router.beforeEach((to, from, next) => {
  const user = getAuth().currentUser
  if ((to.name !== 'login' && to.name !== 'register' && to.name !== 'forgot-password' && to.name !== 'register-invite' && to.name !== 'register-for-club') && !user) next({ path: '/login' })
  else if (to.name == 'login' && user) next({ path: '/' })
  else if (to.name == 'register-for-club' && user) next({ path: '/' })
  else if (to.name == 'register-invite' && user) next({ path: '/' })
  else next()
})
export default router
