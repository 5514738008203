<template>
  <div id="app">
    <div>
      <div class="p-5 border-b border-gray-200 dark:border-dark-5">
        <h2 class="font-medium text-base mr-auto">Event Line Up</h2>
      </div>
      <div class="px-5">
        <div class="flex xl:flex-row flex-col">
          <div class="flex-1 mt-6 xl:mt-0">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 2xl:col-span-6">
                <label v-if="isAvailable" class="form-label mt-3">Select Boat
                </label>
                <div v-if="isAvailable">
                  <TomSelect v-model="lineUpData.boat" :options="{
                    placeholder: 'Select a Boat',
                    allowEmptyOption: true
                  }" id="boatSelect" class="w-full">
                    <option id="boatNone" value="None">None</option>
                    <option v-for="boat in boats" :key="boat.id" :value="boat.id" :selectedBoatId="boat.id">
                      {{ boat?.name }}
                    </option>
                  </TomSelect>
                </div>
                <div v-if="isAvailable">
                  <label for="update-profile-form-1" class="form-label mt-3">Boat Type:</label>
                  <p class="form-control">
                    Type: {{ selectedBoat?.boatType?.boatType }} |
                    <span v-if="selectedBoat?.boatType?.cox">
                      Coxed |
                    </span>
                    <span v-else> Not Coxed | </span>
                    <span v-if="selectedBoat?.boatType?.sweep">
                      Sweep |
                    </span>
                    <span v-else> Scull | </span>
                    Crew Size: {{ selectedBoat?.boatType?.crewSize }}
                  </p>
                  <div class="mt-3">
                    <a @click="submitLineUp()" href="javascript:;" data-toggle="modal"
                      data-target="#small-modal-size-preview" class="btn btn-primary" v-if="
                        (event?.eventDateTime.timestamp >= now) &&
                        (lineUpData.boat !== '') &&
                        (selectedBoat?.boatType?.totalCrewSize <=
                          this.filteredAthletes?.length)
                      ">
                      Add Boat
                    </a>
                    <a @click="lineUpError()" class="btn btn-primary" v-if="
                      (event?.eventDateTime.timestamp < now) ||
                      (lineUpData.boat == '') ||
                      (selectedBoat?.boatType?.totalCrewSize >
                        this.filteredAthletes?.length) ||
                        (this.filteredAthletes == undefined)
                    ">
                      Add Boat
                    </a>
                    <!-- START: Small Modal Content -->
                    <div id="small-modal-size-preview" class="modal" tabindex="-1" aria-hidden="true"
                      data-backdrop="static">
                      <div class="modal-dialog modal-sm">
                        <div class="modal-content">
                          <div class="modal-body p-10 text-center">
                            <h1 class="text-lg border-b border-black">
                              Select Seat
                            </h1>
                            <div class="flex flex-col-reverse">
                              <div v-for="(field, index) in selectedBoat
                                ?.boatType?.crewSize" :key="index">
                                <div class="
                                                p-2
                                                text-4xl
                                                font-bold
                                                text-theme-10
                                              ">
                                  <div class="dropdown inline-block" data-placement="right">
                                    <button>
                                      {{ field }}
                                      <select @change="
                                        addAthlete(
                                          index + 1,
                                          $event.target?.value
                                        )
                                      " :id="index" class="
                                                      flex
                                                      form-select
                                                      bg-white
                                                      form-select-sm
                                                      sm:mt-2 sm:mr-2
                                                      w-44
                                                    " aria-label=".form-select-lg example">
                                        <option value="none">
                                          None
                                        </option>
                                        <option :id="athlete.userId" :selected="athlete == value"
                                          v-for="athlete in filteredAthletes" :key="athlete.userId" :value="
                                            JSON.stringify(athlete)
                                          ">
                                          {{ athlete?.fullName }}
                                        </option>
                                      </select>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div v-if="selectedBoat?.boatType?.cox" class="
                                              p-2
                                              text-4xl
                                              font-bold
                                              text-theme-10
                                            ">
                                <div class="dropdown inline-block" data-placement="right">
                                  <button>
                                    Cox
                                    <select @change="
                                      addAthlete(
                                        'c',
                                        $event.target?.value
                                      )
                                    " id="cox" class="
                                                    flex
                                                    form-select
                                                    bg-white
                                                    form-select-sm
                                                    sm:mt-2 sm:mr-2
                                                    w-44
                                                  " aria-label=".form-select-lg example">
                                      <option value="none">
                                        None
                                      </option>
                                      <option :id="athlete.userId" :selected="athlete == value"
                                        v-for="athlete in filteredAthletes" :key="athlete.userId" :value="
                                          JSON.stringify(athlete)
                                        ">
                                        {{ athlete?.fullName }}
                                      </option>
                                    </select>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div @click="cancel()" class="btn btn-danger mt-4 p-2 mx-2" data-dismiss="modal">
                              cancel
                            </div>
                            <button @click="saveEventLineUps()" class="btn btn-primary mt-4 p-2 mx-2" data-dismiss="modal"
                              id="save" disabled>
                              save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- END: Small Modal Content -->
                  </div>
                </div>
                <label class="form-label mt-3">Recently Created:
                </label>
                <div class="text-lg font-bold text-theme-6" v-if="lineUpsData?.length < 1">
                  -No lineups have been created yet-
                </div>
                <div v-for="(lineUp, index) in lineUpsData" :key="index" class="
                                flex flex-col-reverse
                                border border-gray-500
                                rounded-md
                                my-2
                                p-2
                              ">
                  <div v-if="isAvailable" class="flex justify-end px-2">
                    <a href="javascript:;" data-toggle="modal" data-target="#delete-lineup-preview"
                      @click="this.selectedLineUp = index" class="text-theme-6">
                      <Trash2Icon class="w-4 h-4" />
                    </a>
                  </div>
                  <div class="flex px-2 text-lg text-theme-10" v-for="athlete in lineUp.boatSeats" :key="athlete.name">
                    <div>
                      <span :class="{ hidden: athlete.seat == null }">{{ athlete.seat }}:
                      </span>
                      {{ athlete.name }}
                    </div>
                  </div>
                  <div class="px-1 text-lg font-bold text-theme-10">
                    <span class="text-2xl">☸</span> Boat Type:
                    {{ lineUp?.boatType }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- START: DELETE LINEUP -->
      <div id="delete-modal" class="p-5">
        <div class="preview">
          <!-- START: Modal Content -->
          <div id="delete-lineup-preview" class="modal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-body p-0">
                  <div class="p-5 text-center">
                    <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
                    <div class="text-3xl mt-5">Are you sure?</div>
                    <div class="text-gray-600 mt-2">
                      This process cannot be undone.
                    </div>
                  </div>
                  <div class="px-5 pb-8 text-center">
                    <button type="button" data-dismiss="modal" class="
                                btn btn-outline-secondary
                                w-24
                                dark:border-dark-5 dark:text-gray-300
                                mr-1
                              ">
                      Cancel
                    </button>
                    <button @click="deleteLineUp(this.selectedLineUp)" type="button" class="btn btn-danger w-24"
                      data-dismiss="modal">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END: Modal Content -->
        </div>
      </div>
      <!-- END: DELETE LINEUP -->
    </div>
</div>
</template>

<script>
import { computed, onMounted, reactive } from 'vue'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'
import Toastify from 'toastify-js'
import moment from 'moment'

export default {
  name: 'Event Line Up - Event Detail',
  data() {
    return {
      type: 'information',
      filteredAthletes: this.athletes,
      fields: [],
      isLoaded: false
    }
  },
  methods: {
    // GET: selected boat data
    lineUp() {
      this.$store.dispatch('inventories/getBoatsData')
    },
    disable() {
      this.loading.value = true
      document.getElementById('toggle').disabled = true
    },
    // CANCEL: line-up
    cancel() {
      this.lineUpsData.pop()
      this.$store.dispatch('inventories/getBoatDetail', undefined)
    },
    // SET SEATS and FILTER ATHLETES: Inside the line-up creation modal
    addAthlete(index, athlete) {
      var length = this.lineUpsData.length - 1
      var lastSelectedAthlete = {}
      if (athlete == 'none') {
        lastSelectedAthlete = {
          userId: this.lineUpsData[length]?.boatSeats[index]?.userId,
          fullName: this.lineUpsData[length]?.boatSeats[index]?.name
        }
        this.filteredAthletes.push(lastSelectedAthlete)
      } else {
        athlete = JSON.parse(athlete)
        var id = athlete.userId
        var name = athlete.fullName
      }
      var event = this.event
      var x = null
      var option = document.createElement('option')
      if (this.lineUpsData[length]?.boatSeats[index]?.userId == undefined) {
        lastSelectedAthlete = null
        if (index == 'c') {
          x = document.getElementById('cox')
        } else {
          x = document.getElementById(index - 1)
        }
        option.value = JSON.stringify(athlete)
        option.text = athlete.fullName
        option.setAttribute('selected', true)
        x.add(option)
      } else {
        lastSelectedAthlete = {
          userId: this.lineUpsData[length]?.boatSeats[index]?.userId,
          fullName: this.lineUpsData[length]?.boatSeats[index]?.name
        }
        this.filteredAthletes.push(lastSelectedAthlete)
        if (index == 'c') {
          x = document.getElementById('cox')
        } else {
          x = document.getElementById(index - 1)
        }
        var len = x.length - 1
        if (athlete !== 'none') {
          option.value = JSON.stringify(athlete)
          option.text = athlete.fullName
          option.setAttribute('selected', true)
          x.add(option)
        }
        x.remove(len)
      }
      const indexOfObject = this.filteredAthletes.findIndex((el) => {
        return el.userId == id
      })
      this.filteredAthletes.splice(indexOfObject, 1)
      this.$store.dispatch('events/addAthleteToBoat', {
        index,
        id,
        name,
        event
      })
      // VALIDATION: All fields are filled and unique
      const arr = Object.entries(this.lineUpsData[length]?.boatSeats)
      this.fields.push(index)
      const unique = [...new Set(this.fields.map((item) => item))]
      for (var i = 0; i < arr.length; i++) {
        if (
          this.lineUpsData[length]?.boatSeats[unique[i]]?.name == undefined ||
          this.lineUpsData[length]?.boatSeats[unique[i]]?.name == ''
        ) {
          document.getElementById('save').disabled = true
          break
        } else {
          document.getElementById('save').disabled = false
        }
      }
    }
  },
  watch: {
    isSuccess() {
      this.loading.value = false
      document.getElementById('toggle').disabled = false
    },
    type() {
      if (this.type == 'line-up') {
        this.$store.dispatch('inventories/getBoatsData')
        this.$store.state.inventories.boatDetail =
          this.$store.state.inventories?.boats[0]
      }
    },
    // FILTERING ATHLETES: new boat added
    'lineUpData.boat'() {
      if (this.lineUpData?.boat !== 'None') {
        this.$store.dispatch('inventories/getBoatDetail', this.lineUpData.boat)
        this.$store.dispatch('events/getEventLineUps', this.$route.params.id)

        this.filteredAthletes = this.unAllocatedUsers[0].rsvps
      } else {
        this.$store.dispatch('inventories/getBoatDetail', undefined)
      }
    },
    event() {
      if (this.event.eventDate.length > 0) {
        this.isLoaded = true
      }
    }
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const event = computed(() => store.state.events.eventDetail)
    const boats = computed(() => store.state.inventories.boats)
    const boatTypes = computed(() => store.state.inventories?.boatTypes)
    const athletes = computed(() => store.state.events.eventDetail.rsvps)
    const lineUpsData = computed(() => store.state.events.lineUps?.lineups)
    const isAvailable = computed(() => (store.state.profile.user?.userRole?.name == 'Coach' || store.state.profile.user?.userRole?.name == 'Super Admin'))

    const unAllocatedUsers = computed(
      () => store.state.events.lineUps?.unAllocatedUsers
    )
    const role = computed(() => store.state.profile.user?.userRole?.name)
    const selectedBoat = computed(() => store.state.inventories.boatDetail)
    const isSuccess = computed(() => store.state.events.updateRsvp.isSuccess)
    const deleteSuccess = computed(
      () => store.state.events.deleteEvent.isSuccess
    )
    const userId = computed(() => store.state.profile.user?.id)
    const now = computed(() => +moment(moment()).format('X'))
    var loading = reactive({ value: false })
    onMounted(() => {
      store.dispatch('profile/getProfileData')
      store.dispatch('inventories/getBoatsData')
      store.dispatch('inventories/getBoatTypes')
      store.dispatch('events/getEventDetail', route.params.id)
    })
    const lineUpData = reactive({
      boat: '',
      athletes: []
    })
    const deleteLineUp = (lineUpIndex) => {
      var eventId = route.params.id
      store.dispatch('events/deleteLineUp', { lineUpIndex, eventId })
      store.dispatch('inventories/getBoatDetail', undefined)
    }
    const saveEventLineUps = () => {
      var eventId = route.params.id
      store.dispatch('events/submitLineUp', eventId)
      store.dispatch('inventories/getBoatDetail', undefined)
    }
    const lineUpError = () => {
      // VALIDATION: Event is passed
      if (store.state.events.eventDetail.eventDateTime.timestamp < moment(moment()).format('X')) {
        Toastify({
          duration: 2000,
          text: 'This event has passed',
          newWindow: true,
          style: {
            backgroundColor: '#D32929',
            color: 'white',
            padding: '10px'
          },
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        // VALIDATION: Boat field is empty
      } else if (store.state.inventories.boatDetail == undefined) {
        Toastify({
          duration: 2000,
          text: 'Please select a Boat',
          newWindow: true,
          style: {
            backgroundColor: '#D32929',
            color: 'white',
            padding: '10px'
          },
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        // VALIDATION: Not enough athlete
      } else {
        Toastify({
          duration: 2000,
          text: 'Not enough athletes for this boat',
          newWindow: true,
          style: {
            backgroundColor: '#D32929',
            color: 'white',
            padding: '10px'
          },
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
      }
    }
    const submitLineUp = () => {
      store.dispatch('athletes/getAthletesData')
      store.dispatch('events/addBoatToLineUps', selectedBoat.value)
    }
    return {
      lineUpData,
      selectedBoat,
      athletes,
      lineUpsData,
      unAllocatedUsers,
      boatTypes,
      deleteLineUp,
      submitLineUp,
      saveEventLineUps,
      lineUpError,
      boats,
      deleteSuccess,
      route,
      userId,
      isSuccess,
      event,
      loading,
      role,
      now,
      isAvailable
    }
  }
}
</script>
