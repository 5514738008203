<template>
  <div>
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="" class="text-3xl -intro-x flex items-center pt-5">
            🚣
            <span class="text-white text-lg ml-3">
              Rower<span class="font-medium">hub</span>
            </span>
          </a>
          <div class="my-auto">
            <img
              alt="Icewall Tailwind HTML Admin Template"
              class="-intro-x w-1/2 -mt-16"
              :src="require(`@/assets/images/illustration.svg`)"
            />
            <div
              class="
                -intro-x
                text-white
                font-medium
                text-4xl
                leading-tight
                mt-10
              "
            >
              Forgot Password?
            </div>
            <div
              class="
                -intro-x
                mt-5
                text-lg text-white text-opacity-70
                dark:text-gray-500
              "
            >
              Let us know the email address that you use to get password reset
              email.
            </div>
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="
              my-auto
              mx-auto
              xl:ml-20
              bg-white
              dark:bg-dark-1
              xl:bg-transparent
              px-5
              sm:px-8
              py-8
              xl:p-0
              rounded-md
              shadow-md
              xl:shadow-none
              w-full
              sm:w-3/4
              lg:w-2/4
              xl:w-auto
            "
          >
            <h2
              v-show="!showSuccessMessage"
              class="
                intro-x
                font-bold
                text-2xl
                xl:text-3xl
                text-center
                xl:text-left
              "
            >
              Reset Your Password
            </h2>
            <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">
              Let us know the email address that you use to get password reset
              email.
            </div>
            <!-- Login Errors -->
            <!-- Success Message -->
            <div
              v-show="showSuccessMessage"
              class="
                h-screen
                flex-col
                xl:h-auto
                xl:py-0
                xl:my-0
                xl:flex
                text-green
              "
            >
              <CheckCircleIcon class="block mx-auto w-12 h-12 text-theme-9" />
              <h2
                class="
                  -intro-x
                  mt-5
                  text-lg text-gray-800 text-center
                  dark:text-gray-500
                "
              >
                Password reminder email has been sent. Please check your
                inbox <strong>({{ formData.email }})</strong>
              </h2>
            </div>
            <div
              class="alert alert-danger show mb-2 mt-4"
              role="alert"
              id="login-errors"
              v-if="forgotPasswordEmailError && !isSent"
            >
              {{ forgotPasswordEmailError }}
            </div>
            <div class="intro-x mt-8">
              <input
                type="email"
                class="
                  intro-x
                  login__input
                  form-control
                  py-3
                  px-4
                  border-gray-300
                  block
                "
                placeholder="Email"
                v-show="!showSuccessMessage"
                v-model.trim="formValidate.email.$model"
              />
              <template v-if="formValidate.email.$error">
                <div
                  v-for="(error, index) in formValidate.name.$errors"
                  :key="index"
                  class="text-theme-6 mt-2"
                >
                  {{ error.$message }}
                </div>
              </template>
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button
                v-on:click="submit()"
                v-show="!showSuccessMessage"
                class="btn btn-primary py-3 px-4 mt-4 w-full align-top"
              >
                Send Password Reset Email
              </button>
              <router-link
                :to="{ name: 'login' }"
                tag="button"
                class="
                  btn btn-outline-secondary
                  py-3
                  px-4
                  mt-4
                  w-full
                  xl:mr-3
                  align-top
                "
              >
                Sign In
              </router-link>
            </div>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, computed } from 'vue'
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { useStore } from '@/store'

export default {
  setup() {
    const store = useStore()
    const forgotPasswordEmailError = computed(
      () => store.state.auth.forgotPassword.errorMessage
    )
    const showSuccessMessage = computed(
      () => store.state.auth.forgotPassword.isSent
    )

    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    })

    const formData = reactive({
      email: ''
    })

    const rules = {
      email: {
        required
      }
    }

    const formValidate = useVuelidate(rules, toRefs(formData))

    const submit = () => {
      store.dispatch('auth/forgotPassword', formData)
    }

    return {
      formValidate,
      forgotPasswordEmailError,
      showSuccessMessage,
      formData,
      submit
    }
  }
}
</script>
