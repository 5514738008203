<template>
  <div class="px-2 py-2">
    <span class="inline-flex justify-center mx-2">
      <router-link :to="{ path: '/user-groups' }">
        <div class="inline-block gap-1 border border-1 border-black rounded">
          <ArrowLeftIcon />
        </div>
        <span class="inline-block mx-2 align-top mt-1">User Groups</span>
      </router-link>
    </span>
  </div>
  <div v-if="role == 'Athlete'" class="p-10">
    <div class="flex justify-center">
      <AlertCircleIcon class="w-8 h-8 my-4" />
    </div>
    <div class="flex justify-center text-center">
      You do not have permission to view this page
    </div>
  </div>
  <div class="px-4" v-if="isAvailable">
    <div class="text-xl font-bold">Add New User Groups</div>
    <div role="button" class="float-right text-right p-2" @click="disable()">
      <button v-if="isAvailable" @click="addGroup()" type="text" id="submit" class="btn float-right btn-primary w-24">
        Save
        <LoadingIcon v-if="loading.value" icon="oval" class="w-4 h-4 ml-4 text-white" />
      </button>
    </div>
    <div class="flex p-2">
      <input v-model="validate.name.$model" id="crud-form-1" type="text" class="form-control flex"
        :class="{ 'border-theme-6': validate.name.$error }" placeholder="group name" required />
    </div>
    <!-- BEGIN: Failed Notification Content -->
    <div id="failed-notification-content" class="toastify-content hidden flex">
      <XCircleIcon class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div class="font-medium">Please fill requirements</div>
        <div class="text-gray-600 mt-1">Please check the form.</div>
      </div>
    </div>
    <!-- END: Failed Notification Content -->
  </div>
</template>

<script>
import { onMounted, computed, reactive, toRefs } from 'vue'
import { useStore } from '@/store'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import Toastify from 'toastify-js'

export default {
  name: 'Add Group',
  methods: {
    disable() {
      if (this.formData.name !== '') {
        this.loading.value = true
        document.getElementById('submit').disabled = true
      }
    }
  },
  watch: {
    isSuccess() {
      if (this.isSuccess) {
        this.loading.value = true
        this.$router.back()
      } else {
        this.loading.value = false
        document.getElementById('submit').disabled = false
      }
    }
  },
  setup() {
    const store = useStore()
    var loading = reactive({ value: false })
    const role = computed(() => store.state.profile.user?.userRole?.name)
    const isSuccess = computed(() => store.state.groups.addGroup.isSuccess)
    const isAvailable = computed(() => (store.state.profile.user?.userRole?.name == 'Coach' || store.state.profile.user?.userRole?.name == 'Super Admin'))

    onMounted(() => {
      store.dispatch('profile/getProfileData')
    })
    const formData = reactive({
      name: ''
    })
    const rules = {
      name: {
        required
      }
    }
    const validate = useVuelidate(rules, toRefs(formData))
    const addGroup = () => {
      validate.value.$touch()
      if (validate.value.$invalid) {
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
      } else {
        store.dispatch('groups/addGroup', { name: formData.name })
      }
    }
    return {
      formData,
      validate,
      isSuccess,
      loading,
      addGroup,
      role,
      isAvailable
    }
  }
}
</script>
