<template>
  <div id="app">
    <section>
      <div class="container mx-auto px-2 py-4">
        <div class="flex flex-wrap">
          <div class="w-full">
            <div class="pt-4 bg-white rounded">
              <div class="px-6 border-b border-blue-50">
                <div v-if="isAvailable" class="float-right px-1 py-4">
                  <router-link
                    :to="{ path: '/invite-athlete' }"
                    class="
                      btn
                      float-right
                      border border-black
                      font-bold
                      px-2
                      py-1
                      text-sm
                      outline-none
                      rounded
                      focus:outline-none
                      ease-linear
                      transition-all
                      duration-150
                    "
                  >
                    <div class="flex gap-1"><PlusIcon />Invite Athlete</div>
                  </router-link>
                </div>
                <div class="flex flex-wrap items-center py-2">
                  <div>
                    <h3 class="text-xl font-bold px-1 py-2">Athletes</h3>
                  </div>
                </div>
              </div>
              <!-- START: EMPTY LIST -->
              <div v-if="isLoaded && this.athletes.length < 1">
                    <div class="w-full p-4">
                      <div class="flex justify-center mt-6">
                        <UserXIcon class="w-12 h-12" />
                      </div>
                      <div class="flex justify-center text-lg p-4">
                        Any athletes to list
                      </div>
                    </div>
                  </div>
                  <!-- END: EMPTY LIST -->
              <div class="hidden lg:block">
                <table class="w-full">
                  <thead class="bg-gray-50" v-if="isLoaded && this.athletes.length > 0">
                    <tr class="border-b text-sm border-blue-50">
                      <th class="p-2 text-theme-1">Name</th>
                      <th class="p-2 text-theme-1">Status</th>
                      <th class="p-2 text-theme-1">User Groups</th>
                      <th class="p-2 text-theme-1" v-if="isAvailable">
                        User Roles
                      </th>
                    </tr>
                  </thead>
                  <!-- START: loading animation for L view  -->
                  <tbody v-if="!isLoaded">
                    <tr
                      v-for="el in 8"
                      :key="el.id"
                      class="w-full p-2 mx-4 my-6 border-b"
                    >
                      <td
                        class="
                          w-1/7
                          text-center
                          border-b
                          lg:table-cell
                          static
                          m-4
                          my-2
                          px-2
                        "
                      >
                        <p class="px-2 mx-4 w-full h-6 blob bg-theme-1"></p>
                      </td>
                      <td
                        class="
                          w-1/7
                          text-center
                          border-b
                          lg:table-cell
                          static
                          m-4
                          my-2
                          px-2
                        "
                      >
                        <p class="px-2 mx-4 w-full h-6 blob bg-theme-8"></p>
                      </td>
                      <td
                        class="
                          w-1/7
                          text-center
                          border-b
                          lg:table-cell
                          static
                          m-4
                          my-2
                          px-2
                        "
                      >
                        <p class="px-2 mx-4 w-full h-6 blob bg-theme-8"></p>
                      </td>
                      <td
                        v-if="isAvailable"
                        class="
                          w-1/7
                          text-center
                          border-b
                          lg:table-cell
                          static
                          m-4
                          my-2
                          px-2
                        "
                      >
                        <p class="px-2 mx-4 w-full h-6 blob bg-theme-8"></p>
                      </td>
                    </tr>
                  </tbody>
                  <!-- END: loading animation for L view  -->
                  <tbody v-if="isLoaded">
                    <tr
                      v-for="element in athletes"
                      :key="element.id"
                      class="w-full p-2 mx-4 my-6 border-b hover:bg-theme-14"
                    >
                      <td
                        v-if="isAvailable"
                        class="
                          w-1/7
                          text-theme-1 text-center
                          border-b
                          lg:table-cell
                          static
                        "
                      >
                        <router-link
                          :to="{ path: '/athlete-detail/' + element.id }"
                        >
                          <p class="py-1 px-2 text-sm mx-4 pt-2">
                            {{ element.firstName + ' ' + element.lastName }}
                          </p>
                        </router-link>
                      </td>
                      <td
                        v-if="role == 'Athlete'"
                        class="
                          w-1/7
                          text-theme-1 text-center
                          border-b
                          lg:table-cell
                          static
                        "
                      >
                        <p class="py-1 px-2 text-sm mx-4 pt-2">
                          {{ element.firstName + ' ' + element.lastName }}
                        </p>
                      </td>
                      <td
                        v-if="element.active"
                        class="
                          w-1/7
                          text-theme-9 text-center
                          border-b
                          lg:table-cell
                          static
                        "
                      >
                        <p class="py-1 px-2 text-sm mx-4 pt-2">active</p>
                      </td>
                      <td
                        v-else
                        class="
                          w-1/7
                          text-theme-6 text-center
                          border-b
                          lg:table-cell
                          static
                        "
                      >
                        <p class="py-1 px-2 text-sm mx-4 pt-2">passive</p>
                      </td>
                      <td
                        v-if="isAvailable"
                        class="
                          w-1/7
                          text-theme-1 text-center
                          border-b
                          lg:table-cell
                          static
                        "
                      >
                        <span class="py-1 px-2 text-sm mx-4 pt-2">
                          <div class="flex flex-wrap justify-center">
                            <span
                              v-for="group in element.userGroups"
                              :key="group.id"
                              class="
                                px-2
                                py-1
                                rounded-lg
                                bg-theme-8
                                text-theme-1
                                mr-1
                                m-2
                              "
                            >
                              <router-link
                                :to="{ path: '/group-detail/' + group.id }"
                              >
                                <p>
                                  {{ group.groupName }}
                                </p>
                              </router-link>
                            </span>
                          </div>
                        </span>
                      </td>
                      <td
                        v-if="role == 'Athlete'"
                        class="
                          w-1/7
                          text-theme-1 text-center
                          border-b
                          lg:table-cell
                          static
                        "
                      >
                        <span class="py-1 px-2 text-sm mx-4 pt-2">
                          <div class="flex flex-wrap justify-center">
                            <span
                              v-for="group in element.userGroups"
                              :key="group.id"
                              class="
                                px-2
                                py-1
                                rounded-lg
                                bg-theme-8
                                text-theme-1
                                mr-1
                                m-2
                              "
                            >
                              <p>
                                {{ group.groupName }}
                              </p>
                            </span>
                          </div>
                        </span>
                      </td>
                      <td
                        v-if="isAvailable"
                        class="
                          w-1/7
                          text-theme-1 text-center
                          border-b
                          lg:table-cell
                          static
                        "
                      >
                        <span class="py-1 px-2 text-sm mx-4 pt-2">
                          <div class="flex flex-wrap justify-center">
                            <span
                              class="
                                px-2
                                py-1
                                border
                                rounded-lg
                                bg-white
                                text-theme-1
                                mr-1
                                m-2
                                inline-block
                              "
                            >
                              <router-link
                                :to="{ path: '/athlete-detail/' + element.id }"
                              >
                                <span class="flex p-2">
                                  {{ element.userRole.name }}
                                  <sup
                                    >&nbsp;<EditIcon class="flex w-4 h-4"
                                  /></sup>
                                </span>
                              </router-link>
                            </span>
                          </div>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="lg:hidden xl:hidden p-2">
                <!-- START: loading animation for S-M views  -->
                <div v-if="!isLoaded" disabled>
                  <ul v-for="el in 8" :key="el.id" class="w-full py-2 mx-4">
                    <li>
                      <div class="flex-row">
                        <li
                          class="
                            py-1
                            text-lg
                            bg-theme-1
                            my-2
                            px-2
                            w-64
                            h-6
                            blob
                          "
                        ></li>
                      </div>
                    </li>
                    <li>
                      <span class="inline-block">
                        <li
                          class="
                            py-1
                            text-sm
                            bg-theme-8
                            mr-2
                            px-2
                            w-32
                            h-6
                            blob
                          "
                        ></li>
                      </span>
                    </li>
                    <li>
                      <span v-for="el in 2" :key="el.id" class="inline-block">
                        <p class="py-1 text-sm bg-theme-8 mr-2 px-2 blob"></p>
                      </span>
                    </li>
                    <li>
                      <span v-for="el in 3" :key="el.id" class="inline-block">
                        <p
                          class="
                            py-1
                            text-sm
                            bg-theme-8
                            mr-2
                            px-2
                            w-48
                            h-6
                            blob
                          "
                        ></p>
                      </span>
                    </li>
                  </ul>
                </div>
                <!-- END: loading animation for S-M views  -->
                <div v-if="isLoaded" class="p-2">
                  <ul
                    v-for="element in athletes"
                    :key="element.id"
                    class="border border-1 border-theme-14 rounded-md my-2"
                  >
                    <div>
                      <li class="bg-theme-14 p-2">
                        <div>
                          <span class="text-md font-semibold text-theme-19">{{
                            element.firstName + ' ' + element.lastName
                          }}</span>
                        </div>
                      </li>
                      <li v-if="element.active" class="px-2 py-1">
                        <div>
                          <p class="text-md text-theme-9">
                            <span class="text-md font-semibold text-theme-19"
                              >Status: </span
                            >Active
                          </p>
                        </div>
                      </li>
                      <li v-else class="px-2 py-1">
                        <div>
                          <p class="text-md text-theme-6">
                            <span class="text-md font-semibold text-theme-19"
                              >Status: </span
                            >Passive
                          </p>
                        </div>
                      </li>
                      <li class="px-2 py-1">
                        <div>
                          <div class="flex flex-col">
                            <div
                              v-for="group in element.userGroups"
                              :key="group.id"
                              class="
                                flex-row
                                px-2
                                py-1
                                rounded-lg
                                bg-theme-8
                                text-theme-1
                                mr-1
                                m-2
                              "
                            >
                              {{ group.groupName }}
                            </div>
                          </div>
                        </div>
                      </li>
                      <li v-if="isAvailable" class="px-2 py-1">
                        <div>
                          <p class="flex text-md">
                            <span
                              class="
                                flex
                                text-md
                                align-middle
                                font-semibold
                                text-theme-19
                                pt-2
                                mx-2
                              "
                              >User Role:
                            </span>
                            <router-link
                              :to="{ path: '/athlete-detail/' + element.id }"
                              class="flex border border-theme-1 rounded-md"
                            >
                              <span class="flex p-2">
                                {{ element.userRole.name }}
                                <sup
                                  >&nbsp;<EditIcon class="flex w-4 h-4"
                                /></sup>
                              </span>
                            </router-link>
                          </p>
                        </div>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.blob {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
</style>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from '@/store'

export default {
  name: 'athletes',
  data() {
    return {
      isLoaded: false
    }
  },
  watch: {
    athletes() {
      if (this.$store.state.athletes.athletesLoaded.message) {
        this.isLoaded = true
      }
    }
  },
  setup() {
    const store = useStore()
    const athletes = computed(() => store.state.athletes.athletes)
    const role = computed(() => store.state.profile.user?.userRole?.name)
    const isAvailable = computed(() => (store.state.profile.user?.userRole?.name == 'Coach' || store.state.profile.user?.userRole?.name == 'Super Admin'))

    onMounted(() => {
      store.dispatch('athletes/getAthletesData')
      store.dispatch('sideMenu/getProfileData')
    })
    const clubName = computed(() => store.state.profile.user?.club.name)
    return {
      athletes,
      clubName,
      role,
      isAvailable
    }
  }
}
</script>
