<template>
  <h2 class="text-xl font-bold m-2 p-2">Calendar</h2>
  <FullCalendar :options="calendarOptions" />
</template>

<script>
import '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import FullCalendar from '@fullcalendar/vue3'
import moment from 'moment'
import { computed, defineComponent, onMounted } from 'vue'
import { useStore } from '@/store'

export default defineComponent({
  name: 'Home',
  components: {
    FullCalendar
  },
  data() {
    return {
      calendarEvents: [],
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        initialView: 'dayGridMonth',
        initialDate: moment().format('YYYY-MM-DD'),
        navLinks: true,
        editable: true,
        dayMaxEvents: true,
        slotMinTime: '00:00:00',
        slotMaxTime: '00:00:00',
        eventContent: function (arg) {
          const title = document.createElement('a')
          const type = document.createElement('i')
          title.innerHTML = arg.event.title
          type.innerHTML = arg.event.extendedProps.type
          title.classList.add(
            'font-bold',
            'uppercase',
            'flex',
            'justify-center'
          )
        },
        eventClick: this.goToEvent,
        events: []
      },
      data: []
    }
  },
  watch: {
    events() {
      for (var i = 0; i < this.events.length; i++) {
        this.calendarEvents.push({
          start: this.events[i].eventDate,
          end: this.events[i].eventDate,
          id: this.events[i].id,
          title: this.events[i].name,
          type: this.events[i].type
        })
      }
      this.calendarOptions.events = this.calendarEvents
    }
  },
  setup() {
    const store = useStore()
    const events = computed(() => store.state.calendar.events)
    onMounted(() => {
      store.dispatch('calendar/getEventsData')
    })
    return {
      events
    }
  },
  mounted() {
    this.data = this.events
  },
  methods: {
    goToEvent(args) {
      this.$router.push({ path: '/event-detail/' + args.event.id })
    }
  }
})
</script>
