<template>
  <div id="app">
    <label for="update-profile-form-2" class="form-label font-bold"
      >Participants</label
    >
    <!-- START: loading animation -->
    <div v-if="!isLoaded" disabled>
      <div class="flex-row" v-for="el in 4" :key="el.id">
        <div class="py-1 text-lg bg-theme-8 px-2 w-64 my-2 h-6 blob"></div>
      </div>
    </div>
    <!-- END: loading animation -->
    <ul v-for="rsvp in event.rsvps" :key="rsvp.id">
      <li
        v-if="isLoaded"
        class="my-2 form-control rsvp"
        :class="{
          //loading state
          hidden:
            rsvp.fullName == '' ||
            rsvp.fullName == null ||
            rsvp.fullName == undefined
        }"
      >
        <span class="px-2 mr-1">
          <span class="float-right">
            <button class="text-xs text-center text-theme-9 mx-2 px-2">
              <div class="dropdown inline-block" data-placement="bottom">
                <button
                  v-if="rsvp.rsvp == 'Y'"
                  id="toggle"
                  class="btn btn-success text-white dropdown-toggle w-28"
                >
                  <a class="flex"> going </a>
                </button>
                <button
                  v-if="rsvp.rsvp == 'N'"
                  id="toggle"
                  class="btn btn-danger text-white dropdown-toggle w-28"
                >
                  <a class="flex"> not going </a>
                </button>
                <button
                  v-if="rsvp.rsvp == 'NR'"
                  id="toggle"
                  class="btn btn-primary text-white dropdown-toggle w-28"
                >
                  <a class="flex"> no response </a>
                </button>
                <button
                  v-if="rsvp.rsvp == 'T'"
                  id="toggle"
                  class="btn btn-warning text-white dropdown-toggle w-28"
                >
                  <a class="flex"> tentative </a>
                </button>
                <div class="dropdown-menu w-32">
                  <div class="dropdown-menu__content box dark:bg-dark-1">
                    <button
                      @click="
                        updateRsvpForUser(
                          ((formData.id = rsvp.userId), (formData.rsvp = 'Y'))
                        )
                      "
                      class="block p-2 w-full transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      data-dismiss="dropdown"
                    >
                      going
                    </button>
                    <button
                      @click="
                        updateRsvpForUser(
                          ((formData.id = rsvp.userId), (formData.rsvp = 'N'))
                        )
                      "
                      class="block p-2 w-full transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      data-dismiss="dropdown"
                    >
                      not going
                    </button>
                    <button
                      @click="
                        updateRsvpForUser(
                          ((formData.id = rsvp.userId), (formData.rsvp = 'NR'))
                        )
                      "
                      class="block p-2 w-full transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      data-dismiss="dropdown"
                    >
                      no response
                    </button>
                    <button
                      @click="
                        updateRsvpForUser(
                          ((formData.id = rsvp.userId), (formData.rsvp = 'T'))
                        )
                      "
                      class="block p-2 w-full transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      data-dismiss="dropdown"
                    >
                      tentative
                    </button>
                  </div>
                </div>
              </div>
            </button>
          </span>
          <p class="-mt-4">
            {{ rsvp.fullName }} <br />
            <span>&nbsp;</span>
          </p>
        </span>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.blob {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.rsvp {
  height: 64px;
}
</style>

<script>
import { onMounted, reactive, watch } from 'vue'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'

export default {
  name: 'Update Rsvp for User - Event information - Event Detail',
  data() {
    return {
      type: 'information',
      isLoaded: false
    }
  },
  props: {
    event: Object
  },
  watch: {
    event() {
      if (this.event.eventDate.length > 0) {
        this.isLoaded = true
      }
    }
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    let myEvent = reactive({
      detail: event
    })
    var loading = reactive({ value: false })
    onMounted(() => {
    })
    const formData = reactive({
      eventId: route.params.id,
      rsvp: '',
      id: ''
    })
    const updateRsvpForUser = () => {
      store.dispatch('events/updateRsvpForAthlete', formData)
    }
    watch(
      () => formData.rsvp,
      (rsvp, previous) => {
        myEvent = store.state.events.eventDetail
        const index = myEvent.rsvps.findIndex((x) => x.userId == formData.id)
        myEvent.rsvps[index].rsvp = formData.rsvp
      }
    )
    return {
      route,
      loading,
      formData,
      updateRsvpForUser,
      myEvent
    }
  }
}
</script>
