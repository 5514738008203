<template>
  <CreateAnnouncement :is-available="isAvailable" />
  <LoadingAnimation :is-loaded="isLoaded" />
  <div class="px-2">
    <!-- START: EMPTY LIST -->
    <div v-if="isLoaded && announcements.length < 1">
      <div class="w-full p-4">
        <div class="flex justify-center mt-6">
          <MailIcon class="w-12 h-12" />
        </div>
        <div class="flex justify-center text-lg p-4">
          You have no announcements.
        </div>
      </div>
    </div>
    <!-- END: EMPTY LIST -->
    <ul v-if="isLoaded">
      <li v-for="element in announcements" :key="element.id" class="block w-full p-2">
        <div class="w-full border-2 bg-white rounded-md p-4">
          <div class="border-b pb-4 flex flex-row basis-1/4">
            <div class="announcement-details flex-1">
              <div class="text-right text-xs text-theme-16 float-right w-16">
              {{ element.createdAt }}
            </div>
              <div class="announcement-sender flex flex-row items-center my-2 mx-2">
                <div class="w-12 h-12 rounded-full bg-theme-1 text-white text-2xl text-center items-center image-fit">
                  <p class="mt-2">
                    {{ element.user.firstName.slice(0, 1)
                    }}{{ element.user.lastName.slice(0, 1) }}
                  </p>
                </div>
                <div class="mx-2 font-bold">{{ element.user.fullName }}</div>
              </div>
              <div class="flex flex-row">
                <strong>To:</strong>
                <div v-for="userGroup in element.userGroups" :key="userGroup.id"
                  class="ml-2 px-2 py-0.5 bg-gray-200 text-theme-1 text-xs rounded-md">
                  {{ userGroup.groupName }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="flex py-2">
              {{ element.content }}
            </div>
          </div>
        </div>
      </li>
    </ul>
    <button v-if="isLoadMore" @click="loadMoreEvents()" class="btn btn-outline-secondary py-4 w-full">
      Show More
      <LoadingIcon icon="oval" class="w-4 h-4 ml-4 text-gray-600" v-if="isLoadMoreActive"/>
    </button>
  </div>
</template>

<script>
import { onMounted, computed, ref } from 'vue'
import { useStore } from '@/store'
import CreateAnnouncement from './CreateAnnouncement.vue'
import LoadingAnimation from './LoadingAnimation.vue'

export default {
  name: 'Announcements',
  data() {
    return {
      profile: ''
    }
  },
  watch: {
    fullName() {
      this.profile =
        this.$store.state.profile.user?.firstName.slice(0, 1) +
        this.$store.state.profile.user?.lastName.slice(0, 1)
    },
    sendingOptions() {
      this.sendingOptions.unshift({ groupName: 'Send All' })
    }
  },
  setup() {
    const offset = ref(0)
    const store = useStore()
    const role = computed(() => store.state.profile.user?.userRole?.name)
    const isLoaded = computed(() => store.state.announcements.announcementsLoaded)
    const isLoadMoreActive = computed(() => store.state.announcements.isLoadMoreActive)
    const isLoadMore = computed(() => store.state.announcements.isLoadMore)
    const isAvailable = computed(() => (store.state.profile.user?.userRole?.name == 'Coach' || store.state.profile.user?.userRole?.name == 'Super Admin'))
    const announcements = computed(() => store.state.announcements.announcements)
    const fullName = computed(() => store.state.profile.user?.firstName +
      ' ' +
      store.state.profile.user?.lastName)
    const clubName = computed(() => store.state.profile.user?.club.name)
    onMounted(() => {
      store.dispatch('profile/getProfileData')
      store.dispatch('announcements/getAnnouncements', { offset: this?.offset ?? 0, func: 'mounted' })
    })

    const loadMoreEvents = () => {
      offset.value += 10
      store.dispatch('announcements/getAnnouncements', { offset: offset.value, func: 'loadMore' })
    }

    return {
      announcements,
      clubName,
      fullName,
      role,
      isAvailable,
      loadMoreEvents,
      isLoaded,
      offset,
      isLoadMoreActive,
      isLoadMore
    }
  },
  components: { CreateAnnouncement, LoadingAnimation }
}
</script>
