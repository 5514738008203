<template>
  <div id="app">
    <div class="w-full px-2 py-4">
      <section>
        <div class="container mx-auto">
          <div class="flex flex-wrap">
            <div class="w-full">
              <div class="pt-4 bg-white rounded">
                <div class="px-6 border-b border-blue-50">
                  <!-- router -->
                  <div v-if="isAvailable" class="float-right px-1 py-2">
                    <router-link
                      :to="{ path: '/add-inventory' }"
                      class="btn float-right border border-black font-bold px-2 py-1 text-sm outline-none rounded focus:outline-none ease-linear transition-all duration-150"
                      ><PlusIcon class="mx-1 w-4 h-4" />Add Inventory
                    </router-link>
                    <button
                      @click="exportBoats()"
                      class="btn float-right border border-black font-bold px-2 py-1 text-sm outline-none rounded focus:outline-none ease-linear transition-all duration-150 mx-2"
                    >
                      <ShareIcon class="mx-1 w-4 h-4" />Export Boats
                    </button>
                  </div>
                  <div class="flex flex-wrap items-center mb-4">
                    <div>
                      <h3 class="text-xl font-bold px-1 py-2">Inventories</h3>
                    </div>
                  </div>
                  <div>
                    <span
                      v-on:click="this.type = 'boats'"
                      :class="
                        type === 'boats'
                          ? 'text-theme-1 border-b-2 border-theme-1'
                          : 'text-theme-16 border-b-2 border-transparent hover:border-theme-3 hover:text-theme-3 cursor-pointer'
                      "
                      class="inline-block px-4 pb-2 text-sm font-medium"
                      >Boats</span
                    >
                    <span
                      v-on:click="this.type = 'ergos'"
                      :class="
                        type === 'ergos'
                          ? 'text-theme-1 border-b-2 border-theme-1'
                          : 'text-theme-16 border-b-2 border-transparent hover:border-theme-3 hover:text-theme-3 cursor-pointer'
                      "
                      class="inline-block px-4 pb-2 text-sm font-medium"
                      >Ergos</span
                    >
                    <span
                      v-on:click="this.type = 'others'"
                      :class="
                        type === 'others'
                          ? 'text-theme-1 border-b-2 border-theme-1'
                          : 'text-theme-16 border-b-2 border-transparent hover:border-theme-3 hover:text-theme-3 cursor-pointer'
                      "
                      class="inline-block px-4 pb-2 text-sm font-medium"
                      >Others</span
                    >
                  </div>
                </div>
                <div v-if="type == 'boats'">
                  <div v-if="isLoaded && boats.length < 1">
                    <div class="w-full p-4">
                      <div class="flex justify-center mt-6">
                        <LifeBuoyIcon class="w-12 h-12" />
                      </div>
                      <div class="flex justify-center text-lg p-4">
                        No boat found for this team
                      </div>
                    </div>
                  </div>
                </div>
                <div class="hidden lg:block">
                  <table v-if="type == 'boats'" class="w-full">
                    <thead
                      class="bg-gray-50"
                      v-if="isLoaded && boats.length > 0"
                    >
                      <tr class="border-b text-sm border-blue-50">
                        <th class="p-2 text-theme-1">Boat Name</th>
                        <th class="p-2 text-theme-1">Boat Code</th>
                        <th class="p-2 text-theme-1">Status</th>
                        <th class="p-2 text-theme-1">Crew Weight</th>
                        <th class="p-2 text-theme-1">Make</th>
                        <th class="p-2 text-theme-1">Owner</th>
                        <th class="p-2 text-theme-1" v-if="isAvailable">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <!-- START: loading animation for L view  -->
                    <tbody v-if="!isLoaded">
                      <tr
                        v-for="el in 8"
                        :key="el.id"
                        class="w-full p-2 mx-4 my-6 border-b"
                      >
                        <td
                          class="w-1/7 text-center border-b lg:table-cell static m-4 my-2 px-2"
                        >
                          <p class="px-2 mx-4 w-full h-6 blob bg-theme-1"></p>
                        </td>
                        <td
                          class="w-1/7 text-center border-b lg:table-cell static m-4 my-2 px-2"
                        >
                          <p class="px-2 mx-4 w-full h-6 blob bg-theme-8"></p>
                        </td>
                        <td
                          class="w-1/7 text-center border-b lg:table-cell static m-4 my-2 px-2"
                        >
                          <p class="px-2 mx-4 w-full h-6 blob bg-theme-8"></p>
                        </td>
                        <td
                          class="w-1/7 text-center border-b lg:table-cell static m-4 my-2 px-2"
                        >
                          <p class="px-2 mx-4 w-full h-6 blob bg-theme-8"></p>
                        </td>
                        <td
                          class="w-1/7 text-center border-b lg:table-cell static m-4 my-2 px-2"
                        >
                          <p class="px-2 mx-4 w-full h-6 blob bg-theme-8"></p>
                        </td>
                        <td
                          class="w-1/7 text-center border-b lg:table-cell static m-4 my-2 px-2"
                        >
                          <p class="px-2 mx-4 w-full h-6 blob bg-theme-8"></p>
                        </td>
                        <td
                          class="w-1/7 text-center border-b lg:table-cell static m-4 my-2 px-6"
                        >
                          <p class="px-2 mx-4 w-full h-6 blob bg-theme-8"></p>
                        </td>
                      </tr>
                    </tbody>
                    <!-- END: loading animation for L view  -->
                    <tbody v-if="isLoaded">
                      <tr
                        v-for="(boat, index) in boats"
                        :key="index"
                        class="w-full p-2 mx-4 my-6 border-b hover:bg-theme-14"
                      >
                        <td
                          class="w-1/7 text-theme-1 text-center border-b lg:table-cell static"
                        >
                          <router-link
                            :to="{ path: '/boat-detail/' + boat.id }"
                          >
                            <p class="py-1 px-2 text-sm">
                              {{ boat.name }}
                              <span
                                v-for="(type, index) in boat.boatType"
                                :key="index"
                              >
                                <span
                                  :class="{
                                    hidden:
                                      index == 'cox' ||
                                      index == 'sweep' ||
                                      index == 'crewSize'
                                  }"
                                >
                                  ({{ type }})
                                </span>
                              </span>
                            </p>
                          </router-link>
                        </td>
                        <td
                          class="w-1/7 text-theme-1 text-center border-b lg:table-cell static"
                        >
                          <p class="py-1 px-2 text-sm mx-4 pt-2">
                            {{ boat.boatCode }}
                          </p>
                        </td>
                        <td
                          class="w-1/7 text-theme-1 text-center border-b lg:table-cell static"
                        >
                          <p
                            v-if="boat.status == 'in use'"
                            class="py-1 px-2 text-sm text-theme-9 rounded-full"
                          >
                            {{ boat.status }}
                          </p>
                          <p
                            v-else
                            class="py-1 px-2 text-sm text-theme-6 bg-gray-50 rounded-full"
                          >
                            {{ boat.status }}
                          </p>
                        </td>
                        <td
                          class="w-1/7 text-theme-1 text-center border-b lg:table-cell static"
                        >
                          <p class="py-1 px-2 text-blue-400 text-sm">
                            {{ boat.crewWeight }}
                          </p>
                        </td>
                        <td
                          class="w-1/7 text-theme-1 text-center border-b lg:table-cell static"
                        >
                          <p class="py-1 px-2 text-blue-400 text-sm">
                            {{ boat.make }}
                          </p>
                        </td>
                        <td
                          class="w-1/7 text-theme-1 text-center border-b lg:table-cell static"
                        >
                          <p class="py-1 px-2 text-theme-12 text-sm">
                            {{ boat.owner }}
                          </p>
                        </td>
                        <td
                          v-if="isAvailable"
                          class="w-1/7 text-theme-1 text-center border-b lg:table-cell static"
                        >
                          <a
                            class="flex justify-center py-1 px-2 text-blue-400 text-sm"
                            href="javascript:;"
                            data-toggle="modal"
                            data-target="#delete-modal-preview"
                            @click="this.selectedBoat = boat.id"
                          >
                            <Trash2Icon class="text-theme-6 p-1" />
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-if="type == 'boats'" class="lg:hidden xl:hidden p-2">
                  <!-- START: loading animation for S-M views  -->
                  <div v-if="!isLoaded" disabled>
                    <ul
                      v-for="el in 8"
                      :key="el.id"
                      class="w-full border-b py-2 mx-4"
                    >
                      <li>
                        <div class="flex-row">
                          <li
                            class="py-1 text-lg bg-theme-1 my-2 px-2 w-64 h-6 blob"
                          ></li>
                        </div>
                      </li>
                      <li>
                        <span class="inline-block">
                          <li
                            class="py-1 text-sm bg-theme-8 mr-2 px-2 w-32 h-6 blob"
                          ></li>
                        </span>
                      </li>
                      <li>
                        <span class="inline-block">
                          <p class="py-1 text-sm bg-theme-8 mr-2 px-2 blob"></p>
                        </span>
                      </li>
                      <li>
                        <span class="inline-block">
                          <p
                            class="py-1 text-sm bg-theme-8 mr-2 px-2 w-48 h-6 blob"
                          ></p>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <!-- END: loading animation for S-M views  -->
                  <div class="p-2" v-if="isLoaded">
                    <ul
                      v-for="boat in boats"
                      :key="boat.id"
                      class="border border-1 border-theme-14 rounded-md my-2"
                    >
                      <div>
                        <li>
                          <div class="flex justify-end"></div>
                        </li>
                        <li class="bg-theme-14 p-2">
                          <div v-if="isAvailable" class="float-right">
                            <a
                              href="javascript:;"
                              data-toggle="modal"
                              data-target="#delete-modal-preview"
                              @click="this.selectedBoat = boat.id"
                            >
                              <Trash2Icon class="text-theme-6 p-1" />
                            </a>
                          </div>
                          <div>
                            <router-link
                              :to="{ path: '/boat-detail/' + boat.id }"
                            >
                              <span
                                class="text-md font-semibold text-theme-19"
                                >{{ boat.name }}</span
                              >
                              <span
                                v-if="boat.boatCode !== ''"
                                class="text-theme-9 px-1"
                                >({{ boat.boatCode }})</span
                              >
                            </router-link>
                          </div>
                        </li>
                        <li class="px-2 py-1">
                          <div>
                            <p class="text-md">
                              <span class="text-md font-semibold text-theme-19"
                                >Owner: </span
                              >{{ boat.owner }}
                            </p>
                          </div>
                        </li>
                        <li class="px-2 py-1">
                          <div>
                            <p class="text-md">
                              <span class="text-md font-semibold text-theme-19"
                                >Type:
                              </span>
                              <span
                                v-for="(type, index) in boat.boatType"
                                :key="index"
                              >
                                <span
                                  :class="{
                                    hidden:
                                      index == 'cox' ||
                                      index == 'sweep' ||
                                      index == 'crewSize'
                                  }"
                                >
                                  ({{ type }})
                                </span>
                              </span>
                            </p>
                          </div>
                        </li>
                        <li class="px-2 py-1">
                          <div class="flex">
                            <span class="text-md font-semibold text-theme-19"
                              >Status:
                            </span>
                            <p
                              v-if="boat.status == 'in use'"
                              class="text-sm text-theme-9"
                            >
                              {{ boat.status }}
                            </p>
                            <p v-else class="text-sm text-theme-6">
                              {{ boat.status }}
                            </p>
                          </div>
                        </li>
                        <li class="bg-theme-14 px-2 py-1">
                          <div class="flex">
                            <p class="text-md text-theme-1">
                              <span v-if="boat.make !== ''"
                                ><p
                                  class="inline-block text-md font-semibold text-theme-19"
                                >
                                  Make:
                                </p>
                                {{ boat.make }}</span
                              >
                              <span v-if="boat.crewWeight !== ''">
                                ({{ boat.crewWeight }})</span
                              >
                            </p>
                          </div>
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
                <div v-if="type == 'ergos'">
                  <!-- START: EMPTY LIST -->
                  <div v-if="ergosLoaded && ergos.length < 1">
                    <div class="w-full p-4">
                      <div class="flex justify-center mt-6">
                        <LifeBuoyIcon class="w-12 h-12" />
                      </div>
                      <div class="flex justify-center text-lg p-4">
                        No Ergo boats found for this team
                      </div>
                    </div>
                  </div>
                  <!-- END: EMPTY LIST -->
                  <div class="hidden lg:block">
                    <table v-if="type == 'ergos'" class="w-full">
                      <thead
                        class="bg-gray-50"
                        v-if="ergosLoaded && ergos.length > 0"
                      >
                        <tr class="border-b text-sm border-blue-50">
                          <th class="p-2 text-theme-1">Name</th>
                          <th class="p-2 text-theme-1">Code</th>
                          <th class="p-2 text-theme-1">Status</th>
                          <th class="p-2 text-theme-1">Club</th>
                          <th class="p-2 text-theme-1">Note</th>
                          <th class="p-2 text-theme-1" v-if="isAvailable">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <!-- START: loading animation for L view  -->
                      <tbody v-if="!ergosLoaded">
                        <tr
                          v-for="el in 8"
                          :key="el.id"
                          class="w-full p-2 mx-4 my-6 border-b"
                        >
                          <td
                            class="w-1/6 text-center border-b lg:table-cell static m-4 my-2 px-2"
                          >
                            <p class="px-2 mx-4 w-full h-6 blob bg-theme-1"></p>
                          </td>
                          <td
                            class="w-1/6 text-center border-b lg:table-cell static m-4 my-2 px-2"
                          >
                            <p class="px-2 mx-4 w-full h-6 blob bg-theme-8"></p>
                          </td>
                          <td
                            class="w-1/6 text-center border-b lg:table-cell static m-4 my-2 px-2"
                          >
                            <p class="px-2 mx-4 w-full h-6 blob bg-theme-9"></p>
                          </td>
                          <td
                            class="w-1/6 text-center border-b lg:table-cell static m-4 my-2 px-2"
                          >
                            <p class="px-2 mx-4 w-full h-6 blob bg-theme-8"></p>
                          </td>
                          <td
                            class="w-1/6 text-center border-b lg:table-cell static m-4 my-2 px-2"
                          >
                            <p class="px-2 mx-4 w-full h-6 blob bg-theme-8"></p>
                          </td>
                          <td
                            class="w-1/6 text-center border-b lg:table-cell static m-4 my-2 px-2"
                          >
                            <p
                              class="px-2 mx-4 w-full h-6 blob bg-theme-12"
                            ></p>
                          </td>
                        </tr>
                      </tbody>
                      <!-- END: loading animation for L view  -->
                      <tbody v-if="ergosLoaded">
                        <tr
                          v-for="(ergo, index) in ergos"
                          :key="index"
                          class="w-full p-2 mx-4 my-6 border-b hover:bg-theme-14"
                        >
                          <td
                            class="w-1/6 text-theme-1 text-center border-b lg:table-cell static"
                          >
                            <router-link
                              :to="{ path: '/ergo-detail/' + ergo.id }"
                            >
                              <p class="py-1 px-2 text-sm">
                                {{ ergo.name }}
                              </p>
                            </router-link>
                          </td>
                          <td
                            class="w-1/6 text-theme-1 text-center border-b lg:table-cell static"
                          >
                            <p class="py-1 px-2 text-sm mx-4 pt-2">
                              {{ ergo.code }}
                            </p>
                          </td>
                          <td
                            class="w-1/6 text-theme-1 text-center border-b lg:table-cell static"
                          >
                            <p
                              v-if="ergo.inUse"
                              class="py-1 px-2 text-sm text-theme-9 rounded-full"
                            >
                              in use
                            </p>
                            <p
                              v-else
                              class="py-1 px-2 text-sm text-theme-6 bg-gray-50 rounded-full"
                            >
                              out of usage
                            </p>
                          </td>
                          <td
                            class="w-1/6 text-theme-1 text-center border-b lg:table-cell static"
                          >
                            <p class="py-1 px-2 text-blue-400 text-sm">
                              {{ ergo.club.name }}
                            </p>
                          </td>
                          <td
                            class="w-1/6 text-theme-1 text-center border-b lg:table-cell static"
                          >
                            <p class="py-1 px-2 text-blue-400 text-sm">
                              {{ ergo.note }}
                            </p>
                          </td>
                          <td
                            v-if="isAvailable"
                            class="w-1/6 text-theme-1 text-center border-b lg:table-cell static"
                          >
                            <a
                              class="flex justify-center py-1 px-2 text-blue-400 text-sm"
                              href="javascript:;"
                              data-toggle="modal"
                              data-target="#delete-ergos-preview"
                              @click="this.selectedErgos = ergo.id"
                            >
                              <Trash2Icon class="text-theme-6 p-1" />
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-if="type == 'ergos'" class="lg:hidden xl:hidden p-2">
                    <!-- START: loading animation for S-M views  -->
                    <div v-if="!ergosLoaded" disabled>
                      <ul
                        v-for="el in 8"
                        :key="el.id"
                        class="w-full border-b py-2 mx-4"
                      >
                        <li>
                          <div class="flex-row">
                            <li
                              class="py-1 text-lg bg-theme-1 my-2 px-2 w-64 h-6 blob"
                            ></li>
                          </div>
                        </li>
                        <li>
                          <span class="inline-block">
                            <li
                              class="py-1 text-sm bg-theme-8 mr-2 px-2 w-32 h-6 blob"
                            ></li>
                          </span>
                        </li>
                        <li>
                          <span class="inline-block">
                            <p
                              class="py-1 text-sm bg-theme-8 mr-2 px-2 blob"
                            ></p>
                          </span>
                        </li>
                        <li>
                          <span class="inline-block">
                            <p
                              class="py-1 text-sm bg-theme-8 mr-2 px-2 w-48 h-6 blob"
                            ></p>
                          </span>
                        </li>
                      </ul>
                    </div>
                    <!-- END: loading animation for S-M views  -->
                    <div class="p-2" v-if="ergosLoaded">
                      <ul
                        v-for="ergo in ergos"
                        :key="ergo.id"
                        class="border border-1 border-theme-14 rounded-md my-2"
                      >
                        <div>
                          <li>
                            <div class="flex justify-end"></div>
                          </li>
                          <li class="bg-theme-14 p-2">
                            <div v-if="isAvailable" class="float-right">
                              <a
                                href="javascript:;"
                                data-toggle="modal"
                                data-target="#delete-ergos-preview"
                                @click="this.selectedErgos = ergo.id"
                              >
                                <Trash2Icon class="text-theme-6 p-1" />
                              </a>
                            </div>
                            <div>
                              <router-link
                                :to="{ path: '/ergo-detail/' + ergo.id }"
                              >
                                <span
                                  class="text-md font-semibold text-theme-19"
                                  >{{ ergo.name }}</span
                                >
                              </router-link>
                            </div>
                          </li>
                          <li class="px-2 py-1">
                            <div>
                              <p class="text-md">
                                <span
                                  class="text-md font-semibold text-theme-19"
                                  >Note: </span
                                >{{ ergo.note }}
                              </p>
                            </div>
                          </li>
                          <li class="px-2 py-1">
                            <div>
                              <p class="text-md">
                                <span
                                  class="text-md font-semibold text-theme-19"
                                  >Club: {{ ergo.club.name }}
                                </span>
                              </p>
                            </div>
                          </li>
                          <li class="px-2 py-1">
                            <div class="flex">
                              <span class="text-md font-semibold text-theme-19"
                                >Status:
                              </span>
                              <p v-if="ergo.inUse" class="text-sm text-theme-9">
                                &nbsp;in use
                              </p>
                              <p v-else class="text-sm text-theme-6">
                                &nbsp;out of usage
                              </p>
                            </div>
                          </li>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
                <div v-if="type == 'others'">
                  <!-- START: EMPTY LIST -->
                  <div v-if="othersLoaded && others.length < 1">
                    <div class="w-full p-4">
                      <div class="flex justify-center mt-6">
                        <LifeBuoyIcon class="w-12 h-12" />
                      </div>
                      <div class="flex justify-center text-lg p-4">
                        No listed boats found for this team
                      </div>
                    </div>
                  </div>
                  <!-- END: EMPTY LIST -->
                  <div class="hidden lg:block">
                    <table v-if="type == 'others'" class="w-full">
                      <thead
                        class="bg-gray-50"
                        v-if="othersLoaded && others.length > 0"
                      >
                        <tr class="border-b text-sm border-blue-50">
                          <th class="p-2 text-theme-1">Name</th>
                          <th class="p-2 text-theme-1">Code</th>
                          <th class="p-2 text-theme-1">Status</th>
                          <th class="p-2 text-theme-1">Club</th>
                          <th class="p-2 text-theme-1">Note</th>
                          <th class="p-2 text-theme-1" v-if="isAvailable">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <!-- START: loading animation for L view  -->
                      <tbody v-if="!othersLoaded">
                        <tr
                          v-for="el in 8"
                          :key="el.id"
                          class="w-full p-2 mx-4 my-6 border-b"
                        >
                          <td
                            class="w-1/6 text-center border-b lg:table-cell static m-4 my-2 px-2"
                          >
                            <p class="px-2 mx-4 w-full h-6 blob bg-theme-1"></p>
                          </td>
                          <td
                            class="w-1/6 text-center border-b lg:table-cell static m-4 my-2 px-2"
                          >
                            <p class="px-2 mx-4 w-full h-6 blob bg-theme-8"></p>
                          </td>
                          <td
                            class="w-1/6 text-center border-b lg:table-cell static m-4 my-2 px-2"
                          >
                            <p class="px-2 mx-4 w-full h-6 blob bg-theme-9"></p>
                          </td>
                          <td
                            class="w-1/6 text-center border-b lg:table-cell static m-4 my-2 px-2"
                          >
                            <p class="px-2 mx-4 w-full h-6 blob bg-theme-8"></p>
                          </td>
                          <td
                            class="w-1/6 text-center border-b lg:table-cell static m-4 my-2 px-2"
                          >
                            <p class="px-2 mx-4 w-full h-6 blob bg-theme-8"></p>
                          </td>
                          <td
                            class="w-1/6 text-center border-b lg:table-cell static m-4 my-2 px-2"
                          >
                            <p
                              class="px-2 mx-4 w-full h-6 blob bg-theme-12"
                            ></p>
                          </td>
                        </tr>
                      </tbody>
                      <!-- END: loading animation for L view  -->
                      <tbody v-if="othersLoaded">
                        <tr
                          v-for="(other, index) in others"
                          :key="index"
                          class="w-full p-2 mx-4 my-6 border-b hover:bg-theme-14"
                        >
                          <td
                            class="w-1/6 text-theme-1 text-center border-b lg:table-cell static"
                          >
                            <router-link
                              :to="{ path: '/other-detail/' + other.id }"
                            >
                              <p class="py-1 px-2 text-sm">
                                {{ other.name }}
                              </p>
                            </router-link>
                          </td>
                          <td
                            class="w-1/6 text-theme-1 text-center border-b lg:table-cell static"
                          >
                            <p class="py-1 px-2 text-sm mx-4 pt-2">
                              {{ other.code }}
                            </p>
                          </td>
                          <td
                            class="w-1/6 text-theme-1 text-center border-b lg:table-cell static"
                          >
                            <p
                              v-if="other.inUse"
                              class="py-1 px-2 text-sm text-theme-9 rounded-full"
                            >
                              in use
                            </p>
                            <p
                              v-else
                              class="py-1 px-2 text-sm text-theme-6 bg-gray-50 rounded-full"
                            >
                              out of usage
                            </p>
                          </td>
                          <td
                            class="w-1/6 text-theme-1 text-center border-b lg:table-cell static"
                          >
                            <p class="py-1 px-2 text-blue-400 text-sm">
                              {{ other.club.name }}
                            </p>
                          </td>
                          <td
                            class="w-1/6 text-theme-1 text-center border-b lg:table-cell static"
                          >
                            <p class="py-1 px-2 text-blue-400 text-sm">
                              {{ other.note }}
                            </p>
                          </td>
                          <td
                            v-if="isAvailable"
                            class="w-1/6 text-theme-1 text-center border-b lg:table-cell static"
                          >
                            <a
                              class="flex justify-center py-1 px-2 text-blue-400 text-sm"
                              href="javascript:;"
                              data-toggle="modal"
                              data-target="#delete-inventory-preview"
                              @click="this.selectedInventory = other.id"
                            >
                              <Trash2Icon class="text-theme-6 p-1" />
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-if="type == 'others'" class="lg:hidden xl:hidden p-2">
                    <!-- START: loading animation for S-M views  -->
                    <div v-if="!othersLoaded" disabled>
                      <ul
                        v-for="el in 8"
                        :key="el.id"
                        class="w-full border-b py-2 mx-4"
                      >
                        <li>
                          <div class="flex-row">
                            <li
                              class="py-1 text-lg bg-theme-1 my-2 px-2 w-64 h-6 blob"
                            ></li>
                          </div>
                        </li>
                        <li>
                          <span class="inline-block">
                            <li
                              class="py-1 text-sm bg-theme-8 mr-2 px-2 w-32 h-6 blob"
                            ></li>
                          </span>
                        </li>
                        <li>
                          <span class="inline-block">
                            <p
                              class="py-1 text-sm bg-theme-8 mr-2 px-2 blob"
                            ></p>
                          </span>
                        </li>
                        <li>
                          <span class="inline-block">
                            <p
                              class="py-1 text-sm bg-theme-8 mr-2 px-2 w-48 h-6 blob"
                            ></p>
                          </span>
                        </li>
                      </ul>
                    </div>
                    <!-- END: loading animation for S-M views  -->
                    <div class="p-2" v-if="othersLoaded">
                      <ul
                        v-for="other in others"
                        :key="other.id"
                        class="border border-1 border-theme-14 rounded-md my-2"
                      >
                        <div>
                          <li>
                            <div class="flex justify-end"></div>
                          </li>
                          <li class="bg-theme-14 p-2">
                            <div v-if="isAvailable" class="float-right">
                              <a
                                href="javascript:;"
                                data-toggle="modal"
                                data-target="#delete-inventory-preview"
                                @click="this.selectedInventory = other.id"
                              >
                                <Trash2Icon class="text-theme-6 p-1" />
                              </a>
                            </div>
                            <div>
                              <router-link
                                :to="{ path: '/other-detail/' + other.id }"
                              >
                                <span
                                  class="text-md font-semibold text-theme-19"
                                  >{{ other.name }}</span
                                >
                              </router-link>
                            </div>
                          </li>
                          <li class="px-2 py-1">
                            <div>
                              <p class="text-md">
                                <span
                                  class="text-md font-semibold text-theme-19"
                                  >Note: </span
                                >{{ other.note }}
                              </p>
                            </div>
                          </li>
                          <li class="px-2 py-1">
                            <div>
                              <p class="text-md">
                                <span
                                  class="text-md font-semibold text-theme-19"
                                  >Club: {{ other.club.name }}
                                </span>
                              </p>
                            </div>
                          </li>
                          <li class="px-2 py-1">
                            <div class="flex">
                              <span class="text-md font-semibold text-theme-19"
                                >Status:
                              </span>
                              <p
                                v-if="other.inUse"
                                class="text-sm text-theme-9"
                              >
                                &nbsp;in use
                              </p>
                              <p v-else class="text-sm text-theme-6">
                                &nbsp;out of usage
                              </p>
                            </div>
                          </li>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
                <div id="delete-modal">
                  <div class="preview">
                    <!-- START: Modal Content -->
                    <div
                      id="delete-modal-preview"
                      class="modal"
                      tabindex="-1"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-body p-0">
                            <div class="p-5 text-center">
                              <XCircleIcon
                                class="w-16 h-16 text-theme-6 mx-auto mt-3"
                              />
                              <div class="text-3xl mt-5">Are you sure?</div>
                              <div class="text-gray-600 mt-2">
                                This process cannot be undone.
                              </div>
                            </div>
                            <div class="px-5 pb-8 text-center">
                              <button
                                type="button"
                                data-dismiss="modal"
                                class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1"
                              >
                                Cancel
                              </button>
                              <button
                                @click="deleteBoat(this.selectedBoat)"
                                type="button"
                                class="btn btn-danger w-24"
                                data-dismiss="modal"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- END: Modal Content -->
                  </div>
                </div>
                <div id="delete-modal">
                  <div class="preview">
                    <!-- START: Modal Content -->
                    <div
                      id="delete-ergos-preview"
                      class="modal"
                      tabindex="-1"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-body p-0">
                            <div class="p-5 text-center">
                              <XCircleIcon
                                class="w-16 h-16 text-theme-6 mx-auto mt-3"
                              />
                              <div class="text-3xl mt-5">Are you sure?</div>
                              <div class="text-gray-600 mt-2">
                                This process cannot be undone.
                              </div>
                            </div>
                            <div class="px-5 pb-8 text-center">
                              <button
                                type="button"
                                data-dismiss="modal"
                                class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1"
                              >
                                Cancel
                              </button>
                              <button
                                @click="deleteErgos(this.selectedErgos)"
                                type="button"
                                class="btn btn-danger w-24"
                                data-dismiss="modal"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- END: Modal Content -->
                  </div>
                </div>
                <div id="delete-modal">
                  <div class="preview">
                    <!-- START: Modal Content -->
                    <div
                      id="delete-inventory-preview"
                      class="modal"
                      tabindex="-1"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-body p-0">
                            <div class="p-5 text-center">
                              <XCircleIcon
                                class="w-16 h-16 text-theme-6 mx-auto mt-3"
                              />
                              <div class="text-3xl mt-5">Are you sure?</div>
                              <div class="text-gray-600 mt-2">
                                This process cannot be undone.
                              </div>
                            </div>
                            <div class="px-5 pb-8 text-center">
                              <button
                                type="button"
                                data-dismiss="modal"
                                class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1"
                              >
                                Cancel
                              </button>
                              <button
                                @click="deleteInventory(this.selectedInventory)"
                                type="button"
                                class="btn btn-danger w-24"
                                data-dismiss="modal"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- END: Modal Content -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<style scoped>
.blob {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
</style>

<script>
import { computed, onMounted, reactive } from 'vue'
import { useStore } from '@/store'
import Toastify from 'toastify-js'

export default {
  name: 'inventories',
  data() {
    return {
      type: 'boats',
      isLoaded: false,
      ergosLoaded: false,
      othersLoaded: false
    }
  },
  methods: {},
  watch: {
    boats() {
      if (this.$store.state.inventories.boatsLoaded.message) {
        this.isLoaded = true
      }
    },
    ergos() {
      if (this.$store.state.inventories.ergosLoaded.message) {
        this.ergosLoaded = true
      }
    },
    others() {
      if (this.$store.state.inventories.othersLoaded.message) {
        this.othersLoaded = true
      }
    },
    exports() {
      if (this.$store.state?.inventories?.export?.length > 0) {
        var csv =
          'data:text/csv;charset=utf-8,' + this.$store.state.inventories.export
        var excel = encodeURI(csv)
        var link = document.createElement('a')
        link.setAttribute('href', excel)
        link.setAttribute('download', 'export.csv')
        link.click()
        Toastify({
          duration: 2000,
          text: 'CSV file created successfully.',
          newWindow: true,
          style: {
            backgroundColor: '#91C714',
            color: 'white',
            padding: '10px'
          },
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
      } else {
        Toastify({
          duration: 2000,
          text: 'An error has occurred, please try again.',
          newWindow: true,
          style: {
            backgroundColor: '#D32929',
            color: 'white',
            padding: '10px'
          },
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
      }
    }
  },
  setup() {
    const store = useStore()
    const boats = computed(() => store.state.inventories.boats)
    const ergos = computed(() => store.state.inventories.ergos)
    const others = computed(() => store.state.inventories.others)
    const exports = computed(() => store.state.inventories.export)
    const role = computed(() => store.state.profile.user?.userRole?.name)
    const isAvailable = computed(
      () =>
        store.state.profile.user?.userRole?.name == 'Coach' ||
        store.state.profile.user?.userRole?.name == 'Super Admin'
    )

    onMounted(() => {
      store.dispatch('inventories/getBoatsData')
      store.dispatch('inventories/getErgosData')
      store.dispatch('inventories/getOthersData')
      store.dispatch('profile/getProfileData')
    })
    const clubName = computed(() => store.state.profile.user?.club.name)
    const formData = reactive({
      type: '',
      status: '',
      weight: ''
    })
    const deleteBoat = (boatId) => {
      store.dispatch('inventories/deleteBoat', boatId)
    }
    const deleteErgos = (ergoId) => {
      store.dispatch('inventories/deleteErgos', ergoId)
    }
    const deleteInventory = (id) => {
      store.dispatch('inventories/deleteInventory', id)
    }
    const exportBoats = () => {
      store.dispatch('inventories/getExportData')
    }
    return {
      formData,
      deleteBoat,
      deleteInventory,
      deleteErgos,
      exportBoats,
      clubName,
      boats,
      ergos,
      others,
      exports,
      role,
      isAvailable
    }
  }
}
</script>
