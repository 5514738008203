import axios from 'axios'
export const WEATHER = 'WEATHER'

const state = () => {
  return {
    weather: {}
  }
}

// getters
const getters = {
  weather: state => {
    return state.weather
  }
}

// actions
const actions = {
  getWeatherData({ commit }, params) {
    commit(WEATHER, params)
  }
}

// mutations
const mutations = {
  [WEATHER](state, params) {
    axios
      .get(process.env.VUE_APP_API_BASE_URL + '/api/weather?lat=' + params.lat + '&lon=' + params.lon)
      .then(response => {
        state.weather = response.data
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
