import {
  getAuth,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut
} from 'firebase/auth'

import router from '@/router'
import axios from 'axios'
import Toastify from 'toastify-js'
import authErrorMessages from '../utils/authErrorMessages'

export const REGISTER = 'REGISTER'
export const REGISTER_FOR_CLUB = 'REGISTER_FOR_CLUB'
export const SET_LOGGED_IN = 'SET_LOGGED_IN '
export const LOGGED_OUT = 'LOGGED_OUT'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'

const state = () => {
  return {
    user: {
      isLoggedIn: false,
      loginErrorMessage: null,
      data: null
    },
    registration: {
      isSuccess: false,
      errorMessage: ''
    },
    forgotPassword: {
      isSent: false,
      errorMessage: null
    }
  }
}

// getters
const getters = {
  darkMode: state => state.darkMode,
  isLoggedIn: state => state.user.isLoggedIn,
  userData: state => state.user
}

// actions
const actions = {
  setRegistration({ commit }, formData) {
    commit(REGISTER, formData)
  },
  setClubRegistration({ commit }, formData) {
    commit(REGISTER_FOR_CLUB, formData)
  },
  setLoggedIn({ commit }, formData) {
    commit(SET_LOGGED_IN, formData)
  },
  loggedOut({ commit }) {
    commit(LOGGED_OUT)
  },
  forgotPassword({ commit }, formData) {
    commit(FORGOT_PASSWORD, formData)
  }
}

// mutations
const mutations = {
  [REGISTER](state, formData) {
    const auth = getAuth()
    createUserWithEmailAndPassword(auth, formData.email, formData.password)
      .then(userCredential => {
        state.registration.isSuccess = true
      })
      .catch(error => {
        if (authErrorMessages[error.code] != undefined) {
          state.registration.errorMessage = authErrorMessages[error.code]
        } else {
          state.registration.errorMessage =
            'Something went wrong please try again or contact us'
        }
      })
  },
  [REGISTER_FOR_CLUB](state, formData) {
    axios.post(process.env.VUE_APP_API_BASE_URL + '/api/register-club-owner', {
      firstName: formData.name,
      lastName: formData.lastName,
      clubName: formData.clubName,
      userEmail: formData.email,
      password: formData.password
    })
      .then(response => {
        if (response.data.message) {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#91C714',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
          router.push('/')
        } else {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#D32929',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
  [SET_LOGGED_IN](state, formData) {
    const auth = getAuth()

    signInWithEmailAndPassword(auth, formData.email, formData.password, router)
      .then(userCredential => {
        // Signed in
        const user = userCredential.user

        state.user.data = user
        state.user.isLoggedIn = true
        state.user.loginErrorMessage = null
        router.go('/')
      })
      .catch(error => {
        console.log(error)
        if (authErrorMessages[error.code] != undefined) {
          state.user.loginErrorMessage = authErrorMessages[error.code]
        } else {
          state.user.loginErrorMessage =
            'Something went wrong please try again or contact us'
        }
      })
  },
  [LOGGED_OUT](state) {
    const auth = getAuth()
    signOut(auth)
      .then(() => {
        state.user.isLoggedIn = false
        state.user.data = null
      })
      .catch(error => {
        console.log(error)
      })
  },
  [FORGOT_PASSWORD](state, formData) {
    const auth = getAuth()
    sendPasswordResetEmail(auth, formData.email)
      .then(() => {
        state.forgotPassword.isSent = true
        state.forgotPassword.errorMessage = null
      })
      .catch(error => {
        state.forgotPassword.isSent = false
        state.forgotPassword.errorMessage = authErrorMessages[error.code]
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
