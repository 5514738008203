import axios from 'axios'
import moment from 'moment'
import Toastify from 'toastify-js'

export const BOATS = 'BOATS'
export const ERGOS = 'ERGOS'
export const OTHERS = 'OTHERS'
export const BOAT_TYPES = 'BOAT_TYPES'
export const EXPORT = 'EXPORT'

export const BOAT_DETAIL = 'BOAT_DETAIL'
export const ERGO_DETAIL = 'ERGO_DETAIL'
export const OTHER_DETAIL = 'OTHER_DETAIL'

export const ADD_BOATS = 'ADD_BOATS'
export const ADD_ERGO = 'ADD_ERGO'
export const ADD_OTHER = 'ADD_OTHER'
export const ADD_REPAIR = 'ADD_REPAIR'

export const UPDATE_BOAT = 'UPDATE_BOAT'
export const UPDATE_ERGO = 'UPDATE_ERGO'
export const UPDATE_OTHER = 'UPDATE_OTHER'

export const DELETE_BOAT = 'DELETE_BOAT'
export const DELETE_ERGOS = 'DELETE_ERGOS'
export const DELETE_INVENTORY = 'DELETE_INVENTORY'
export const DELETE_REPAIR = 'DELETE_REPAIR'

const state = () => {
  return {
    boats: {
    },
    ergos: {
    },
    others: {
    },
    export: {
    },
    boatDetail: {},
    ergoDetail: {},
    otherDetail: {},
    boatTypes: {},
    addRepair: {
      isFormSubmitted: false,
      isSuccess: null
    },
    addBoat: {
      isFormSubmitted: false,
      isSuccess: null
    },
    boatDetailLoaded: {
      message: null
    },
    boatsLoaded: {
      message: null
    },
    ergosLoaded: {
      message: null
    },
    exportLoaded: {
      message: null
    },
    ergoDetailLoaded: {
      message: null
    },
    othersLoaded: {
      message: null
    },
    otherDetailLoaded: {
      message: null
    }
  }
}

// getters
const getters = {
  boats: state => {
    return state.boats
  },
  ergos: state => {
    return state.ergos
  },
  others: state => {
    return state.others
  },
  export: state => {
    return state.export
  },
  boatDetail: state => {
    return state.boatDetail
  },
  ergoDetail: state => {
    return state.ergoDetail
  },
  otherDetail: state => {
    return state.otherDetail
  },
  boatTypes: state => {
    return state.boatTypes
  }
}

// actions
const actions = {
  getBoatsData({ commit }, data) {
    commit(BOATS, data)
  },
  getErgosData({ commit }, data) {
    commit(ERGOS, data)
  },
  getOthersData({ commit }, data) {
    commit(OTHERS, data)
  },
  getExportData({ commit }, data) {
    commit(EXPORT, data)
  },
  getBoatDetail({ commit }, data) {
    commit(BOAT_DETAIL, data)
  },
  getErgoDetail({ commit }, data) {
    commit(ERGO_DETAIL, data)
  },
  getOtherDetail({ commit }, data) {
    commit(OTHER_DETAIL, data)
  },
  getBoatTypes({ commit }, data) {
    commit(BOAT_TYPES, data)
  },
  addBoats({ commit }, formData) {
    commit(ADD_BOATS, formData)
  },
  addErgo({ commit }, formData) {
    commit(ADD_ERGO, formData)
  },
  addOther({ commit }, formData) {
    commit(ADD_OTHER, formData)
  },
  updateBoat({ commit }, params) {
    commit(UPDATE_BOAT, params)
  },
  updateErgo({ commit }, params) {
    commit(UPDATE_ERGO, params)
  },
  updateOther({ commit }, params) {
    commit(UPDATE_OTHER, params)
  },
  addRepair({ commit }, formData) {
    commit(ADD_REPAIR, formData)
  },
  deleteBoat({ commit }, params) {
    commit(DELETE_BOAT, params)
  },
  deleteErgos({ commit }, params) {
    commit(DELETE_ERGOS, params)
  },
  deleteInventory({ commit }, params) {
    commit(DELETE_INVENTORY, params)
  },
  deleteRepair({ commit }, params) {
    commit(DELETE_REPAIR, params)
  }
}

// mutations
const mutations = {
  [BOATS](state, data) {
    axios
      .get(process.env.VUE_APP_API_BASE_URL + '/api/club/boat/list')
      .then(response => {
        if (response.data.success) {
          state.boats = response.data.data
          if (response.status == 200) {
            state.boatsLoaded.message = true
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
  [ERGOS](state, data) {
    axios
      .get(process.env.VUE_APP_API_BASE_URL + '/api/inventories?type=ergo')
      .then(response => {
        if (response.data.success) {
          state.ergos = response.data.data
          if (response.status == 200) {
            state.ergosLoaded.message = true
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
  [OTHERS](state, data) {
    axios
      .get(process.env.VUE_APP_API_BASE_URL + '/api/inventories?type=other')
      .then(response => {
        if (response.data.success) {
          state.others = response.data.data
          if (response.status == 200) {
            state.othersLoaded.message = true
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
  [EXPORT](state, data) {
    axios
      .get(process.env.VUE_APP_API_BASE_URL + '/api/export/export-boats')
      .then(response => {
        state.export = response.data
        if (response.status == 200) {
          state.exportLoaded.message = true
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
  [BOAT_TYPES](state, data) {
    axios
      .get(process.env.VUE_APP_API_BASE_URL + '/api/boat-types')
      .then(response => {
        if (response.data.success) {
          state.boatTypes = response.data.data
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
  [BOAT_DETAIL](state, id) {
    if (id !== undefined) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + '/api/boat/' + id)
        .then(response => {
          if (response.data.success) {
            state.boatDetail = response.data.data
            if (response.status == 200) {
              state.boatDetailLoaded.message = true
            }
            for (var i = 0; i < state.boatDetail.boatRepairs.length; i++) {
              state.boatDetail.boatRepairs[i].dateAdded = moment(state.boatDetail.boatRepairs[i].dateAdded * 1000).format('MMM Do YY')
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      state.boatDetail = undefined
    }
  },
  [ERGO_DETAIL](state, id) {
    if (id !== undefined) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + '/api/inventory/' + id)
        .then(response => {
          if (response.data.success) {
            state.ergoDetail = response.data.data
            if (response.status == 200) {
              state.ergoDetailLoaded.message = true
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      state.ergoDetail = undefined
    }
  },
  [OTHER_DETAIL](state, id) {
    if (id !== undefined) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + '/api/inventory/' + id)
        .then(response => {
          if (response.data.success) {
            state.otherDetail = response.data.data
            if (response.status == 200) {
              state.otherDetailLoaded.message = true
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      state.otherDetail = undefined
    }
  },
  [ADD_BOATS](state, formData) {
    state.addBoat.isFormSubmitted = true
    axios.post(process.env.VUE_APP_API_BASE_URL + '/api/boat', {
      name: formData.boatName,
      status: formData.status,
      note: formData.note,
      boatCode: formData.boatCode,
      crewWeight: formData.crewWeight,
      boatType: formData.boatType,
      year: formData.year,
      make: formData.make,
      owner: formData.owner
    })
      .then(response => {
        if (response.data.message) {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#91C714',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        } else {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#D32929',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        }
        state.addBoat.isSuccess = response.data.success
      })
    state.addBoat.isSuccess = null
  },
  [ADD_ERGO](state, formData) {
    state.addBoat.isFormSubmitted = true
    axios.post(process.env.VUE_APP_API_BASE_URL + '/api/inventory', {
      name: formData.name,
      type: 'ergo',
      code: formData.code,
      inUse: formData.status,
      note: formData.note
    })
      .then(response => {
        if (response.data.message) {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#91C714',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        } else {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#D32929',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        }
        state.addBoat.isSuccess = response.data.success
      })
    state.addBoat.isSuccess = null
  },
  [ADD_OTHER](state, formData) {
    state.addBoat.isFormSubmitted = true
    axios.post(process.env.VUE_APP_API_BASE_URL + '/api/inventory', {
      name: formData.name,
      type: 'other',
      code: formData.code,
      inUse: formData.status,
      note: formData.note
    })
      .then(response => {
        if (response.data.message) {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#91C714',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        } else {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#D32929',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        }
        state.addBoat.isSuccess = response.data.success
      })
    state.addBoat.isSuccess = null
  },
  [ADD_REPAIR](state, formData) {
    state.addRepair.isFormSubmitted = true
    axios.post(process.env.VUE_APP_API_BASE_URL + '/api/boat/repair/' + formData.id, {
      cost: formData.cost,
      note: formData.note
    })
      .then(response => {
        if (response.data.message) {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#91C714',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        } else {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#D32929',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        }
        state.addRepair.isSuccess = response.data.success
      })
    state.addRepair.isSuccess = null
  },
  [UPDATE_BOAT](state, params) {
    axios.put(process.env.VUE_APP_API_BASE_URL + '/api/boat/' + params.id, {
      name: params.name,
      boatCode: params.boatCode,
      status: params.status,
      boatType: params.boatType.boatType,
      crewWeight: params.crewWeight,
      make: params.make,
      insuranceValue: params.insuranceValue,
      note: params.note,
      owner: params.owner,
      year: params.year

    })
      .then(response => {
        if (response.data.message) {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#91C714',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        } else {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#D32929',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        }
      })
      .catch(err => {
        console.log(err)
      })
  },
  [UPDATE_ERGO](state, params) {
    axios.put(process.env.VUE_APP_API_BASE_URL + '/api/inventory/' + params.id, {
      name: params.name,
      code: params.code,
      note: params.note,
      inUse: params.inUse,
      type: 'ergo'
    })
      .then(response => {
        if (response.data.message) {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#91C714',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        } else {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#D32929',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        }
      })
      .catch(err => {
        console.log(err)
      })
  },
  [UPDATE_OTHER](state, params) {
    axios.put(process.env.VUE_APP_API_BASE_URL + '/api/inventory/' + params.id, {
      name: params.name,
      code: params.code,
      note: params.note,
      inUse: params.inUse,
      type: 'other'

    })
      .then(response => {
        if (response.data.message) {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#91C714',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        } else {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#D32929',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        }
      })
      .catch(err => {
        console.log(err)
      })
  },
  [DELETE_BOAT](state, params) {
    axios.delete(process.env.VUE_APP_API_BASE_URL + '/api/boat/' + params)
      .then((response) => {
        state.boats.map((boat) => {
          if (params == boat.id) {
            state.boats =
              state.boats.filter((boat) => {
                return boat.id != params
              })
          }
        })
        state.boats
        if (response.data.message) {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#91C714',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        } else {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#D32929',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        }
      })
  },
  [DELETE_ERGOS](state, params) {
    axios.delete(process.env.VUE_APP_API_BASE_URL + '/api/inventory/' + params)
      .then((response) => {
        state.ergos.map((ergo) => {
          if (params == ergo.id) {
            state.ergos =
              state.ergos.filter((ergo) => {
                return ergo.id != params
              })
          }
        })
        state.ergos
        if (response.data.message) {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#91C714',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        } else {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#D32929',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        }
      })
  },
  [DELETE_INVENTORY](state, params) {
    axios.delete(process.env.VUE_APP_API_BASE_URL + '/api/inventory/' + params)
      .then((response) => {
        state.others.map((other) => {
          if (params == other.id) {
            state.others =
              state.others.filter((other) => {
                return other.id != params
              })
          }
        })
        state.others
        if (response.data.message) {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#91C714',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        } else {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#D32929',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        }
      })
  },
  [DELETE_REPAIR](state, params) {
    axios.delete(process.env.VUE_APP_API_BASE_URL + '/api/boat/' + params.id + '/repair/' + params.index)
      .then((response) => {
        state.boatDetail.boatRepairs.splice(params.index, 1)
        if (response.data.message) {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#91C714',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        } else {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#D32929',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        }
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
