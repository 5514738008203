<template>
  <div>
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Register Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="" class="text-3xl -intro-x flex items-center pt-5">
            🚣
            <span class="text-white text-lg ml-3">
              Rower<span class="font-medium">hub</span>
            </span>
          </a>
          <div class="my-auto">
            <img
              alt="Rowerhub"
              class="-intro-x w-1/2 -mt-16"
              :src="require(`@/assets/images/illustration.svg`)"
            />
            <div
              class="
                -intro-x
                text-white
                font-medium
                text-4xl
                leading-tight
                mt-10
              "
            >
              A few more clicks to <br />
              sign up to your account.
            </div>
            <div
              class="
                -intro-x
                mt-5
                text-lg text-white text-opacity-70
                dark:text-gray-500
              "
            >
             You've been Invited to Rowerhub Rowing club ({{user?.club?.name}}). <br>Please enter a password and start to use rowerhub.
            </div>
          </div>
        </div>
        <!-- END: Register Info -->
        <!-- BEGIN: Register Form -->
        <div class="h-screen xl:h-auto xl:py-0 xl:my-0 xl:flex">
          <div class="flex xl:hidden w-full">
            <a href="" class="-intro-x w-40 my-5 flex mx-auto">
              🚣
              <span class="text-white text-lg ml-2">
                Rower<span class="font-medium">hub</span>
              </span>
            </a>
          </div>
          <div
            class="
              my-auto
              mx-auto
              xl:ml-20
              bg-white
              dark:bg-dark-1
              xl:bg-transparent
              px-5
              sm:px-8
              py-8
              xl:p-0
              rounded-md
              shadow-md
              xl:shadow-none
              w-full
              sm:w-3/4
              lg:w-2/4
              xl:w-auto
            "
          >
            <div>
              <h2
                class="
                  intro-x
                  font-bold
                  text-2xl
                  xl:text-3xl
                  text-center
                  xl:text-left
                  text-white
                "
              >
                Sign Up
              </h2>
              <div
                class="
                  intro-x
                  mt-2
                  text-gray-500
                  dark:text-gray-500
                  xl:hidden
                  text-center
                "
              >
                A few more clicks to sign in to your account. Manage all your
                club events in one place
              </div>
              <div class="intro-x mt-8" id="form-fields">
                <!-- Name !-->
                <label
                  for="name"
                  class="
                    form-label
                    w-full
                    flex flex-col
                    mt-4
                    sm:flex-row
                    text-white
                  "
                  >First Name</label
                >
                <input
                  type="text"
                  name="name"
                  class="
                    intro-x
                    login__input
                    form-control
                    py-3
                    px-4
                    border-gray-300
                    block
                  "
                  v-model="user.firstName"
                  placeholder="First Name"
                  disabled
                />

                <!-- Lastname !-->
                <label
                  for="lastname"
                  class="
                    form-label
                    w-full
                    flex flex-col
                    mt-4
                    sm:flex-row
                    text-white
                  "
                  >Last Name</label
                >
                <input
                  type="text"
                  name="lastname"
                  class="
                    intro-x
                    login__input
                    form-control
                    py-3
                    px-4
                    border-gray-300
                    block
                  "
                  v-model="user.lastName"
                  placeholder="Last Name"
                  disabled
                />

                <!-- email !-->
                <label
                  for="email"
                  class="
                    form-label
                    w-full
                    flex flex-col
                    mt-4
                    sm:flex-row
                    text-white
                  "
                  >E-mail</label
                >
                <input
                  type="text"
                  name="email"
                  class="
                    intro-x
                    login__input
                    form-control
                    py-3
                    px-4
                    border-gray-300
                    block
                  "
                  v-model="user.email"
                  placeholder="E-mail"
                  disabled
                />

                <!-- password !-->
                <label
                  for="password"
                  class="
                    form-label
                    w-full
                    flex flex-col
                    mt-4
                    sm:flex-row
                    text-white
                  "
                  >Password</label
                >
                <input
                  type="password"
                  name="password"
                  class="
                    intro-x
                    login__input
                    form-control
                    py-3
                    px-4
                    border-gray-300
                    block
                  "
                  v-model.trim="formData.password"
                  placeholder="Password"
                />
              </div>
              <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
                <button
                  class="
                    btn btn-outline-secondary
                    bg-white
                    py-3
                    px-2
                    w-full
                    mt-2
                    xl:w-64
                    align-top
                  "
                  v-on:click="submit()"
                >
                  Register and Login
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Register Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, computed } from 'vue'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'

export default {
  data() {
    return {
    }
  },
  watch: {
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const user = computed(() => store.state.invite.user)
    onMounted(() => {
      store.dispatch('invite/getUserData', route.params.id)
    })

    const formData = reactive({
      password: ''
    })
    const submit = () => {
      store.dispatch('invite/addUser', {
        password: formData.password,
        id: route.params.id
      })
    }
    return {
      user,
      formData,
      submit
    }
  }
}
</script>
