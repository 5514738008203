<template>
  <div id="app">
    <div class="intro-y box">
      <div class="p-5 border-b border-gray-200 dark:border-dark-5">
        <span class="
                        inline-flex
                        justify-center
                        border border-1 border-black
                        rounded
                        mx-2
                      ">
          <router-link :to="{ path: '/boat-booking' }">
            <div class="flex gap-1">
              <ArrowLeftIcon />
            </div>
          </router-link>
        </span>
        <span class="font-medium text-base"> Add Boat Booking </span>
      </div>
      <div class="px-5 w-full">
        <div class="flex flex-row">
          <div class="w-1/2">
            <div>
              <label for="update-profile-form-1" class="form-label block font-bold mt-3">Add Filters</label>
            </div>
            <div>
              <div class="flex gap-2 wide">
                <div class="w-full">
                  <label for="update-profile-form-1" class="form-label block">Date</label>
                  <v-date-picker v-model="filters.date.date" mode="date" :masks="filters.masks">
                    <template v-slot="{ inputValue, inputEvents }">
                      <input class="
                                      px-2
                                      py-1
                                      border
                                      rounded
                                      focus:outline-none focus:border-blue-300
                                      w-full
                                    " :value="inputValue" v-on="inputEvents" />
                    </template>
                  </v-date-picker>
                </div>
              </div>
              <div class="w-full gap-2 small">
                <div class="my-auto">
                  <label for="update-profile-form-1" class="form-label block">Date</label>
                  <v-date-picker v-model="filters.date.date" mode="date" :masks="filters.masks">
                    <template v-slot="{ inputValue, inputEvents }">
                      <input class="
                                      px-2
                                      py-1
                                      border
                                      rounded
                                      focus:outline-none focus:border-blue-300
                                    " :value="inputValue" v-on="inputEvents" />
                    </template>
                  </v-date-picker>
                </div>
              </div>
            </div>
            <div>
              <label for="update-profile-form-1" class="form-label block mt-3">Boat Type</label>
              <v-select label="boatType" v-model="filters.type" :reduce="(option) => option?.boatType" :options="types">
              </v-select>
            </div>
            <div class="mb-4">
              <label for="update-profile-form-1" class="form-label mt-3">Select Boat</label>
              <v-select v-model="filteredBoats.bookingBoat" :options="filteredBoats.boats" label="name">
                <template v-slot:option="option">
                  {{ option?.name }} ({{ option?.boatType?.boatType }})
                </template>
              </v-select>
            </div>
          </div>
          <div v-if="sessions?.morning?.length > 0" class="rounded-md bg-theme-32 w-1/2 mx-1">
            <div class="mx-2 text-center">
              <div class="font-bold text-theme-34 mt-3">{{ date?.date }}</div>
              <div class="font-bold text-theme-34 mb-3">{{ date?.value }}</div>
              <div class="p-2">Morning</div>
              <button @click="
                submitBooking(
                  (start = session?.startDate),
                  (end = session?.endDate),
                  index
                )
              " v-for="(session, index) in sessions?.morning" :key="index" class="
                                  inline-block
                                  border
                                  border-1
                                  rounded-md
                                  text-xs
                                  mx-2
                                  mb-2
                                  p-1
                                  bg-white
                                " :disabled="!session?.available" :id="index" :clicked="false" :time="session?.startDate"
                :class="{
                  'bg-primary-1 text-white border-primary-1': setBooking.times.includes(session?.startDate),
                  'bg-theme-33 border-theme-33': !session?.available
                }">
                {{ session?.start }}&nbsp;&#8209;>&nbsp;{{ session?.end }}
              </button>
              <div class="p-2">Afternoon</div>
              <button @click="
                submitBooking(
                  (start = session?.startDate),
                  (end = session?.endDate),
                  index
                )
              " v-for="(session, index) in sessions?.afternoon" :key="index" class="
                                  inline-block
                                  border
                                  border-1
                                  rounded-md
                                  text-xs
                                  mx-2
                                  mb-2
                                  p-1
                                  bg-white
                                " :disabled="!session?.available" :id="index" :clicked="false" :time="session?.startDate"
                :class="{
                  'bg-primary-1 text-white border-primary-1': setBooking.times.includes(session?.startDate),
                  'bg-theme-33 border-theme-33': !session?.available
                }">
                {{ session?.start }}&nbsp;&#8209;>&nbsp;{{ session?.end }}
              </button>
              <div class="p-2">Evening</div>
              <button @click="
                submitBooking(
                  (start = session?.startDate),
                  (end = session?.endDate),
                  index
                )
              " v-for="(session, index) in sessions?.evening" :key="index" class="
                                  inline-block
                                  border
                                  border-1
                                  rounded-md
                                  text-xs
                                  mx-2
                                  mb-2
                                  p-1
                                  bg-white
                                " :disabled="!session?.available" :id="index" :clicked="false" :time="session?.startDate"
                :class="{
                  'bg-primary-1 text-white border-primary-1': setBooking.times.includes(session?.startDate),
                  'bg-theme-33 border-theme-33': !session?.available
                }">
                {{ session?.start }}&nbsp;&#8209;>&nbsp;{{ session?.end }}
              </button>
            </div>
          </div>
        </div>
        <div class="py-2">
          <button @click="submit()" type="submit" class="btn btn-primary">
            Complete Boat Booking
          </button>
        </div>
      </div>
      <div id="delete-modal">
        <div class="preview">
          <!-- START: Modal Content -->
          <div id="delete-ergos-preview" class="modal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-body p-0">
                  <div class="p-5 text-center">
                    <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
                    <div class="text-3xl mt-5">Are you sure?</div>
                    <div class="text-gray-600 mt-2">
                      This process cannot be undone.
                    </div>
                  </div>
                  <div class="px-5 pb-8 text-center">
                    <button type="button" data-dismiss="modal" class="
                                    btn btn-outline-secondary
                                    w-24
                                    dark:border-dark-5 dark:text-gray-300
                                    mr-1
                                  ">
                      Cancel
                    </button>
                    <button @click="deleteBooking(this.selectedBooking)" type="button" class="btn btn-danger w-24"
                      data-dismiss="modal">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END: Modal Content -->
        </div>
      </div>
    </div>
</div>
</template>

<style scoped>
@media screen and (min-width: 735px) {
  .wide {
    display: flex;
  }

  .small {
    display: none;
  }
}

@media screen and (max-width: 734px) {
  .wide {
    display: none;
  }

  .small {
    display: block;
  }
}
</style>

<script>
import { computed, onMounted, reactive } from 'vue'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'
import moment from 'moment'
import Toastify from 'toastify-js'

export default {
  name: 'Boat Booking',
  data() {
    return {
      filters: {
        type: '',
        date: { date: '', startTime: '', endTime: '' },
        masks: {
          input: 'YYYY-MM-DD'
        }
      },
      isLoaded: false,
      filteredBoats: {
        boats: [],
        bookingBoat: ''
      },
      types: [],
      days: [],
      buttons: [],
      sessions: {},
      nextWeek: '',
      date: { date: '', value: '' },
      unix: { today: 0, nextWeek: 0 },
      setBooking: { index: [], timecodes: [], times: [] },
      bookingSettings: {
        set: false,
        start: 0,
        end: 0
      }
    }
  },
  methods: {
    submit() {
      if (
        !this.bookingSettings.set ||
        this.bookingSettings.start == 0 ||
        this.bookingSettings.end == 0 ||
        this.filteredBoats.bookingBoat == ''
      ) {
        Toastify({
          duration: 2000,
          text: 'Please fill all fields',
          newWindow: true,
          style: {
            backgroundColor: '#D32929',
            color: 'white',
            padding: '10px'
          },
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
      } else {
        this.$store.dispatch('bookings/addBoatBooking', {
          boat: this.filteredBoats.bookingBoat.id,
          startTime: this.bookingSettings.start,
          endTime: this.bookingSettings.end
        })
      }
    },
    createSessions() {
      this.sessions = { morning: [], afternoon: [], evening: [] }
      var morningEndTime = moment(this.filters.date.date).subtract(moment(this.filters.date.date).hour(), 'hours')
      var afternoonEndTime = moment(this.filters.date.date).subtract(moment(this.filters.date.date).hour(), 'hours')
      var eveningEndTime = moment(this.filters.date.date).subtract(moment(this.filters.date.date).hour(), 'hours')
      morningEndTime = morningEndTime.subtract(moment(this.filters.date.date).minutes(), 'minutes')
      morningEndTime = morningEndTime.subtract(moment(this.filters.date.date).seconds(), 'seconds')
      morningEndTime = morningEndTime.add(12, 'hour')
      afternoonEndTime = afternoonEndTime.subtract(moment(this.filters.date.date).minutes(), 'minutes')
      afternoonEndTime = afternoonEndTime.subtract(moment(this.filters.date.date).seconds(), 'seconds')
      afternoonEndTime = afternoonEndTime.add(17, 'hour')
      eveningEndTime = eveningEndTime.subtract(moment(this.filters.date.date).minutes(), 'minutes')
      eveningEndTime = eveningEndTime.subtract(moment(this.filters.date.date).seconds(), 'seconds')
      eveningEndTime = eveningEndTime.add(24, 'hour')
      morningEndTime = moment(morningEndTime).format('X')
      afternoonEndTime = moment(afternoonEndTime).format('X')
      eveningEndTime = moment(eveningEndTime).format('X')
      for (var s = 0; s <= this.allSessions?.length; s++) {
        if (moment(this.allSessions[s]?.endDate) < morningEndTime) {
          this.sessions.morning.push(this.allSessions[s])
        } else if (moment(this.allSessions[s]?.endDate) > morningEndTime && moment(this.allSessions[s]?.endDate) < afternoonEndTime) {
          this.sessions.afternoon.push(this.allSessions[s])
        } else if (moment(this.allSessions[s]?.endDate) > afternoonEndTime && moment(this.allSessions[s]?.endDate) < eveningEndTime) {
          this.sessions.evening.push(this.allSessions[s])
        }
      }
    },
    submitBooking(start, end, index) {
      if (
        this.filteredBoats.bookingBoat == null ||
        this.filteredBoats.bookingBoat == ''
      ) {
        Toastify({
          duration: 2000,
          text: 'Please select a boat',
          newWindow: true,
          style: {
            backgroundColor: '#D32929',
            color: 'white',
            padding: '10px'
          },
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
      } else {
        if (!this.setBooking.index.includes(index)) {
          if (
            this.setBooking.index.length == 0 ||
            this.setBooking.index[0] - 1 == index ||
            this.setBooking.index[this.setBooking.index.length - 1] + 1 == index
          ) {
            this.setBooking.index.push(index)
            this.setBooking.timecodes.push({ startTime: start, endTime: end })
            this.setBooking.times.push(start)
          } else {
            this.setBooking.index = []
            this.setBooking.timecodes = []
            this.setBooking.times = []
            this.setBooking.index.push(index)
            this.setBooking.timecodes.push({ startTime: start, endTime: end })
            this.setBooking.times.push(start)
          }
          document.getElementById(index).clicked = true
          this.bookingSettings.set = true
        } else {
          if (index !== this.setBooking.index[this.setBooking.index.length - 1]) {
            this.setBooking.index = []
            this.setBooking.timecodes = []
            this.setBooking.times = []
            this.setBooking.index.push(index)
            this.setBooking.timecodes.push({ startTime: start, endTime: end })
            this.setBooking.times.push(start)
          } else {
            var deleteIndex = this.setBooking.index.indexOf(index)
            this.setBooking.index.splice(deleteIndex, 1)
            deleteIndex = this.setBooking.timecodes.indexOf(start)
            this.setBooking.timecodes.splice(deleteIndex, 1)
            deleteIndex = this.setBooking.times.indexOf(start)
            this.setBooking.times.splice(deleteIndex, 1)
          }
        }
        this.bookingSettings.start = this.setBooking?.timecodes[0]?.startTime
        var last = this.setBooking.timecodes.length - 1
        this.bookingSettings.end = this.setBooking?.timecodes[last]?.endTime
      }
    }
  },
  watch: {
    isSuccess() {
      this.$router.back()
    },
    '$store.state.bookings.sessions'() {
      this.createSessions()
    },
    boats() {
      if (this.$store.state.inventories.boatsLoaded.message) {
        this.filteredBoats.boats = this.$store.state?.inventories?.boats
        this.isLoaded = true
        this.unix.today = moment(moment()).format('X')
        this.unix.nextWeek = moment(moment().add(6, 'days')).format('X')
      }
      this.filters.date.date = moment().format(
        'YYYY-MM-DD'
      )
    },
    'filters.type'() {
      if (this.filters.type == null) {
        this.filteredBoats.boats = this.$store?.state?.inventories?.boats
      } else {
        this.filteredBoats.boats = this.$store?.state?.inventories?.boats
        this.filteredBoats.boats = this.filteredBoats?.boats?.filter((boat) => {
          return boat.boatType.boatType == this.filters.type
        })
      }
    },
    'filters.date.date'() {
      if (
        this.filteredBoats.bookingBoat !== null &&
        this.filteredBoats.bookingBoat !== '' &&
        this.filteredBoats.bookingBoat !== undefined
      ) {
        this.date.date = moment(this.filters.date.date).format('MMM Do YY')
        this.date.value = moment(this.filters.date.date).format('dddd')
        this.filters.date.date = moment(this.filters.date.date).format(
          'YYYY-MM-DD'
        )
        this.$store.dispatch('bookings/getSessions', {
          id: this.filteredBoats.bookingBoat.id,
          date: this.filters.date.date
        })

        this.createSessions()
      }
    },
    'filteredBoats.bookingBoat'() {
      if (
        this.filteredBoats.bookingBoat !== null &&
        this.filteredBoats.bookingBoat !== '' &&
        this.filteredBoats.bookingBoat !== undefined
      ) {
        this.$store.dispatch('bookings/getBoatBookings', {
          id: this.filteredBoats.bookingBoat.id,
          today: this.unix.today,
          nextWeek: this.unix.nextWeek
        })
        if (
          this.filters.date.date !== null &&
          this.filters.date.date !== '' &&
          this.filters.date.date !== undefined
        ) {
          this.filters.date.date = moment(this.filters.date.date).format(
            'YYYY-MM-DD'
          )
          this.$store.dispatch('bookings/getSessions', {
            id: this.filteredBoats.bookingBoat.id,
            date: this.filters.date.date
          })

          this.createSessions()
        }
      } else {
        Toastify({
          duration: 2000,
          text: 'Please select a boat',
          newWindow: true,
          style: {
            backgroundColor: '#D32929',
            color: 'white',
            padding: '10px'
          },
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
      }
    },
    boatBookings() {
      if (this.boatBookings.length > 0) {
        for (var i = 0; i < this.boatBookings.length; i++) {
          this.boatBookings[i].startHour = moment(
            this.boatBookings[i].startDate
          ).format('h:mm a')
          this.boatBookings[i].endHour = moment(
            this.boatBookings[i].endDate
          ).format('h:mm a')
        }
      }
    },
    boatTypes() {
      this.types = this.boatTypes
    }
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const boats = computed(() => store.state.inventories.boats)
    const allSessions = computed(() => store.state.bookings.sessions)
    const boatBookings = computed(() => store.state.bookings.boatBookings)
    const boatTypes = computed(() => store.state.inventories.boatTypes)
    const role = computed(() => store.state.profile.user?.userRole?.name)
    const selectedBoat = computed(() => store.state.inventories.boatDetail)
    const isSuccess = computed(() => store.state.bookings.addBooking.isSuccess)
    const userId = computed(() => store.state.profile.user?.id)
    var loading = reactive({ value: false })
    onMounted(() => {
      store.dispatch('profile/getProfileData')
      store.dispatch('inventories/getBoatsData')
      store.dispatch('inventories/getBoatTypes')
    })
    const deleteBooking = (bookingId) => {
      store.dispatch('bookings/deleteBooking', bookingId)
    }
    return {
      selectedBoat,
      boatTypes,
      boats,
      boatBookings,
      route,
      allSessions,
      userId,
      deleteBooking,
      loading,
      isSuccess,
      role
    }
  }
}
</script>
