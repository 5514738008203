import axios from 'axios'
export const PROFILE = 'PROFILE'

const state = () => {
  return {
    user: {
    },
    menu: [
      {
        icon: 'HomeIcon',
        pageName: 'side-menu-dashboard-overview-1',
        title: 'Dashboard'
      },
      {
        icon: 'CalendarIcon',
        pageName: 'side-menu-calendar',
        title: 'Calendar'
      },
      {
        icon: 'LifeBuoyIcon',
        pageName: 'side-menu-your-events',
        title: 'Your Events'
      },
      {
        icon: 'FileTextIcon',
        pageName: 'side-menu-inventory',
        title: 'Inventory'
      },
      {
        icon: 'BellIcon',
        pageName: 'side-menu-announcements',
        title: 'Announcements'
      },
      {
        icon: 'UsersIcon',
        pageName: 'side-menu-athletes',
        title: 'Athletes'
      },
      {
        icon: 'ClipboardIcon',
        pageName: 'side-menu-boat-booking',
        title: 'Boat Booking'
      }
    ]
  }
}

// getters
const getters = {
  user: state => {
    return state.user
  },
  menu: state => state.menu
}

// actions
const actions = {
  getProfileData({ commit }, data) {
    commit(PROFILE, data)
  }
}

// mutations
const mutations = {
  [PROFILE](state, data) {
    axios
      .get(process.env.VUE_APP_API_BASE_URL + '/api/user')
      .then(response => {
        if (response.data.success) {
          state.user = response.data.data
        }
        const role = state.user?.userRole?.name
        if (role == 'Coach' || role == 'Super Admin') {
          state.menu = [
            {
              icon: 'HomeIcon',
              pageName: 'side-menu-dashboard-overview-1',
              title: 'Dashboard'
            },
            {
              icon: 'CalendarIcon',
              pageName: 'side-menu-calendar',
              title: 'Calendar'
            },
            {
              icon: 'LifeBuoyIcon',
              pageName: 'side-menu-your-events',
              title: 'Your Events'
            },
            {
              icon: 'FileTextIcon',
              pageName: 'side-menu-inventory',
              title: 'Inventory'
            },
            {
              icon: 'BellIcon',
              pageName: 'side-menu-announcements',
              title: 'Announcements'
            },
            {
              icon: 'UsersIcon',
              pageName: 'side-menu-athletes',
              title: 'Athletes'
            },
            {
              icon: 'ClipboardIcon',
              pageName: 'side-menu-boat-booking',
              title: 'Boat Booking'
            },
            {
              icon: 'CoffeeIcon',
              pageName: 'side-menu-user-groups',
              title: 'User Groups'
            }
          ]
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
