<template>
  <div id="app">
    <div class="w-full px-2 py-2">
      <div class="my-2">
        <span>
          <!-- BEGIN: Content -->
          <div>
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-body px-8 py-6">
                  <span class="inline-flex justify-center mx-2">
                    <router-link :to="{ path: '/athletes' }">
                      <div class="
                          inline-block
                          gap-1
                          border border-1 border-black
                          rounded
                        ">
                        <ArrowLeftIcon />
                      </div>
                      <span class="inline-block align-top mt-1 mx-2">Athletes</span>
                    </router-link>
                  </span>
                  <h2 class="text-xl font-semibold px-2 py-2">
                    Invite New Athlete
                  </h2>
                  <div v-if="role == 'Athlete'" class="p-10">
                    <div class="flex justify-center">
                      <AlertCircleIcon class="w-8 h-8 my-4" />
                    </div>
                    <div class="flex justify-center text-center">
                      You do not have permission to view this page
                    </div>
                  </div>
                  <div v-if="isAvailable">
                    <div class="px-4">
                      <label for="crud-form-1" class="form-label">E-Mail</label>
                      <input v-model="validate.email.$model" id="crud-form-1" type="text" class="form-control w-full"
                        placeholder="register mail" required />
                    </div>
                    <div class="p-4">
                      <label for="crud-form-1" class="form-label">First Name</label>
                      <input v-model="validate.firstName.$model" id="crud-form-1" type="text"
                        class="form-control w-full" placeholder="first name" required />
                    </div>
                    <div class="p-4">
                      <label for="crud-form-1" class="form-label">Last Name</label>
                      <input v-model="validate.lastName.$model" id="crud-form-1" type="text" class="form-control w-full"
                        placeholder="last name" required />
                    </div>
                    <div class="p-4">
                      <label for="crud-form-1" class="form-label">Select User Group</label>
                      <v-select multiple v-model="validate.groups.$model" :reduce="(option) => option?.id"
                        label="groupName" :options="userGroups">
                      </v-select>
                    </div>
                  </div>
                </div>
                <div role="button" class="text-right p-4" @click="disable()" v-if="isAvailable">
                  <button @click="save()" type="text" id="submit" class="btn btn-primary w-24">
                    Save
                    <LoadingIcon v-if="loading.value" icon="oval" class="w-4 h-4 ml-4 text-white" />
                  </button>
                </div>
                <!-- BEGIN: Failed Notification Content -->
                <div id="failed-notification-content" class="toastify-content hidden flex">
                  <XCircleIcon class="text-theme-6" />
                  <div class="ml-4 mr-4">
                    <div class="font-medium">Please fill requirements</div>
                    <div class="text-gray-600 mt-1">Please check the form.</div>
                  </div>
                </div>
                <!-- END: Failed Notification Content -->
              </div>
            </div>
          </div>
          <!-- END: Large Modal Content -->
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, toRefs } from 'vue'
import { useStore } from '@/store'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import Toastify from 'toastify-js'

export default {
  name: 'Invite Athlete',
  methods: {
    disable() {
      if (
        this.formData.email !== '' &&
        this.formData.firstName !== '' &&
        this.formData.lastName !== '' &&
        this.formData.groups.length > 0
      ) {
        this.loading.value = true
        document.getElementById('submit').disabled = true
      }
    }
  },
  watch: {
    isSuccess() {
      if (this.isSuccess) {
        this.loading.value = true
        this.$router.back()
      } else {
        this.loading.value = false
        document.getElementById('submit').disabled = false
      }
    }
  },
  setup() {
    const store = useStore()
    const userGroups = computed(() => store.state.groups.groups)
    var loading = reactive({ value: false })
    const isSuccess = computed(() => store.state.invite.inviteAthlete.isSuccess)
    const role = computed(() => store.state.profile.user?.userRole?.name)
    const isAvailable = computed(() => (store.state.profile.user?.userRole?.name == 'Coach' || store.state.profile.user?.userRole?.name == 'Super Admin'))

    onMounted(() => {
      store.dispatch('groups/getGroupsData')
    })
    const formData = reactive({
      email: '',
      firstName: '',
      lastName: '',
      groups: []
    })
    const rules = {
      email: {
        required
      },
      firstName: {
        required
      },
      lastName: {
        required
      },
      groups: {
        required
      }
    }
    const validate = useVuelidate(rules, toRefs(formData))
    const save = () => {
      validate.value.$touch()
      if (validate.value.$invalid) {
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
      } else {
        store.dispatch('invite/inviteUser', {
          email: formData.email,
          firstName: formData.firstName,
          lastName: formData.lastName,
          groups: formData.groups
        })
      }
    }
    return {
      userGroups,
      validate,
      formData,
      loading,
      isSuccess,
      role,
      isAvailable,
      save
    }
  }
}
</script>
