<template>
  <div id="app">
    <div class="w-full px-2 py-2">
      <div class="my-2">
        <span>
          <!-- BEGIN: Content -->
          <form @submit.prevent="addEvent">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-body px-8 py-6">
                  <span class="inline-flex justify-center">
                    <router-link :to="{ path: '/your-events' }">
                      <div
                        class="inline-block gap-1 border border-1 border-black rounded"
                      >
                        <ArrowLeftIcon />
                      </div>
                      <span class="inline-block mx-2 align-top mt-1"
                        >Your Events</span
                      >
                    </router-link>
                  </span>
                  <!-- BEGIN: Basic Datepicker -->
                  <h2 class="text-xl font-semibold px-1 py-2">
                    Create New Event
                  </h2>
                  <div class="intro-y box p-4">
                    <div v-if="role == 'Athlete'" class="p-10">
                      <div class="flex justify-center">
                        <AlertCircleIcon class="w-8 h-8 my-4" />
                      </div>
                      <div class="flex justify-center text-center">
                        You do not have permission to view this page
                      </div>
                    </div>
                    <div class="p-2" v-if="isAvailable">
                      <div class="w-full flex gap-2 wide">
                        <div class="my-auto">
                          <label
                            for="update-profile-form-1"
                            class="form-label block"
                            >Date</label
                          >
                          <v-date-picker
                            v-model="filters.date.date"
                            mode="date"
                            :masks="filters.masks"
                          >
                            <template v-slot="{ inputValue, inputEvents }">
                              <input
                                class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                                :value="inputValue"
                                v-on="inputEvents"
                              />
                            </template>
                          </v-date-picker>
                        </div>
                        <div class="my-2">
                          <v-date-picker
                            mode="time"
                            v-model="filters.date.startTime"
                            :minute-increment="5"
                            is24hr
                          />
                        </div>
                        <div class="my-2">
                          <v-date-picker
                            mode="time"
                            v-model="filters.date.endTime"
                            :minute-increment="5"
                            is24hr
                          />
                        </div>
                      </div>
                      <div class="w-full gap-2 small">
                        <div class="my-auto">
                          <label
                            for="update-profile-form-1"
                            class="form-label block"
                            >Date</label
                          >
                          <v-date-picker
                            v-model="filters.date.date"
                            mode="date"
                            :masks="filters.masks"
                          >
                            <template v-slot="{ inputValue, inputEvents }">
                              <input
                                class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                                :value="inputValue"
                                v-on="inputEvents"
                              />
                            </template>
                          </v-date-picker>
                        </div>
                        <div>
                          <div class="my-2 mr-2 inline-block">
                            <v-date-picker
                              mode="time"
                              v-model="filters.date.startTime"
                              :minute-increment="5"
                              is24hr
                            />
                          </div>
                          <div class="my-2 mr-2 inline-block">
                            <v-date-picker
                              mode="time"
                              v-model="filters.date.endTime"
                              :minute-increment="5"
                              is24hr
                            />
                          </div>
                        </div>
                      </div>
                      <div class="inline-block mt-2">
                        <div class="mt-2">
                          Recurring Event
                          <input
                            class="form-check-input align-middle mx-1"
                            type="checkbox"
                            v-model="formData.isRecurring"
                          />
                          <div>
                            <span
                              class="block w-44"
                              v-if="formData.isRecurring"
                            >
                              <div class="py-2">Event Interval</div>
                              <v-select
                                v-model="formData.eventInterval"
                                :reduce="(option) => option?.value"
                                :options="[
                                  {
                                    label: 'every day',
                                    value: 'EVERY_DAY'
                                  },
                                  {
                                    label: 'every week',
                                    value: 'EVERY_WEEK'
                                  },
                                  {
                                    label: 'every 2 week',
                                    value: 'EVERY_TWO_WEEKS'
                                  },
                                  {
                                    label: 'every month',
                                    value: 'EVERY_MONTH'
                                  }
                                ]"
                              >
                              </v-select>
                            </span>
                          </div>
                          <div>
                            <span v-if="formData.isRecurring">
                              <div class="py-2">
                                <div id="date" class="preview">
                                  <div class="py-2">Recurring end date</div>
                                  <v-date-picker
                                    v-model="formData.recurringEndDate"
                                    mode="date"
                                  >
                                    <template
                                      v-slot="{ inputValue, inputEvents }"
                                    >
                                      <input
                                        class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                                        :value="inputValue"
                                        v-on="inputEvents"
                                      />
                                    </template>
                                  </v-date-picker>
                                </div>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- END: Basic Datepicker -->
                  <div class="px-4" v-if="isAvailable">
                    <label for="name" class="form-label py-2">Event Name</label>
                    <input
                      v-model="validate.name.$model"
                      id="name"
                      type="text"
                      class="form-control w-full"
                      placeholder="Event Name"
                    />
                  </div>
                  <div class="px-4" v-if="isAvailable">
                    <label for="note" class="form-label py-2">Note</label>
                    <input
                      v-model="formData.note"
                      id="note"
                      type="text"
                      class="form-control w-full"
                      placeholder="Note"
                    />
                  </div>
                  <div class="px-4 py-2" v-if="isAvailable">
                    <label class="form-label py-2">Select user groups</label>
                    <div>
                      <v-select
                        multiple
                        v-model="validate.userGroups.$model"
                        label="groupName"
                        :reduce="(option) => option?.id"
                        :options="userGroups"
                      >
                      </v-select>
                    </div>
                    <span>
                      <div id="header-footer-dropdown">
                        <div class="preview py-2">
                          <label for="dropdown">Type</label>
                          <div class="py-2">
                            <div class="dropdown" data-placement="bottom-start">
                              <div
                                class="dropdown-toggle btn btn-primary"
                                aria-expanded="false"
                              >
                                <span class="flex gap-2 px-1">
                                  <span> Type: {{ formData.type }} </span>
                                  <span>
                                    <ChevronDownIcon class="p-1" />
                                  </span>
                                </span>
                              </div>
                              <div class="dropdown-menu w-56">
                                <div
                                  class="dropdown-menu__content box dark:bg-dark-1"
                                >
                                  <div
                                    class="p-4 border-b border-gray-200 dark:border-dark-5 font-medium"
                                  >
                                    Type
                                  </div>
                                  <div class="r-0 p-2">
                                    <button
                                      @click="formData.type = 'water'"
                                      data-dismiss="dropdown"
                                      class="flex items-center w-full p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                                    >
                                    <img src="../../assets/images/water.png" alt="water icon" class="w-6">
                                    &nbsp;Water
                                    </button>
                                    <button
                                      @click="formData.type = 'ergo'"
                                      data-dismiss="dropdown"
                                      class="flex items-center w-full p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                                    >
                                    <img src="../../assets/images/ergo.png" alt="ergo icon" class="w-6">
                                    &nbsp;&nbsp;Ergo
                                    </button>
                                    <button
                                      @click="formData.type = 'training'"
                                      data-dismiss="dropdown"
                                      class="flex items-center w-full p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                                    >
                                      <SettingsIcon
                                        class="w-4 h-4 text-gray-700 dark:text-gray-300 mr-2 ml-1"
                                      />
                                      Training
                                    </button>
                                    <button
                                      @click="formData.type = 'race'"
                                      data-dismiss="dropdown"
                                      class="flex items-center w-full p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                                    >
                                    <img src="../../assets/images/race.png" alt="race icon" class="w-6">
                                      &nbsp;Race
                                    </button>
                                    <button
                                      @click="formData.type = 'social'"
                                      data-dismiss="dropdown"
                                      class="dropdown-toggle flex items-center w-full p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                                      data-dissmiss="dropdown-menu"
                                    >
                                      <UsersIcon
                                        class="w-4 h-4 text-gray-700 dark:text-gray-300 mr-2 ml-1"
                                      />
                                      Social
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- BEGIN: Failed Notification Content -->
                          <div
                            id="failed-notification-content"
                            class="toastify-content hidden flex"
                          >
                            <XCircleIcon class="text-theme-6" />
                            <div class="ml-4 mr-4">
                              <div class="font-medium">
                                Please fill all requirements
                              </div>
                              <div class="text-gray-600 mt-1">
                                Please check the form.
                              </div>
                            </div>
                          </div>
                          <!-- END: Failed Notification Content -->
                        </div>
                      </div>
                      <div class="hidden lg:block">
                        <div
                          v-if="isAvailable"
                          role="button"
                          class="text-center float-right"
                        >
                          <button
                            type="submit"
                            class="flex justify-center bg-theme-1 w-24 rounded-md text-white"
                            :disabled="disabled"
                          >
                            <input
                              @click="disable()"
                              id="submit"
                              type="submit"
                              value="save"
                              class="flex bg-theme-1 rounded-md p-2 cursor-pointer"
                              :disabled="disabled"
                            />
                          </button>
                        </div>
                        <div class="flex flex-cols">
                          <div class="w-48 mx-4">
                            <div class="py-2">Lock Event</div>
                            <div class="mt-2 h-16">
                              <input
                                id="vertical-form-1"
                                class="form-check-input align-middle"
                                type="checkbox"
                                v-model="isLocked"
                              />
                              <label
                                class="form-check-label"
                                for="vertical-form-1"
                              >
                                <span v-if="isLocked">
                                  <input
                                    v-model="formData.lockBeforeInHours"
                                    id="name"
                                    type="number"
                                    inputmode="numeric"
                                    class="form-control w-20 h-8"
                                  />
                                  hours before
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="block lg:hidden">
                        <div>
                          <div class="w-full mx-4 parent-min">
                            <div class="py-2">Lock Event</div>
                            <div class="mt-2 h-8">
                              <input
                                id="vertical-form-1"
                                class="form-check-input align-middle"
                                type="checkbox"
                                v-model="isLocked"
                              />
                              <label
                                class="form-check-label"
                                for="vertical-form-1"
                              >
                                <span v-if="isLocked">
                                  <input
                                    v-model="formData.lockBeforeInHours"
                                    id="name"
                                    type="number"
                                    inputmode="numeric"
                                    class="form-control w-20 h-8"
                                  />
                                  hours before
                                </span>
                              </label>
                            </div>
                          </div>
                          <div
                            v-if="isAvailable"
                            role="button"
                            class="w-full text-center my-4"
                          >
                            <button
                              type="submit"
                              class="flex justify-center bg-theme-1 w-24 rounded-md text-white"
                              :disabled="disabled"
                            >
                              <input
                                @click="disable()"
                                id="submit"
                                type="submit"
                                value="save"
                                class="flex bg-theme-1 rounded-md p-2 cursor-pointer"
                                :disabled="disabled"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <!-- END: Content -->
        </span>
      </div>
    </div>
  </div>
</template>
<style scoped>
@media screen and (max-width: 400px) {
  .parent-min {
    margin-left: 0;
    margin-right: 0;
  }

  .interval-min {
    width: 125px;
  }

  .recurring-min {
    width: 125px;
  }
}
@media screen and (min-width: 735px) {
  .wide {
    display: flex;
  }
  .small {
    display: none;
  }
}
@media screen and (max-width: 734px) {
  .wide {
    display: none;
  }
  .small {
    display: block;
  }
}
</style>

<script>
import { computed, onMounted, reactive, toRefs } from 'vue'
import { useStore } from '@/store'
// import { useRouter } from 'vue-router'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import Toastify from 'toastify-js'
import moment from 'moment'

export default {
  name: 'add event',
  data() {
    return {
      isLocked: false,
      disabled: false,
      filters: {
        type: '',
        date: { date: '', startTime: '', endTime: '' },
        masks: {
          input: 'YYYY-MM-DD'
        }
      }
    }
  },
  methods: {
    disable() {
      if (
        this.formData.name !== '' &&
        this.formData.userGroups !== '' &&
        this.formData.type !== ''
      ) {
        document.getElementById('submit').disabled = true
      }
    },
    addEvent() {
      this.formData.eventDate.date.start = this.filters.date.startTime
      this.formData.eventDate.date.end = this.filters.date.endTime
      this.createEvent()
    }
  },
  watch: {
    isSuccess() {
      if (this.isSuccess) {
        this.$router.back()
      } else {
        document.getElementById('submit').disabled = false
      }
    },
    'filters.date.date'() {
      this.filters.date.startTime = new Date(
        moment(this.filters.date.date).format('YYYY'),
        moment(this.filters.date.date).month(),
        moment(this.filters.date.date).format('DD')
      )
      this.filters.date.endTime = new Date(
        moment(this.filters.date.date).format('YYYY'),
        moment(this.filters.date.date).month(),
        moment(this.filters.date.date).format('DD')
      )
    }
  },
  setup() {
    const store = useStore()
    // const router = useRouter()
    const isSuccess = computed(() => store.state.events.addEventForm.isSuccess)
    const users = computed(() => store.state.athletes.athletes)
    const userGroups = computed(() => store.state.groups.groups)
    const role = computed(() => store.state.profile.user?.userRole?.name)
    const isAvailable = computed(
      () =>
        store.state.profile.user?.userRole?.name == 'Coach' ||
        store.state.profile.user?.userRole?.name == 'Super Admin'
    )

    onMounted(() => {
      store.dispatch('athletes/getAthletesData')
      store.dispatch('groups/getGroupsData')
      store.dispatch('profile/getProfileData')
    })
    const formData = reactive({
      type: '',
      eventDate: {
        type: '',
        date: { start: '', end: '' },
        masks: {
          input: 'YYYY-MM-DD h:mm A'
        }
      },
      name: '',
      note: '',
      userGroups: '',
      lockBeforeInHours: 24,
      isRecurring: false,
      eventInterval: '',
      recurringEndDate: ''
    })
    const rules = {
      name: {
        required
      },
      userGroups: {
        required
      },
      type: {
        required
      }
    }

    const validate = useVuelidate(rules, toRefs(formData))
    const createEvent = () => {
      formData.recurringEndDate = moment(formData.recurringEndDate).unix()
      validate.value.$touch()
      if (validate.value.$invalid) {
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
      } else {
        store.dispatch('events/addEvent', formData)
      }
    }
    return {
      validate,
      formData,
      userGroups,
      isSuccess,
      users,
      createEvent,
      role,
      isAvailable
    }
  }
}
</script>
