<template>
  <div>
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="" class="text-3xl -intro-x flex items-center pt-5">
            <img
              alt="Rowerhub"
              class="-intro-x w-40"
              :src="require(`@/assets/images/rowerhub-dark-logo.png`)"
            />
          </a>
          <div class="my-auto">
            <img
              alt="Rowerhub"
              class="-intro-x w-1/2 -mt-16"
              :src="require(`@/assets/images/illustration.svg`)"
            />
            <div
              class="
                -intro-x
                text-white
                font-medium
                text-4xl
                leading-tight
                mt-10
              "
            >
              A few more clicks to <br />
              sign in to your Rowerhub account.
            </div>
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto xl:py-0 xl:my-0 xl:flex">
          <div class="flex justify-center my-5 xl:hidden w-full">
            <a href="" class="text-3xl -intro-x flex items-center pt-5">
              <img
                alt="Rowerhub"
                class="-intro-x w-40"
                :src="require(`@/assets/images/rowerhub-dark-logo.png`)"
              />
            </a>
          </div>
          <div
            class="
              my-auto
              mx-auto
              xl:ml-20
              bg-white
              dark:bg-dark-1
              xl:bg-transparent
              px-5
              sm:px-8
              py-8
              xl:p-0
              rounded-md
              shadow-md
              xl:shadow-none
              w-full
              sm:w-3/4
              lg:w-2/4
              xl:w-auto
            "
          >
            <h2
              class="
                intro-x
                font-bold
                text-2xl
                xl:text-3xl
                text-center
                xl:text-left
              "
            >
              Sign In
            </h2>
            <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">
              A few more clicks to sign in to your Rowerhub account.
            </div>
            <!-- Login Errors -->
            <div
              class="alert alert-danger show mb-2 mt-4"
              role="alert"
              id="login-errors"
              v-if="loginErrorMessage"
            >
              {{ loginErrorMessage }}
            </div>
            <div class="intro-x mt-8">
              <input
                type="text"
                class="
                  intro-x
                  login__input
                  form-control
                  py-3
                  px-4
                  border-gray-300
                  block
                "
                placeholder="Email"
                v-model.trim="loginValidate.email.$model"
              />
              <input
                type="password"
                class="
                  intro-x
                  login__input
                  form-control
                  py-3
                  px-4
                  border-gray-300
                  block
                  mt-4
                "
                placeholder="Password"
                @keydown.enter.prevent="submit"
                v-model.trim="loginValidate.password.$model"
              />
            </div>
            <div
              class="
                intro-x
                flex
                text-gray-700
                dark:text-gray-600
                text-xs
                sm:text-sm
                mt-4
              "
            >
              <div class="flex items-center mr-auto">
                <input
                  id="remember-me"
                  type="checkbox"
                  class="form-check-input border mr-2"
                />
                <label class="cursor-pointer select-none" for="remember-me"
                  >Remember me</label
                >
              </div>
              <router-link :to="{ name: 'forgot-password' }"
                >Forgot Password?</router-link
              >
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button
                v-on:click="submit()"
                class="
                  btn btn-primary
                  py-3
                  px-4
                  w-full
                  xl:w-32 xl:mr-3
                  mt-4
                  align-top
                "
              >
                Login
              </button>
              <router-link
                :to="{ name: 'register' }"
                tag="button"
                class="
                  btn btn-outline-secondary
                  py-3
                  px-4
                  w-full
                  mt-4
                  xl:w-32 xl:mr-2
                  align-top
                "
              >
                Sign Up
              </router-link>
            </div>
            <div
              class="
                intro-x
                mt-10
                xl:mt-24
                text-gray-700
                dark:text-gray-600
                text-center
                xl:text-left
              "
            >
              By signin up, you agree to our <br />
              <a
                class="text-theme-1 dark:text-theme-10"
                href="https://rowerhub.com/privacy-policy/"
                >Privacy Policy</a
              >
            </div>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, computed } from 'vue'
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { useStore } from '@/store'

export default {
  setup() {
    const store = useStore()
    const loginErrorMessage = computed(
      () => store.state.auth.user.loginErrorMessage
    )

    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    })

    const formData = reactive({
      email: '',
      password: ''
    })

    const rules = {
      email: {
        required
      },
      password: {
        required
      }
    }

    const loginValidate = useVuelidate(rules, toRefs(formData))

    const submit = () => {
      store.dispatch('auth/setLoggedIn', formData)
    }

    return {
      loginValidate,
      loginErrorMessage,
      submit
    }
  }
}
</script>
