import axios from 'axios'
export const CLUBS = 'CLUBS'

const state = () => {
  return {
    clubs: {
    }
  }
}

// getters
const getters = {
  clubs: state => {
    return state.clubs
  }
}

// actions
const actions = {
  getClubsData({ commit }, data) {
    commit(CLUBS, data)
  }
}

// mutations
const mutations = {
  [CLUBS](state, data) {
    axios
      .get(process.env.VUE_APP_API_BASE_URL + '/api/club')
      .then(response => {
        if (response.data.success) {
          state.clubs = response.data.data
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
