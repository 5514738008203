import Toastify from 'toastify-js'
import axios from 'axios'
import moment from 'moment'
import { useStore } from '@/store'
export const ANNOUNCEMENTS = 'ANNOUNCEMENTS'
export const CREATE_ANNOUNCEMENTS = 'CREATE_ANNOUNCEMENTS'

const state = () => {
  return {
    announcements: [],
    announcementsLoaded: false,
    isLoadMoreActive: false,
    isLoadMore: true,
    createAnnouncements: null
  }
}

// getters
const getters = {
  announcements: state => {
    return state.announcements
  }
}

// actions
const actions = {
  getAnnouncements({ commit }, data) {
    commit(ANNOUNCEMENTS, data)
  },
  createAnnouncements({ commit }, formData) {
    commit(CREATE_ANNOUNCEMENTS, formData)
  }
}

// mutations
const mutations = {
  [ANNOUNCEMENTS](state, data) {
    if (data.offset > 0) {
      state.isLoadMoreActive = true
    } else {
      state.announcementsLoaded = false
    }
    axios
      .get(process.env.VUE_APP_API_BASE_URL + '/api/my-announcements', {
        params: { offset: data.offset }
      })
      .then(response => {
        if (response.status === 200) {
          const formattedAnnouncements = response.data.map(announcement => {
            announcement.createdAt = moment(
              announcement.createdAt * 1000
            ).format('dddd MMM Do YY, h:mm a')

            return announcement
          })
          if (data.func == 'loadMore') {
            state.announcements.push(...formattedAnnouncements)
          } else if (data.func == 'mounted') {
            state.isLoadMore = true
            data.offset = 0
            state.announcements = []
            state.announcements.push(...formattedAnnouncements)
          }
          state.announcementsLoaded = true
          state.isLoadMoreActive = false
          state.createAnnouncements = null
          if (formattedAnnouncements.length < 10) {
            state.isLoadMore = false
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
  [CREATE_ANNOUNCEMENTS](state, formData) {
    state.createAnnouncements = false
    const store = useStore()
    var selectedUserGroups = []
    if (formData.userGroups[0] !== 'send all') {
      for (var i = 0; i < formData.userGroups.length; i++) {
        selectedUserGroups.push({ id: formData.userGroups[i] })
      }
    } else {
      store.dispatch('groups/getGroupsData')
      for (var k = 1; k < store.state.groups.groups.length; k++) {
        selectedUserGroups.push({ id: store.state.groups.groups[k].id })
      }
    }
    axios
      .post(process.env.VUE_APP_API_BASE_URL + '/api/announcement', {
        subject: formData.subject,
        content: formData.content,
        userGroups: selectedUserGroups
      })
      .then(response => {
        if (response.data.message) {
          state.createAnnouncements = true
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#91C714',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        } else {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#D32929',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
