<template>
  <div id="app">
    <div class="px-4 py-2">
      <label for="crud-form-name" class="form-label"
        >Name <span class="text-theme-6">*</span></label
      >
      <input
        v-model="validateErgos.name.$model"
        id="crud-form-name"
        type="text"
        class="form-control w-full"
        placeholder="Name"
      />
    </div>
    <div class="px-4 py-2">
      <label for="crud-form-code" class="form-label"
        >Code <span class="text-theme-6">*</span></label
      >
      <input
        v-model="validateErgos.code.$model"
        id="crud-form-code"
        type="text"
        class="form-control w-full"
        placeholder="Code"
      />
    </div>
    <div class="px-4 py-2">
      <label for="crud-form-status" class="form-label">Status</label>
      <v-select
        id="crud-form-status"
        v-model="formDataErgos.status"
        :reduce="(option) => option?.value"
        :options="[
          { label: 'in use', value: true },
          { label: 'out of use', value: false }
        ]"
      >
      </v-select>
    </div>
    <div class="px-4 py-2">
      <label for="crud-form-erg-note" class="form-label">Note</label>
      <input
        v-model="formDataErgos.note"
        id="crud-form-erg-note"
        type="text"
        class="form-control w-full"
      />
    </div>
    <div class="font-light text-theme-6 px-4 py-2">
      <p>Fields marked with '*' are required</p>
    </div>
    <div v-if="isAvailable" role="button" class="text-right p-4">
      <button
        @click="addErgos()"
        type="submit"
        id="submit"
        class="btn btn-primary w-24"
      >
        Save
        <LoadingIcon
          v-if="loading.value"
          icon="oval"
          class="w-4 h-4 ml-4 text-white"
        />
      </button>
    </div>
    <!-- BEGIN: Failed Notification Content -->
    <div id="failed-notification-content" class="toastify-content hidden flex">
      <XCircleIcon class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div class="font-medium">Please fill requirements</div>
        <div class="text-gray-600 mt-1">Please check the form.</div>
      </div>
    </div>
    <!-- END: Failed Notification Content -->
  </div>
</template>

<script>
import { computed, onMounted, reactive, toRefs } from 'vue'
import { useStore } from '@/store'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import Toastify from 'toastify-js'

export default {
  name: 'add inventory - ergos',
  data() {
    return {
      type: 'ergos'
    }
  },
  props: {
    isAvailable: Boolean
  },
  methods: {
    disable() {
      if (this.formData.boatName !== '' && this.formData.boatType !== '') {
        this.loading.value = true
        document.getElementById('submit').disabled = true
      }
    }
  },
  setup() {
    const store = useStore()
    const role = computed(() => store.state.profile.user?.userRole?.name)
    var loading = reactive({ value: false })
    onMounted(() => {
      store.dispatch('profile/getProfileData')
    })
    const formDataErgos = reactive({
      name: '',
      code: '',
      status: 'in use',
      note: ''
    })
    const rulesOfErgos = {
      name: {
        required
      },
      code: {
        required
      }
    }
    const validateErgos = useVuelidate(rulesOfErgos, toRefs(formDataErgos))
    const addErgos = () => {
      validateErgos.value.$touch()
      if (validateErgos.value.$invalid) {
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
      } else {
        store.dispatch('inventories/addErgo', formDataErgos)
      }
    }
    return {
      formDataErgos,
      validateErgos,
      loading,
      addErgos,
      role
    }
  }
}
</script>
