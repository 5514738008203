<template>
  <div id="app">
    <div class="w-full px-2 py-2">
      <div class="my-2">
        <span>
          <!-- BEGIN: Content -->
          <div>
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-body px-8 py-6">
                  <label class="form-label text-lg font-bold">
                    <span
                      class="inline-flex justify-center border border-1 border-black rounded"
                    >
                      <router-link :to="{ path: '/inventory' }">
                        <div class="flex gap-1">
                          <ArrowLeftIcon />
                        </div>
                      </router-link>
                    </span>
                    Add New Inventory</label
                  >
                  <div v-if="isAvailable" class="my-4">
                    <span
                      v-on:click="this.type = 'boats'"
                      :class="
                        type === 'boats'
                          ? 'text-theme-1 border-b-2 border-theme-1'
                          : 'text-theme-16 border-b-2 border-transparent hover:border-theme-3 hover:text-theme-3 cursor-pointer'
                      "
                      class="inline-block px-4 pb-2 text-sm font-medium"
                      >Boats</span
                    >
                    <span
                      v-on:click="this.type = 'ergos'"
                      :class="
                        type === 'ergos'
                          ? 'text-theme-1 border-b-2 border-theme-1'
                          : 'text-theme-16 border-b-2 border-transparent hover:border-theme-3 hover:text-theme-3 cursor-pointer'
                      "
                      class="inline-block px-4 pb-2 text-sm font-medium"
                      >Ergos</span
                    >
                    <span
                      v-on:click="this.type = 'others'"
                      :class="
                        type === 'others'
                          ? 'text-theme-1 border-b-2 border-theme-1'
                          : 'text-theme-16 border-b-2 border-transparent hover:border-theme-3 hover:text-theme-3 cursor-pointer'
                      "
                      class="inline-block px-4 pb-2 text-sm font-medium"
                      >Others</span
                    >
                  </div>
                  <div v-if="role == 'Athlete'" class="p-10">
                    <div class="flex justify-center">
                      <AlertCircleIcon class="w-8 h-8 my-4" />
                    </div>
                    <div class="flex justify-center text-center">
                      You do not have permission to view this page
                    </div>
                  </div>
                  <div v-if="isAvailable">
                    <div v-if="this.type == 'boats'">
                      <AddBoat
                        :boatTypes="boatTypes"
                        :athletes="athletes"
                        :isAvailable="isAvailable"
                      />
                    </div>
                  </div>
                  <div v-if="this.type == 'ergos'">
                    <AddErgo :isAvailable="isAvailable" />
                  </div>
                  <div v-if="this.type == 'others'">
                    <AddOtherTypeofBoat
                      :isAvailable="isAvailable"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END: Large Modal Content -->
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive } from 'vue'
import { useStore } from '@/store'
import AddBoat from './AddBoat.vue'
import AddErgo from './AddErgo.vue'
import AddOtherTypeofBoat from './AddOtherTypeofBoat.vue'

export default {
  name: 'add inventory',
  data() {
    return {
      type: 'boats'
    }
  },
  components: { AddBoat, AddErgo, AddOtherTypeofBoat },
  watch: {
    isSuccess() {
      if (this.isSuccess) {
        this.loading.value = true
        this.$router.back()
      } else {
        this.loading.value = false
        document.getElementById('submit').disabled = false
      }
    }
  },
  setup() {
    const store = useStore()
    const athletes = computed(() => store.state.athletes.athletes)
    const role = computed(() => store.state.profile.user?.userRole?.name)
    const boatTypes = computed(() => store.state?.inventories?.boatTypes)
    var loading = reactive({ value: false })
    const isSuccess = computed(() => store.state.inventories.addBoat.isSuccess)
    const isAvailable = computed(
      () =>
        store.state.profile.user?.userRole?.name == 'Coach' ||
        store.state.profile.user?.userRole?.name == 'Super Admin'
    )
    onMounted(() => {
      store.dispatch('athletes/getAthletesData')
      store.dispatch('profile/getProfileData')
      store.dispatch('inventories/getBoatTypes')
    })
    return {
      athletes,
      boatTypes,
      loading,
      isSuccess,
      role,
      isAvailable
    }
  }
}
</script>
