<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Update Profile</h2>
    </div>
    <div class="grid grid-cols-12 gap-6">
      <!-- BEGIN: Profile Menu -->
      <div
        class="
          col-span-12
          lg:col-span-4
          2xl:col-span-3
          flex
          lg:block
          flex-col-reverse
        "
      >
        <div class="intro-y box mt-5">
          <div class="relative flex items-center p-5">
            <div
              class="
                w-12
                h-12
                rounded-full
                bg-theme-1
                text-white text-2xl text-center
                image-fit
              "
            >
              <p class="my-2">
                {{ user?.firstName?.slice(0, 1)
                }}{{ user?.lastName?.slice(0, 1) }}
              </p>
            </div>
            <div class="ml-4 mr-auto">
              <div class="font-medium text-base">
                {{ fullName }}
              </div>
              <div class="text-gray-600">{{ clubName }}</div>
            </div>
          </div>
          <div class="p-5 border-t border-gray-200 dark:border-dark-5">
            <span
              class="
                flex
                items-center
                text-theme-1
                dark:text-theme-10
                font-medium
              "
            >
              <ActivityIcon class="w-4 h-4 mr-2" /> Personal Information
            </span>
            <a class="flex items-center mt-5" href="">
              <LockIcon class="w-4 h-4 mr-2" /> Change Password
            </a>
          </div>
        </div>
      </div>
      <!-- END: Profile Menu -->
      <div class="col-span-12 lg:col-span-8 2xl:col-span-9">
        <!-- BEGIN: Display Information -->
        <div class="intro-y box lg:mt-5">
          <div
            class="
              flex
              items-center
              p-5
              border-b border-gray-200
              dark:border-dark-5
            "
          >
            <h2 class="font-medium text-base mr-auto">Display Information</h2>
          </div>
          <div class="px-5">
            <div class="flex xl:flex-row flex-col">
              <div class="flex-1 mt-6 xl:mt-0">
                <div class="grid grid-cols-12 gap-x-5">
                  <div class="col-span-12 2xl:col-span-6">
                    <div>
                      <label for="update-profile-form-1" class="form-label"
                        >Display Name</label
                      >
                      <input
                        id="update-profile-form-1"
                        type="text"
                        class="form-control"
                        placeholder="Input text"
                        :value="fullName"
                        disabled
                      />
                    </div>
                    <div class="mt-3">
                      <label for="update-profile-form-2" class="form-label"
                        >Club</label
                      >
                      <TomSelect
                        id="update-profile-form-2"
                        v-model="select"
                        class="w-full"
                        disabled
                      >
                        <option value="1">{{ clubName }}</option>
                      </TomSelect>
                    </div>
                    <div class="mt-3">
                      <label for="update-profile-form-2" class="form-label"
                        >User Groups</label
                      >
                      <div
                        v-for="group in userGroups"
                        :key="group.id"
                        class="mt-3"
                      >
                        <span
                          class="border text-gray-500 rounded-md w-full p-2"
                        >
                          {{ group.groupName }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-span-12 2xl:col-span-6 mt-3">
                    <div class="my-2">
                      <label for="update-profile-form-1" class="form-label"
                        >Postal Code</label
                      >
                      <input
                        v-model="formData.zipCode"
                        id="update-profile-form-1"
                        type="text"
                        class="form-control"
                        placeholder="Input text"
                      />
                    </div>
                    <div class="mt-3">
                      <label for="update-profile-form-4" class="form-label"
                        >Phone Number</label
                      >
                      <input
                        v-model="formData.phoneNumber"
                        id="update-profile-form-4"
                        type="text"
                        class="form-control"
                        placeholder="Input number"
                      />
                    </div>
                    <div class="mt-3">
                      <label for="update-profile-form-4" class="form-label"
                        >Emergency Phone Number</label
                      >
                      <input
                        v-model="formData.emergencyNumber"
                        id="update-profile-form-4"
                        type="text"
                        class="form-control"
                        placeholder="Input text"
                      />
                    </div>
                    <div class="mt-3">
                      <label for="update-profile-form-4" class="form-label"
                        >Emergency Contact Name</label
                      >
                      <input
                        id="update-profile-form-4"
                        type="text"
                        class="form-control"
                        placeholder="Input text"
                        v-model="formData.emergencyName"
                      />
                    </div>
                  </div>
                  <div class="col-span-12">
                    <div class="mt-3">
                      <label for="update-profile-form-5" class="form-label"
                        >Address</label
                      >
                      <textarea
                        v-model="formData.address"
                        id="update-profile-form-5"
                        class="form-control"
                        placeholder="Adress"
                      >
                      </textarea>
                    </div>
                  </div>
                </div>
                <button
                  @click="updateInformations()"
                  type="button"
                  class="btn btn-primary w-20 my-4"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Display Information -->
        <!-- BEGIN: Personal Information -->
        <div class="intro-y box mt-5">
          <div
            class="
              flex
              items-center
              p-5
              border-b border-gray-200
              dark:border-dark-5
            "
          >
            <h2 class="font-medium text-base mr-auto">Personal Information</h2>
          </div>
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-6">
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Email</label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="Input text"
                    :value="formData.email"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="flex justify-end mt-4">
              <button type="button" class="btn btn-primary w-20 mr-auto">
                Save
              </button>
              <a href="" class="text-theme-6 flex items-center">
                <Trash2Icon class="w-4 h-4 mr-1" /> Delete Account
              </a>
            </div>
          </div>
        </div>
        <!-- END: Personal Information -->
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, onMounted, ref } from 'vue'
import { useStore } from '@/store'

export default {
  data: function () {
    return {
      profile: ''
    }
  },
  watch: {
    user() {
      this.formData.zipCode = this.$store.state.profile.user?.zipCode
      this.formData.phoneNumber = this.$store.state.profile.user?.phoneNumber
      this.formData.emergencyNumber = this.$store.state.profile.user?.emergencyNumber
      this.formData.emergencyName = this.$store.state.profile.user?.emergencyName
      this.formData.address = this.$store.state.profile.user?.address
      this.formData.email = this.$store.state.profile.user?.userEmail
    }
  },
  setup() {
    const select = ref('1')
    const store = useStore()
    const logout = () => {
      store.dispatch('auth/loggedOut')
    }
    const fullName = computed(
      () =>
        store.state.profile.user?.firstName +
        ' ' +
        store.state.profile.user?.lastName
    )
    const clubName = computed(() => store.state.profile.user?.club.name)
    const user = computed(() => store.state.profile.user)
    const userGroups = computed(() => store.state.profile.user?.userGroups)
    onMounted(() => {
      store.dispatch('profile/getProfileData')
    })
    const formData = reactive({
      zipCode: '',
      phoneNumber: store.state.profile.user?.phoneNumber,
      emergencyNumber: store.state.profile.user?.emergencyNumber,
      emergencyName: store.state.profile.user?.emergencyName,
      address: store.state.profile.user?.address,
      email: store.state.profile.user?.userEmail
    })
    const updateInformations = () => {
      const params = formData
      store.dispatch('profile/updateInformations', params)
    }
    return {
      logout,
      formData,
      updateInformations,
      fullName,
      clubName,
      userGroups,
      select,
      user
    }
  }
}
</script>
