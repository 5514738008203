<template>
  <div class="px-2 py-2" v-if="isAvailable">
    <div class="text-xl font-bold p-2">Announcements</div>
    <label class="p-4" for="to">Send To</label>
    <div class="p-2">
      <!-- BEGIN: Multiple Select -->
      <div class="intro-y">
        <div id="multiple-select">
          <div>
            <v-select multiple v-model="validate.userGroups.$model" id="groups" class="bg-white"
              :reduce="(option) => option?.id" label="groupName" :options="this.sendingOptions">
            </v-select>
          </div>
        </div>
      </div>
      <!-- END: Multiple Select -->
    </div>
    <div class="px-2">
      <textarea class="w-full form-control" rows="1" placeholder="Subject" v-model="validate.subject.$model"></textarea>
    </div>
    <div class="px-2">
      <textarea class="w-full form-control" rows="12" placeholder="Write your message!"
        v-model="validate.content.$model"></textarea>
    </div>
    <div class="p-2 float-right">
      <button type="submit" @click="createAnnouncements()" id="send" class="btn bg-theme-9 text-white">
        <span class="px-4">Send</span>
      </button>
    </div>
    <hr class="my-6" />
  </div>
  <!-- BEGIN: Failed Notification Content -->
  <div id="failed-notification-content" class="toastify-content hidden flex">
    <XCircleIcon class="text-theme-6" />
    <div class="ml-4 mr-4">
      <div class="font-medium">Please fill all fields</div>
      <div class="text-gray-600 mt-1">Please check the form.</div>
    </div>
  </div>
  <!-- END: Failed Notification Content -->
</template>

<script>
import { onMounted, computed, reactive, toRefs, ref } from 'vue'
import { useStore } from '@/store'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import Toastify from 'toastify-js'

export default {
  name: 'CreateAnnouncement',
  props: {
    isAvailable: Boolean
  },
  data() {
    return {
      isLoaded: false,
      profile: ''
    }
  },
  watch: {
    fullName() {
      this.profile =
        this.$store.state.profile.user?.firstName.slice(0, 1) +
        this.$store.state.profile.user?.lastName.slice(0, 1)
    },
    sendingOptions() {
      this.sendingOptions.unshift({ groupName: 'Send All', id: 'send all' })
    },
    isSuccess () {
      if (this.isSuccess) {
        this.$store.dispatch('announcements/getAnnouncements', { offset: this?.offset ?? 0, func: 'mounted' })
        document.getElementById('send').disabled = false
      }
    }
  },
  setup() {
    const store = useStore()
    const offset = ref(0)
    const userGroups = computed(() => store.state.groups.groups)
    const sendingOptions = computed(() => store.state.groups.groups)
    const role = computed(() => store.state.profile.user?.userRole?.name)
    const isSuccess = computed(() => store.state.announcements.createAnnouncements)

    const fullName = computed(
      () =>
        store.state.profile.user?.firstName +
        ' ' +
        store.state.profile.user?.lastName
    )
    const clubName = computed(() => store.state.profile.user?.club.name)
    onMounted(() => {
      store.dispatch('groups/getGroupsData')
    })
    const formData = reactive({
      userGroups: [],
      subject: '',
      content: ''
    })
    const rules = {
      userGroups: {
        required
      },
      subject: {
        required
      },
      content: {
        required
      }
    }
    const validate = useVuelidate(rules, toRefs(formData))
    const createAnnouncements = (userGroups) => {
      validate.value.$touch()
      if (validate.value.$invalid) {
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
      } else {
        store.dispatch('announcements/createAnnouncements', formData)
      }
      document.getElementById('send').disabled = true
      formData.userGroups = []
      formData.subject = ''
      formData.content = ''
    }
    return {
      userGroups,
      sendingOptions,
      clubName,
      formData,
      offset,
      validate,
      fullName,
      createAnnouncements,
      isSuccess,
      role
    }
  }
}
</script>
