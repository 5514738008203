<template>
  <div id="app">
    <div class="w-full px-2 py-2">
      <div>
        <span class="inline-flex justify-center mx-2">
          <router-link :to="{ path: '/athletes' }">
            <div
              class="inline-block gap-1 border border-1 border-black rounded"
            >
              <ArrowLeftIcon />
            </div>
            <span class="inline-block mx-2 align-top mt-1">Athletes</span>
          </router-link>
        </span>
      </div>
      <div class="col-span-12 lg:col-span-8 2xl:col-span-9">
        <!-- BEGIN: Display Information -->
        <div class="intro-y box lg:mt-5">
          <div
            class="
              flex
              items-center
              p-5
              border-b border-gray-200
              dark:border-dark-5
            "
          >
            <h2 class="font-medium text-base mr-auto">
              {{ fullName }}'s Details
            </h2>
          </div>
          <div class="px-5">
            <div class="flex xl:flex-row flex-col">
              <div class="flex-1 mt-6 xl:mt-0">
                <div class="grid grid-cols-12 gap-x-5">
                  <div class="col-span-12 2xl:col-span-6">
                    <div>
                      <label for="update-profile-form-1" class="form-label mt-5"
                        >Display Name</label
                      >
                      <!-- START: loading animation -->
                      <div v-if="!isLoaded">
                        <p class="bg-theme-8 px-2 w-full h-8 blob"></p>
                      </div>
                      <!-- END: loading animation -->
                      <input
                        v-if="isLoaded"
                        id="update-profile-form-1"
                        type="text"
                        class="form-control"
                        placeholder="Input text"
                        :value="fullName"
                        :disabled="isFieldDisabled"
                      />
                    </div>
                    <div class="mt-3">
                      <label for="update-profile-form-2" class="form-label"
                        >Club</label
                      >
                      <!-- START: loading animation -->
                      <div v-if="!isLoaded">
                        <p class="bg-theme-8 px-2 w-full h-8 blob"></p>
                      </div>
                      <!-- END: loading animation -->
                      <v-select
                        v-if="isLoaded"
                        v-model="clubName"
                        label="clubName"
                        disabled
                      >
                      </v-select>
                    </div>
                    <div class="mt-3">
                      <label for="update-profile-form-2" class="form-label"
                        >User Groups</label
                      >
                      <!-- START: loading animation -->
                      <div v-if="!isLoaded">
                        <p class="bg-theme-8 px-2 w-full h-8 blob"></p>
                      </div>
                      <!-- END: loading animation -->
                      <a href="/user-groups/" v-if="isAvailable">
                        <div
                          v-for="group in userGroups"
                          :key="group.id"
                          class="mt-3"
                        >
                          <span
                            v-if="isLoaded"
                            class="border text-gray-500 rounded-md w-full p-2"
                          >
                            {{ group.groupName }}</span
                          >
                        </div>
                      </a>
                      <div v-if="role == 'Athlete'">
                        <div
                          v-for="group in userGroups"
                          :key="group.id"
                          class="mt-3"
                        >
                          <span
                            v-if="isLoaded"
                            class="border text-gray-500 rounded-md w-full p-2"
                          >
                            {{ group.groupName }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="block mt-4" v-if="isAvailable">
                      <label for="update-profile-form-2" class="form-label"
                        >Role</label
                      >
                      <span v-if="isLoaded">
                        <div class="intro-y">
                          <div id="select" class="flex">
                            <div class="select">
                              <!-- START: loading animation -->
                              <div v-if="!isLoaded">
                                <p class="bg-theme-8 px-2 w-full h-8 blob"></p>
                              </div>
                              <!-- END: loading animation -->
                              <v-select
                                v-model="athleteDetail.userRole.code"
                                :reduce="(option) => option?.code"
                                id="groups"
                                label="role"
                                :options="[{role: 'Athlete', code: 'ROLE_ATHLETE'}, {role: 'Coach', code: 'ROLE_COACH'}]"
                              >
                              </v-select>
                            </div>
                            <div>
                              <button
                                @click="updateRole()"
                                class="btn btn-primary mx-2"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div class="col-span-12 2xl:col-span-6 mt-3">
                    <div class="my-2">
                      <label for="update-profile-form-1" class="form-label"
                        >Postal Code</label
                      >
                      <!-- START: loading animation -->
                      <div v-if="!isLoaded">
                        <p class="bg-theme-8 px-2 w-full h-8 blob"></p>
                      </div>
                      <!-- END: loading animation -->
                      <input
                        v-if="isLoaded"
                        v-model="formData.zipCode"
                        id="update-profile-form-1"
                        type="text"
                        class="form-control"
                        placeholder="Input text"
                        :disabled="isFieldDisabled"
                      />
                    </div>
                    <div class="mt-3">
                      <label for="update-profile-form-4" class="form-label"
                        >Phone Number</label
                      >
                      <!-- START: loading animation -->
                      <div v-if="!isLoaded">
                        <p class="bg-theme-8 px-2 w-full h-8 blob"></p>
                      </div>
                      <!-- END: loading animation -->
                      <input
                        v-if="isLoaded"
                        v-model="formData.phoneNumber"
                        id="update-profile-form-4"
                        type="text"
                        class="form-control"
                        placeholder="Input number"
                        :disabled="isFieldDisabled"
                      />
                    </div>
                    <div class="mt-3">
                      <label for="update-profile-form-4" class="form-label"
                        >Emergency Phone Number</label
                      >
                      <!-- START: loading animation -->
                      <div v-if="!isLoaded">
                        <p class="bg-theme-8 px-2 w-full h-8 blob"></p>
                      </div>
                      <!-- END: loading animation -->
                      <input
                        v-if="isLoaded"
                        v-model="formData.emergencyNumber"
                        id="update-profile-form-4"
                        type="text"
                        class="form-control"
                        placeholder="Input text"
                        :disabled="isFieldDisabled"
                      />
                    </div>
                    <div class="mt-3">
                      <label for="update-profile-form-4" class="form-label"
                        >Emergency Contact Name</label
                      >
                      <!-- START: loading animation -->
                      <div v-if="!isLoaded">
                        <p class="bg-theme-8 px-2 w-full h-8 blob"></p>
                      </div>
                      <!-- END: loading animation -->
                      <input
                        v-if="isLoaded"
                        id="update-profile-form-4"
                        type="text"
                        class="form-control"
                        placeholder="Input text"
                        v-model="formData.emergencyName"
                        :disabled="isFieldDisabled"
                      />
                    </div>
                  </div>
                  <div class="col-span-12">
                    <div class="mt-3">
                      <label for="update-profile-form-5" class="form-label"
                        >Address</label
                      >
                      <!-- START: loading animation -->
                      <div v-if="!isLoaded">
                        <p class="bg-theme-8 px-2 w-full h-8 blob my-4"></p>
                      </div>
                      <!-- END: loading animation -->
                      <textarea
                        v-if="isLoaded"
                        v-model="formData.address"
                        id="update-profile-form-5"
                        class="form-control mb-2"
                        placeholder="Adress"
                        :disabled="isFieldDisabled"
                      >
                      </textarea>
                    </div>
                  </div>
                </div>
                <button
                  v-if="isAvailable"
                  @click="updateInformations()"
                  type="button"
                  class="btn btn-primary w-20 mb-2"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Display Information -->
        <!-- BEGIN: Personal Information -->
        <div class="intro-y box mt-5">
          <div
            class="
              flex
              items-center
              p-5
              border-b border-gray-200
              dark:border-dark-5
            "
          >
            <h2 class="font-medium text-base mr-auto">Personal Information</h2>
          </div>
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-6">
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Email</label
                  >
                  <!-- START: loading animation -->
                  <div v-if="!isLoaded">
                    <p class="bg-theme-8 px-2 w-full h-8 blob"></p>
                  </div>
                  <!-- END: loading animation -->
                  <input
                    v-if="isLoaded"
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="Input text"
                    :value="formData.email"
                    :disabled="isFieldDisabled"
                  />
                </div>
              </div>
            </div>
            <div v-if="isAvailable" class="flex justify-end mt-4">
              <button
                @click="updateInformations()"
                type="button"
                class="btn btn-primary w-20 mr-auto"
              >
                Save
              </button>
              <a href="" class="text-theme-6 flex items-center">
                <Trash2Icon class="w-4 h-4 mr-1" /> Delete Account
              </a>
            </div>
          </div>
        </div>
        <!-- END: Personal Information -->
      </div>
    </div>
  </div>
</template>

<style scoped>
.blob {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
.select {
  width: 200px;
}
</style>

<script>
import { computed, onMounted, reactive } from 'vue'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'

export default {
  name: 'athlete detail',
  data() {
    return {
      isLoaded: false
    }
  },
  watch: {
    athleteDetail() {
      if (this.$store.state.athletes.athleteDetailLoaded.message) {
        this.isLoaded = true
      }
      this.formData.zipCode = this.$store.state.athletes.athleteDetail?.zipCode
      this.formData.phoneNumber =
        this.$store.state.athletes.athleteDetail?.phoneNumber
      this.formData.emergencyNumber =
        this.$store.state.athletes.athleteDetail?.emergencyNumber
      this.formData.emergencyName =
        this.$store.state.athletes.athleteDetail?.emergencyName
      this.formData.address = this.$store.state.athletes.athleteDetail?.address
      this.formData.email = this.$store.state.athletes.athleteDetail?.userEmail
    }
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const athleteDetail = computed(() => store.state.athletes.athleteDetail)
    const role = computed(() => store.state.profile.user?.userRole?.name)
    const groups = computed(() => store.state.athletes.athleteDetail.userGroups)
    const isFieldDisabled = computed(() => !(store.state.profile.user?.userRole?.name == 'Coach' || store.state.profile.user?.userRole?.name == 'Super Admin'))
    const isAvailable = computed(() => (store.state.profile.user?.userRole?.name == 'Coach' || store.state.profile.user?.userRole?.name == 'Super Admin'))

    const fullName = computed(
      () =>
        store.state.athletes.athleteDetail?.firstName +
        ' ' +
        store.state.athletes.athleteDetail?.lastName
    )
    const clubName = computed(
      () => store.state.athletes.athleteDetail?.club?.name
    )
    const user = computed(() => store.state.athletes.athleteDetail)
    const userGroups = computed(
      () => store.state.athletes.athleteDetail?.userGroups
    )
    onMounted(() => {
      store.dispatch('athletes/getAthleteDetail', route.params.id)
      store.dispatch('profile/getProfileData')
    })
    const formData = reactive({
      role: store.state.profile.user?.userRole?.name,
      zipCode: '',
      phoneNumber: store.state.athletes.athleteDetail?.phoneNumber,
      emergencyNumber: store.state.athletes.athleteDetail?.emergencyNumber,
      emergencyName: store.state.athletes.athleteDetail?.emergencyName,
      address: store.state.athletes.athleteDetail?.address,
      email: store.state.athletes.athleteDetail?.userEmail
    })
    const updateRole = () => {
      store.dispatch('athletes/updateRole', {
        id: route.params.id,
        role: store.state.athletes.athleteDetail.userRole.code
      })
    }
    const updateInformations = () => {
      store.dispatch('athletes/updateAthleteDetail', {
        id: route.params.id,
        role: formData.role,
        phoneNumber: formData.phoneNumber,
        emergencyNumber: formData.emergencyNumber,
        emergencyName: formData.emergencyName,
        address: formData.address,
        email: formData.email
      })
    }
    return {
      athleteDetail,
      fullName,
      user,
      userGroups,
      isFieldDisabled,
      groups,
      clubName,
      formData,
      updateRole,
      updateInformations,
      role,
      isAvailable
    }
  }
}
</script>
