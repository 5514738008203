<template>
  <div id="app">
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12 lg:col-span-8 2xl:col-span-9">
        <div class="px-5">
          <div class="flex xl:flex-row flex-col">
            <div class="flex-1 mt-6 xl:mt-0">
              <div class="grid grid-cols-12 gap-x-5">
                <div class="col-span-12 2xl:col-span-6">
                  <div>
                    <label
                      for="update-profile-form-1"
                      class="form-label font-bold mt-3"
                      >Event Name</label
                    >
                    <!-- START: loading animation -->
                    <div v-if="!isLoaded" disabled>
                      <div class="flex-row">
                        <div
                          class="py-1 text-lg bg-theme-8 px-2 w-72 h-6 blob"
                        ></div>
                      </div>
                    </div>
                    <!-- END: loading animation -->
                    <p
                      v-if="isLoaded"
                      class="form-control"
                      :class="{
                        //loading state
                        hidden:
                          event.name == '' ||
                          event.name == null ||
                          event.name == undefined
                      }"
                    >
                      {{ event.name }}
                    </p>
                  </div>
                  <div class="mt-3">
                    <label
                      for="update-profile-form-1"
                      class="form-label font-bold"
                      >Event Date</label
                    >
                    <!-- START: loading animation -->
                    <div v-if="!isLoaded" disabled>
                      <div class="flex-row">
                        <div
                          class="py-1 text-lg bg-theme-8 px-2 w-72 h-6 blob"
                        ></div>
                      </div>
                    </div>
                    <!-- END: loading animation -->
                    <p
                      v-if="isLoaded"
                      class="form-control"
                      :class="{
                        //loading state
                        hidden:
                          event.eventDate == '' ||
                          event.eventDate == null ||
                          event.eventDate == undefined
                      }"
                    >
                      {{ event.eventDate }}
                      <ArrowDownIcon class="mx-2 pt-1" />
                      {{ event?.eventEndDate }}
                    </p>
                  </div>
                  <div class="w-full mt-3">
                    <label
                      for="update-profile-form-1"
                      class="form-label font-bold"
                      >Event Type</label
                    >
                    <!-- START: loading animation -->
                    <div v-if="!isLoaded" disabled>
                      <div class="flex-row">
                        <div
                          class="py-1 text-lg bg-theme-8 px-2 w-32 h-6 blob"
                        ></div>
                      </div>
                    </div>
                    <!-- END: loading animation -->
                    <p
                      v-if="isLoaded"
                      class="form-control"
                      :class="{
                        //loading state
                        hidden:
                          event.type == '' ||
                          event.type == null ||
                          event.type == undefined
                      }"
                    >
                      {{ event.type }}
                    </p>
                  </div>
                  <div class="w-full mt-3">
                    <label
                      for="update-profile-form-1"
                      class="form-label font-bold"
                      >Note</label
                    >
                    <!-- START: loading animation -->
                    <div v-if="!isLoaded" disabled>
                      <div class="flex-row">
                        <div
                          class="py-1 text-lg bg-theme-8 px-2 w-32 h-6 blob"
                        ></div>
                      </div>
                    </div>
                    <!-- END: loading animation -->
                    <p
                      v-if="isLoaded"
                      class="form-control"
                      :class="{
                        //loading state
                        hidden:
                          event.note == '' ||
                          event.note == null ||
                          event.note == undefined
                      }"
                    >
                      {{ event.note }}
                    </p>
                  </div>
                  <div class="mt-3">
                    <label
                      for="update-profile-form-2"
                      class="form-label font-bold"
                      >User Groups</label
                    >
                    <!-- START: loading animation -->
                    <div v-if="!isLoaded" disabled>
                      <div class="flex-row" v-for="el in 3" :key="el.id">
                        <div
                          class="py-1 text-lg bg-theme-1 px-2 w-32 my-2 h-6 blob"
                        ></div>
                      </div>
                    </div>
                    <!-- END: loading animation -->
                    <ul v-for="group in event.userGroups" :key="group.id">
                      <li class="my-2">
                        <span
                          v-if="isLoaded"
                          class="bg-theme-8 text-theme-1 rounded-md px-2 py-1 mr-1"
                          :class="{
                            //loading state
                            hidden:
                              group.groupName == '' ||
                              group.groupName == null ||
                              group.groupName == undefined
                          }"
                        >
                          {{ group.groupName }}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div v-if="role == 'Athlete'" class="mt-3 py-2">
                    <label
                      for="update-profile-form-2"
                      class="form-label font-bold"
                      >Participants</label
                    >
                    <!-- START: loading animation -->
                    <div v-if="!isLoaded" disabled>
                      <div class="flex-row" v-for="el in 4" :key="el.id">
                        <div
                          class="py-1 text-lg bg-theme-8 px-2 w-64 my-2 h-6 blob"
                        ></div>
                      </div>
                    </div>
                    <!-- END: loading animation -->
                    <ul v-for="rsvp in event.rsvps" :key="rsvp.id">
                      <li
                        v-if="isLoaded"
                        class="my-2 form-control"
                        :class="{
                          //loading state
                          hidden:
                            rsvp.fullName == '' ||
                            rsvp.fullName == null ||
                            rsvp.fullName == undefined
                        }"
                      >
                        <span class="px-2 py-1 mr-1">
                          {{ rsvp.fullName }} (
                          <div
                            v-if="rsvp.rsvp == 'Y'"
                            class="inline-block text-theme-9"
                          >
                            going
                          </div>
                          <div
                            v-if="rsvp.rsvp == 'N'"
                            class="inline-block text-theme-6"
                          >
                            not going
                          </div>
                          <div
                            v-if="rsvp.rsvp == 'NR'"
                            class="inline-block text-theme-7"
                          >
                            no response
                          </div>
                          <div
                            v-if="rsvp.rsvp == 'T'"
                            class="inline-block text-theme-12"
                          >
                            tentative
                          </div>
                          )
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div
                    v-else-if="role == 'Coach' || 'Super Admin'"
                    class="mt-3 py-2"
                  >
                  <UpdateRsvpForUser :event="event" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Event Information -->
    </div>
  </div>
</template>

<style scoped>
.blob {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.rsvp {
  height: 64px;
}
</style>

<script>
import UpdateRsvpForUser from './UpdateRsvpForUser.vue'
import { computed, onMounted, reactive, watch } from 'vue'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'

export default {
  name: 'Event information - Event Detail',
  data() {
    return {
      type: 'information',
      isLoaded: false
    }
  },
  watch: {
    event() {
      if (this.event.eventDate.length > 0) {
        this.isLoaded = true
      }
    }
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const event = computed(() => store.state.events.eventDetail)
    const role = computed(() => store.state.profile.user?.userRole?.name)
    const isSuccess = computed(
      () => store.state.events.updateRsvpForAthlete.isSuccess
    )
    let myEvent = reactive({
      detail: event
    })
    var loading = reactive({ value: false })
    onMounted(() => {
      store.dispatch('profile/getProfileData')
      store.dispatch('events/getEventDetail', route.params.id)
    })
    const formData = reactive({
      eventId: route.params.id,
      rsvp: '',
      id: ''
    })
    const updateRsvpForUser = () => {
      store.dispatch('events/updateRsvpForAthlete', formData)
    }
    watch(
      () => formData.rsvp,
      (rsvp, previous) => {
        myEvent = store.state.events.eventDetail
        const index = myEvent.rsvps.findIndex((x) => x.userId == formData.id)
        myEvent.rsvps[index].rsvp = formData.rsvp
      }
    )
    return {
      route,
      event,
      loading,
      formData,
      updateRsvpForUser,
      isSuccess,
      myEvent,
      role
    }
  },
  components: {
    UpdateRsvpForUser
  }
}
</script>
