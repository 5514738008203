const authErrorMessages = {
  'auth/wrong-password': 'Your details are incorrect please try again',
  'auth/email-already-exists':
    'The provided email is already in use by an existing user',
  'auth/invalid-email': 'Email address in invalid',
  'auth/email-already-in-use': 'This email address is already registered',
  'auth/user-not-found': 'User cannot be found, please register'
}

export default authErrorMessages
