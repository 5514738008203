import axios from 'axios'
import Toastify from 'toastify-js'
export const PROFILE = 'PROFILE'
export const UPDATE_INFORMATIONS = 'UPDATE_INFORMATIONS'

const state = () => {
  return {
    user: {
      club: {
      },
      userGroups: {
      }
    }
  }
}

// getters
const getters = {
  user: state => {
    return state.user
  }
}

// actions
const actions = {
  getProfileData({ commit }, data) {
    commit(PROFILE, data)
  },
  updateInformations({ commit }, params) {
    commit(UPDATE_INFORMATIONS, params)
  }
}

// mutations
const mutations = {
  [PROFILE](state, data) {
    axios
      .get(process.env.VUE_APP_API_BASE_URL + '/api/user')
      .then(response => {
        if (response.data.success) {
          state.user = response.data.data
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
  [UPDATE_INFORMATIONS](state, params) {
    axios.put(process.env.VUE_APP_API_BASE_URL + '/api/user', {
      phoneNumber: params.phoneNumber,
      emergencyNumber: params.emergencyNumber,
      emergencyName: params.emergencyName,
      address: params.address
    })
      .then(response => {
        Toastify({
          duration: 2000,
          text: response.data.message,
          newWindow: true,
          backgroundColor: 'white',
          style: {
            padding: '10px'
          },
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
      })
      .catch(err => {
        console.log(err)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
