<template>
  <div>
    <span>
      <button id="event" class="text-sm text-center float-right">
        <div class="inline-block p-2" :class="{
          //not locked
          dropdown:
            !element.locked
        }" data-placement="bottom">
          <button class="dropdown-toggle btn btn-primary w-32" :class="{
            'btn-success text-white': element?.userRsvp?.rsvp === 'Y',
            'btn-danger text-white': element?.userRsvp?.rsvp === 'N',
            'btn-primary text-white': element?.userRsvp?.rsvp === 'NR',
            'btn-warning text-white': element?.userRsvp?.rsvp === 'T',
            'btn-primary text-white': element?.userRsvp?.rsvp == undefined
          }" aria-expanded="false" :disabled="element.locked">
            <a v-if="element?.userRsvp?.rsvp === 'Y'"> going </a>
            <a v-else-if="element?.userRsvp?.rsvp === 'N'"> not going </a>
            <a v-else-if="element?.userRsvp?.rsvp === 'NR'"> no response </a>
            <a v-else-if="element?.userRsvp?.rsvp === 'T'"> tentative </a>
            <a v-else> no response </a>
          </button>
          <div class="dropdown-menu w-40">
            <div class="
                                    dropdown-menu__content
                                    box
                                    dark:bg-dark-1
                                    p-2
                                  ">
              <button @click="
                updateRsvp(
                  index,
                  (formData.id = element.id),
                  (formData.rsvp = 'Y')
                )
              " class="
                                      block
                                      p-2
                                      w-full
                                      transition
                                      duration-300
                                      ease-in-out
                                      bg-white
                                      dark:bg-dark-1
                                      hover:bg-gray-200
                                      dark:hover:bg-dark-2
                                      rounded-md
                                    " data-dismiss="dropdown">
                going
              </button>
              <button @click="
                updateRsvp(
                  index,
                  (formData.id = element.id),
                  (formData.rsvp = 'N')
                )
              " class="
                                      block
                                      p-2
                                      w-full
                                      transition
                                      duration-300
                                      ease-in-out
                                      bg-white
                                      dark:bg-dark-1
                                      hover:bg-gray-200
                                      dark:hover:bg-dark-2
                                      rounded-md
                                    " data-dismiss="dropdown">
                not going
              </button>
              <button @click="
                updateRsvp(
                  index,
                  (formData.id = element.id),
                  (formData.rsvp = 'NR')
                )
              " class="
                                      block
                                      p-2
                                      w-full
                                      transition
                                      duration-300
                                      ease-in-out
                                      bg-white
                                      dark:bg-dark-1
                                      hover:bg-gray-200
                                      dark:hover:bg-dark-2
                                      rounded-md
                                    " data-dismiss="dropdown">
                no response
              </button>
              <button @click="
                updateRsvp(
                  index,
                  (formData.id = element.id),
                  (formData.rsvp = 'T')
                )
              " class="
                                      block
                                      p-2
                                      w-full
                                      transition
                                      duration-300
                                      ease-in-out
                                      bg-white
                                      dark:bg-dark-1
                                      hover:bg-gray-200
                                      dark:hover:bg-dark-2
                                      rounded-md
                                    " data-dismiss="dropdown">
                tentative
              </button>
            </div>
          </div>
        </div>
      </button>
    </span>
</div>
</template>

<script>
import { computed, reactive } from 'vue'
import { useStore } from '@/store'

export default {
  name: 'Update Rsvp',
  data() {
    return {
      disabled: false
    }
  },
  props: {
    element: Object,
    index: Number
  },
  setup() {
    const store = useStore()
    const events = computed(() => store.state.events.events)
    const response = computed(() => store.state.events.updateRsvp.response)
    const userId = computed(() => store.state.profile.user?.id)
    const fullName = computed(
      () =>
        store.state.profile.user?.firstName +
        ' ' +
        store.state.profile.user?.lastName
    )
    const formData = reactive({
      id: '',
      rsvp: ''
    })
    const updateRsvp = (index) => {
      if (events.value[index].userRsvp !== undefined) {
        events.value[index].userRsvp.rsvp = formData.rsvp
      } else {
        events.value[index].userRsvp = { userId: userId, fullName: fullName, rsvp: formData.rsvp }
      }
      store.dispatch('events/updateEvent', formData)
    }
    return {
      formData,
      updateRsvp,
      response,
      userId,
      fullName,
      events
    }
  }
}
</script>
