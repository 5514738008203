import { createStore } from 'vuex'
import main from './main'
import sideMenu from './side-menu'
import auth from './auth'
import profile from './profile'
import inventories from './inventories'
import clubs from './clubs'
import events from './events'
import bookings from './bookings'
import athletes from './athletes'
import groups from './groups'
import invite from './invite'
import dashboard from './dashboard'
import announcements from './announcements'
import calendar from './calendar'

const store = createStore({
  modules: {
    main,
    sideMenu,
    auth,
    profile,
    inventories,
    clubs,
    events,
    bookings,
    athletes,
    groups,
    invite,
    dashboard,
    announcements,
    calendar
  }
})

export function useStore() {
  return store
}

export default store
