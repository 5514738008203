<template>
  <div class="p-2">
    <span class="inline-flex justify-center mx-2">
      <router-link :to="{ path: '/inventory' }">
        <div class="inline-block gap-1 border border-1 border-black rounded">
          <ArrowLeftIcon />
        </div>
        <span class="inline-block mx-2 align-top mt-1">Inventory</span>
      </router-link>
    </span>
  </div>
  <div class="container bg-white rounded-lg">
    <div class="col-span-12 lg:col-span-8 2xl:col-span-9">
      <!-- BEGIN: Display Information -->
      <div class="intro-y box lg:mt-5">
        <div
          class="
            flex
            items-center
            p-5
            border-b border-gray-200
            dark:border-dark-5
          "
        >
          <h2
            class="font-medium text-base mr-auto"
            :class="{
              //loading state
              hidden:
                otherDetail?.name == 'undefined undefined' ||
                otherDetail?.name == 'undefined' ||
                otherDetail?.name == undefined
            }"
          >
            {{ otherDetail?.name }}'s Details
          </h2>
        </div>
        <div class="px-5">
          <div class="flex xl:flex-row flex-col">
            <div class="flex-1 mt-6 xl:mt-0">
              <div class="grid grid-cols-12 gap-x-5">
                <div class="col-span-12 2xl:col-span-6">
                  <div>
                    <label for="update-profile-form-1" class="form-label mt-5"
                      >Name<span v-if="isAvailable" class="text-theme-6"
                        >*</span
                      ></label
                    >
                    <!-- START: loading animation -->
                    <div v-if="!isLoaded">
                      <p class="bg-theme-8 px-2 w-full h-8 blob"></p>
                    </div>
                    <!-- END: loading animation -->
                    <input
                      v-if="isLoaded"
                      id="update-profile-form-1"
                      type="text"
                      class="form-control"
                      placeholder="no value entered yet"
                      v-model="otherDetail.name"
                      :disabled="isFieldDisabled"
                    />
                  </div>
                  <div class="my-2">
                    <label for="update-profile-form-2" class="form-label"
                      >Inventory Code</label
                    >
                    <!-- START: loading animation -->
                    <div v-if="!isLoaded">
                      <p class="bg-theme-8 px-2 w-full h-8 blob"></p>
                    </div>
                    <!-- END: loading animation -->
                    <input
                      v-if="isLoaded"
                      id="update-profile-form-1"
                      type="text"
                      class="form-control"
                      placeholder="no value entered yet"
                      v-model="otherDetail.code"
                      :disabled="isFieldDisabled"
                    />
                  </div>
                </div>
                <div class="col-span-12 2xl:col-span-6 mt-3">
                  <div class="my-2">
                    <label for="update-profile-form-1" class="form-label"
                      >Note</label
                    >
                    <!-- START: loading animation -->
                    <div v-if="!isLoaded">
                      <p class="bg-theme-8 px-2 w-full h-8 blob"></p>
                    </div>
                    <!-- END: loading animation -->
                    <input
                      v-if="isLoaded"
                      v-model="otherDetail.note"
                      id="update-profile-form-1"
                      type="text"
                      class="form-control"
                      placeholder="no value entered yet"
                      :disabled="isFieldDisabled"
                    />
                  </div>
                  <div class="my-2">
                    <label for="update-profile-form-1" class="form-label"
                      >Status</label
                    >
                    <!-- START: loading animation -->
                    <div v-if="!isLoaded">
                      <p class="bg-theme-8 px-2 w-full h-8 blob"></p>
                    </div>
                    <!-- END: loading animation -->
                    <v-select :disabled="isFieldDisabled" v-if="isLoaded" v-model="otherDetail.inUse" :reduce="(option) => option?.value" :options="[{label: 'in use', value: true},{label: 'out of use', value: false}]">
                    </v-select>
                  </div>
                </div>
              </div>
              <div v-if="isAvailable" class="font-light text-theme-6">
                <p>Fields marked with '*' are required</p>
              </div>
              <div
                v-if="isAvailable"
                class="inline-block py-2"
                role="button"
                @click="submit()"
              >
                <input
                  type="submit"
                  class="
                    rounded-md
                    bg-theme-1
                    text-white
                    font-semibold
                    w-20
                    h-8
                  "
                  value="save"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Display Information -->
    </div>
  </div>
</template>

<style scoped>
.blob {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
</style>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'
import Toastify from 'toastify-js'

export default {
  data() {
    return {
      isLoaded: false
    }
  },
  mounted() {},
  methods: {},
  watch: {
    otherDetail() {
      if (this.$store.state.inventories.otherDetailLoaded.message) {
        this.isLoaded = true
      }
    }
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const otherDetail = computed(() => store.state.inventories.otherDetail)
    const role = computed(() => store.state.profile.user?.userRole?.name)
    const clubName = computed(() => store.state.profile.user?.club.name)
    const isFieldDisabled = computed(() => !(store.state.profile.user?.userRole?.name == 'Coach' || store.state.profile.user?.userRole?.name == 'Super Admin'))
    const isAvailable = computed(() => (store.state.profile.user?.userRole?.name == 'Coach' || store.state.profile.user?.userRole?.name == 'Super Admin'))

    onMounted(() => {
      store.dispatch('profile/getProfileData')
      store.dispatch('inventories/getOtherDetail', route.params.id)
    })
    const submit = () => {
      if (store.state.inventories.otherDetail.name !== '') {
        store.dispatch(
          'inventories/updateOther',
          store.state.inventories.otherDetail
        )
      } else {
        Toastify({
          duration: 2000,
          text: 'Please fill in the required fields',
          newWindow: true,
          style: {
            backgroundColor: '#D32929',
            color: 'white',
            padding: '10px'
          },
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
      }
      store.dispatch('inventories/getOtherDetail', route.params.id)
    }
    return {
      otherDetail,
      clubName,
      submit,
      role,
      isFieldDisabled,
      isAvailable
    }
  }
}
</script>
