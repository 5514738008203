<template>
  <div class="p-4">
    <label for="crud-form-1" class="form-label flex">
      <span class="inline-flex justify-center border border-1 border-black rounded">
        <router-link :to="{ path: '/user-groups' }">
          <div class="flex gap-1">
            <ArrowLeftIcon />
          </div>
        </router-link>
      </span>
      <span class="flex items-center px-2">User Groups</span>
    </label>
  </div>
  <div v-if="role == 'Athlete'" class="p-10">
    <div class="flex justify-center">
      <AlertCircleIcon class="w-8 h-8 my-4" />
    </div>
    <div class="flex justify-center text-center">
      You do not have permission to view this page
    </div>
  </div>
  <div class="px-4" v-if="isAvailable">
    <div class="text-xl font-bold">Add New Athlete to Group</div>
    <div role="button" class="float-right text-right p-2" @click="disable()">
      <button @click="addAthlete()" id="submit" class="btn float-right btn-primary w-24">
        Save
        <LoadingIcon v-if="loading.value" icon="oval" class="w-4 h-4 ml-4 text-white" />
      </button>
    </div>
    <!-- BEGIN: Multiple Select -->
    <div class="flex p-2">
      <v-select v-model="validate.athletes.$model" :reduce="(option) => option?.id" label="fullName" :options="users"
        multiple class="w-full bg-white">
      </v-select>
    </div>
    <!-- END: Multiple Select -->
    <!-- BEGIN: Failed Notification Content -->
    <div id="failed-notification-content" class="toastify-content hidden flex">
      <XCircleIcon class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div class="font-medium">Please fill requirements</div>
        <div class="text-gray-600 mt-1">Please check the form.</div>
      </div>
    </div>
    <!-- END: Failed Notification Content -->
  </div>
</template>

<script>
import { computed, onMounted, reactive, toRefs } from 'vue'
import { useStore } from '@/store'
import { /* useRouter */ useRoute } from 'vue-router'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import Toastify from 'toastify-js'

export default {
  name: 'Add athlete to group',
  methods: {
    disable() {
      if (this.formData.athletes.length > 0) {
        this.loading.value = true
        document.getElementById('submit').disabled = true
      }
    }
  },
  watch: {
    isSuccess() {
      if (this.isSuccess) {
        this.loading.value = true
        this.$router.back()
      } else {
        this.loading.value = false
        document.getElementById('submit').disabled = false
      }
    }
  },
  setup() {
    const route = useRoute()
    // const router = useRouter()
    const store = useStore()
    const role = computed(() => store.state.profile.user?.userRole?.name)
    const isAvailable = computed(() => (store.state.profile.user?.userRole?.name == 'Coach' || store.state.profile.user?.userRole?.name == 'Super Admin'))
    const users = computed(() => store.state.athletes.athletes)
    const isSuccess = computed(
      () => store.state.groups.addAthletesToGroup.isSuccess
    )
    var loading = reactive({ value: false })
    onMounted(() => {
      store.dispatch('athletes/getAthletesData')
    })
    const formData = reactive({
      athletes: ''
    })
    const rules = {
      athletes: {
        required
      }
    }
    const validate = useVuelidate(rules, toRefs(formData))
    const addAthlete = (userId, groupId) => {
      validate.value.$touch()
      if (validate.value.$invalid) {
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
      } else {
        store.dispatch('groups/addAthletesToGroup', {
          users: formData.athletes,
          groupId: route.query.groupId,
          len: formData.athletes.length
        })
        // router.back()
        store.dispatch('athletes/getAthletesData')
      }
    }
    return {
      route,
      users,
      formData,
      validate,
      isSuccess,
      loading,
      role,
      isAvailable,
      addAthlete
    }
  }
}
</script>
