<template>
  <div id="app">
    <div class="w-full px-2 py-4">
      <section>
        <div class="container mx-auto">
          <div class="flex flex-wrap">
            <div class="w-full">
              <div class="pt-4 bg-white rounded">
                <div class="px-6 border-b border-blue-50">
                  <!-- router -->
                  <div class="float-right px-1 py-2">
                    <router-link :to="{ path: '/add-booking' }" class="
                              btn
                              float-right
                              border border-black
                              font-bold
                              px-2
                              py-1
                              text-sm
                              outline-none
                              rounded
                              focus:outline-none
                              ease-linear
                              transition-all
                              duration-150
                            ">
                      <div class="flex gap-1">
                        <PlusIcon />Add Booking
                      </div>
                    </router-link>
                  </div>
                  <div class="flex flex-wrap items-center mb-4">
                    <div>
                      <h3 class="text-xl font-bold px-1 py-2">Boat Booking</h3>
                    </div>
                  </div>
                  <div>
                    <span v-on:click="this.type = 'myBookings'" :class="
                      type === 'myBookings'
                        ? 'text-theme-1 border-b-2 border-theme-1'
                        : 'text-theme-16 border-b-2 border-transparent hover:border-theme-3 hover:text-theme-3 cursor-pointer'
                    " class="inline-block px-4 pb-2 text-sm font-medium">My Bookings</span>
                    <span v-on:click="this.type = 'allBookings'" :class="
                      type === 'allBookings'
                        ? 'text-theme-1 border-b-2 border-theme-1'
                        : 'text-theme-16 border-b-2 border-transparent hover:border-theme-3 hover:text-theme-3 cursor-pointer'
                    " class="inline-block px-4 pb-2 text-sm font-medium">All Bookings</span>
                  </div>
                </div>
                <div>
                  <div
                    v-if="(type == 'myBookings' && isLoaded && myBookings.length < 1) || (type == 'allBookings' && allBookings.length < 1)">
                    <div class="w-full p-4">
                      <div class="flex justify-center mt-6">
                        <LifeBuoyIcon class="w-12 h-12" />
                      </div>
                      <div class="flex justify-center text-lg p-4">
                        No booking to list
                      </div>
                    </div>
                  </div>
                </div>
                <div class="hidden lg:block" v-if="type == 'myBookings'">
                  <table class="w-full">
                    <thead class="bg-gray-50" v-if="isLoaded && myBookings.length > 0">
                      <tr class="border-b text-sm border-blue-50">
                        <th class="p-2 text-theme-1">Boat Name</th>
                        <th class="p-2 text-theme-1">DateTime</th>
                        <th class="p-2 text-theme-1">Created by</th>
                        <th class="p-2 text-theme-1">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <!-- START: loading animation for L view  -->
                    <tbody v-if="!isLoaded">
                      <tr v-for="el in 8" :key="el.id" class="w-full p-2 mx-4 my-6 border-b">
                        <td class="
                                  w-1/7
                                  text-center
                                  border-b
                                  lg:table-cell
                                  static
                                  m-4
                                  my-2
                                  px-2
                                ">
                          <p class="px-2 mx-4 w-full h-6 blob bg-theme-1"></p>
                        </td>
                        <td class="
                                  w-1/7
                                  text-center
                                  border-b
                                  lg:table-cell
                                  static
                                  m-4
                                  my-2
                                  px-2
                                ">
                          <p class="px-2 mx-4 w-full h-6 blob bg-theme-8"></p>
                        </td>
                        <td class="
                                  w-1/7
                                  text-center
                                  border-b
                                  lg:table-cell
                                  static
                                  m-4
                                  my-2
                                  px-2
                                ">
                          <p class="px-2 mx-4 w-full h-6 blob bg-theme-8"></p>
                        </td>
                        <td class="
                                  w-1/7
                                  text-center
                                  border-b
                                  lg:table-cell
                                  static
                                  m-4
                                  my-2
                                  px-6
                                ">
                          <p class="px-2 mx-4 w-full h-6 blob bg-theme-6"></p>
                        </td>
                      </tr>
                    </tbody>
                    <!-- END: loading animation for L view  -->
                    <tbody v-if="isLoaded">
                      <tr v-for="(booking, index) in myBookings" :key="index"
                        class="w-full p-2 mx-4 my-6 border-b hover:bg-theme-14">
                        <td class="
                                  w-1/4
                                  text-theme-1 text-center
                                  border-b
                                  lg:table-cell
                                  static
                                ">
                          <p class="py-1 px-2 text-sm">
                            {{ booking.boat?.name }}({{ booking.boat?.boatType?.boatType }})
                          </p>
                        </td>
                        <td class="
                                  w-1/4
                                  text-theme-1 text-center
                                  border-b
                                  lg:table-cell
                                  static
                                ">
                          <p class="py-1 px-2 text-sm mx-4 pt-2">
                            {{ booking.startDate }} -> {{ booking.endDate }}
                          </p>
                        </td>
                        <td class="
                                  w-1/4
                                  text-theme-1 text-center
                                  border-b
                                  lg:table-cell
                                  static
                                ">
                          <p class="py-1 px-2 text-blue-400 text-sm">
                            {{ booking.createdBy?.fullName }}
                          </p>
                        </td>
                        <td class="
                                  w-1/4
                                  text-theme-1 text-center
                                  border-b
                                  lg:table-cell
                                  static
                                ">
                          <a class="flex justify-center py-1 px-2 text-blue-400 text-sm" href="javascript:;"
                            data-toggle="modal" data-target="#delete-modal-preview"
                            @click="this.selectedBooking = booking.id">
                            <Trash2Icon class="text-theme-6 p-1" />
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="hidden lg:block" v-if="type == 'allBookings'">
                  <table class="w-full">
                    <thead class="bg-gray-50" v-if="isLoaded && allBookings.length > 0">
                      <tr class="border-b text-sm border-blue-50">
                        <th class="p-2 text-theme-1">Boat Name</th>
                        <th class="p-2 text-theme-1">DateTime</th>
                        <th class="p-2 text-theme-1">Created by</th>
                        <th class="p-2 text-theme-1">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="isLoaded">
                      <tr v-for="(booking, index) in allBookings" :key="index"
                        class="w-full p-2 mx-4 my-6 border-b hover:bg-theme-14">
                        <td class="
                                  w-1/4
                                  text-theme-1 text-center
                                  border-b
                                  lg:table-cell
                                  static
                                ">
                          <p class="py-1 px-2 text-sm">
                            {{ booking.boat?.name }}({{ booking.boat?.boatType?.boatType }})
                          </p>
                        </td>
                        <td class="
                                  w-1/4
                                  text-theme-1 text-center
                                  border-b
                                  lg:table-cell
                                  static
                                ">
                          <p class="py-1 px-2 text-sm mx-4 pt-2">
                            {{ booking.startDate }} -> {{ booking.endDate }}
                          </p>
                        </td>
                        <td class="
                                  w-1/4
                                  text-theme-1 text-center
                                  border-b
                                  lg:table-cell
                                  static
                                ">
                          <p class="py-1 px-2 text-blue-400 text-sm">
                            {{ booking.createdBy?.fullName }}
                          </p>
                        </td>
                        <td class="
                                  w-1/4
                                  text-theme-1 text-center
                                  border-b
                                  lg:table-cell
                                  static
                                ">
                          <a class="flex justify-center py-1 px-2 text-blue-400 text-sm" href="javascript:;"
                            data-toggle="modal" data-target="#delete-modal-preview"
                            @click="this.selectedBooking = booking.id">
                            <Trash2Icon class="text-theme-6 p-1" />
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="lg:hidden xl:hidden p-4" v-if="type == 'allBookings'">
                  <ul v-for="booking in allBookings" :key="booking.id"
                    class="border border-1 border-theme-14 rounded-md my-2">
                    <div>
                      <li>
                        <div class="flex justify-end"></div>
                      </li>
                      <li class="bg-theme-14 p-2">
                        <div class="float-right">
                          <a href="javascript:;" data-toggle="modal" data-target="#delete-modal-preview"
                            @click="this.selectedBooking = booking.id">
                            <Trash2Icon class="text-theme-6 p-1" />
                          </a>
                        </div>
                        <div>
                          <span class="text-md font-semibold text-theme-19"> {{ booking.boat?.name }}({{
                            booking.boat?.boatType?.boatType }})</span>
                        </div>
                      </li>
                      <li class="px-2 py-1">
                        <div>
                          <p class="text-md">
                            <span class="font-semibold text-theme-19">Owner: </span>
                            <span class="text-xs ">
                              {{ booking.startDate }} -> {{ booking.endDate }}
                            </span>
                          </p>
                        </div>
                      </li>
                      <li class="px-2 py-1">
                        <div>
                          <p class="text-md mb-2">
                            <span class="text-md font-semibold text-theme-19">Type:
                            </span>
                            <span class="text-xs"> {{ booking.createdBy?.fullName }}
                            </span>
                          </p>
                        </div>
                      </li>
                    </div>
                  </ul>
                </div>
                <div class="lg:hidden xl:hidden p-2" v-if="type == 'myBookings'">
                  <!-- START: loading animation for S-M views  -->
                  <div v-if="!isLoaded" disabled>
                    <ul v-for="el in 8" :key="el.id" class="w-full border-b py-2 mx-4">
                      <li>
                        <div class="flex-row">
                      <li class="
                                    py-1
                                    text-lg
                                    bg-theme-1
                                    my-2
                                    px-2
                                    w-64
                                    h-6
                                    blob
                                  "></li>
                  </div>
                  </li>
                  <li>
                    <span class="inline-block">
                  <li class="
                                    py-1
                                    text-sm
                                    bg-theme-8
                                    mr-2
                                    px-2
                                    w-32
                                    h-6
                                    blob
                                  "></li>
                  </span>
                  </li>
                  <li>
                    <span class="inline-block">
                      <p class="py-1 text-sm bg-theme-8 mr-2 px-2 blob"></p>
                    </span>
                  </li>
                  <li>
                    <span class="inline-block">
                      <p class="
                                    py-1
                                    text-sm
                                    bg-theme-8
                                    mr-2
                                    px-2
                                    w-48
                                    h-6
                                    blob
                                  "></p>
                    </span>
                  </li>
                  </ul>
                </div>
                <!-- END: loading animation for S-M views  -->
                <div class="p-2" v-if="isLoaded && type == 'myBookings'">
                  <ul v-for="booking in myBookings" :key="booking.id"
                    class="border border-1 border-theme-14 rounded-md my-2">
                    <div>
                      <li>
                        <div class="flex justify-end"></div>
                      </li>
                      <li class="bg-theme-14 p-2">
                        <div class="float-right">
                          <a href="javascript:;" data-toggle="modal" data-target="#delete-modal-preview"
                            @click="this.selectedBooking = booking.id">
                            <Trash2Icon class="text-theme-6 p-1" />
                          </a>
                        </div>
                        <div>
                          <span class="text-md font-semibold text-theme-19"> {{ booking.boat?.name }}({{
                            booking.boat?.boatType?.boatType }})</span>
                        </div>
                      </li>
                      <li class="px-2 py-1">
                        <div>
                          <p class="text-md">
                            <span class="font-semibold text-theme-19">Owner: </span>
                            <span class="text-xs ">
                              {{ booking.startDate }} -> {{ booking.endDate }}
                            </span>
                          </p>
                        </div>
                      </li>
                      <li class="px-2 py-1">
                        <div>
                          <p class="text-md mb-2">
                            <span class="text-md font-semibold text-theme-19">Type:
                            </span>
                            <span class="text-xs"> {{ booking.createdBy?.fullName }}
                            </span>
                          </p>
                        </div>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
              <div id="delete-modal">
                <div class="preview">
                  <!-- START: Modal Content -->
                  <div id="delete-modal-preview" class="modal" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-body p-0">
                          <div class="p-5 text-center">
                            <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
                            <div class="text-3xl mt-5">Are you sure?</div>
                            <div class="text-gray-600 mt-2">
                              This process cannot be undone.
                            </div>
                          </div>
                          <div class="px-5 pb-8 text-center">
                            <button type="button" data-dismiss="modal" class="
                                        btn btn-outline-secondary
                                        w-24
                                        dark:border-dark-5 dark:text-gray-300
                                        mr-1
                                      ">
                              Cancel
                            </button>
                            <button @click="deleteBooking(this.selectedBooking)" type="button" class="btn btn-danger w-24"
                              data-dismiss="modal">
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- END: Modal Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    </section>
  </div>
</div>
</template>

<style scoped>
.blob {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
</style>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from '@/store'

export default {
  name: 'Boat Booking',
  data() {
    return {
      type: 'myBookings',
      isLoaded: false
    }
  },
  methods: {},
  watch: {
    myBookings() {
      if (this.$store.state.bookings.myBookingsLoaded.message) {
        this.isLoaded = true
      }
    }
  },
  setup() {
    const store = useStore()
    const myBookings = computed(() => store.state.bookings.myBookings)
    const allBookings = computed(() => store.state.bookings.allBookings)

    onMounted(() => {
      store.dispatch('bookings/getMyBookings')
      store.dispatch('bookings/getAllBookings')
      store.dispatch('profile/getProfileData')
    })
    const clubName = computed(() => store.state.profile.user?.club?.name)
    const deleteBooking = (bookingId) => {
      store.dispatch('bookings/deleteBooking', bookingId)
    }
    return {
      deleteBooking,
      clubName,
      allBookings,
      myBookings
    }
  }
}
</script>
