import axios from 'axios'
import Toastify from 'toastify-js'
import moment from 'moment'
import { useStore } from '@/store'
export const EVENTS = 'EVENTS'
export const UPCOMING_EVENTS = 'UPCOMING_EVENTS'
export const EVENT_DETAIL = 'EVENT_DETAIL'
export const LINE_UPS = 'LINE_UPS'
export const ADD_EVENT = 'ADD_EVENT'
export const DELETE_EVENT = 'DELETE_EVENT'
export const DELETE_ALL_RECURRING_EVENTS = 'DELETE_ALL_RECURRING_EVENTS'
export const DELETE_LINE_UP = 'DELETE_LINE_UP'
export const UPDATE_EVENT = 'UPDATE_EVENT'
export const UPDATE_RSVP_FOR_ATHLETE = 'UPDATE_RSVP_FOR_ATHLETE'
export const ADD_BOAT_TO_LINE_UPS = 'ADD_BOAT_TO_LINE_UPS'
export const ADD_ATHLETE_TO_BOAT = 'ADD_ATHLETE_TO_BOAT'
export const SUBMIT_LINE_UP = 'SUBMIT_LINE_UP'

const state = () => {
  return {
    events: [],
    upcomingEvents: {
    },
    eventDetail: {},
    addEventForm: {
      isFormSubmitted: false,
      isSuccess: null
    },
    updateRsvp: {
      isSuccess: null,
      response: null
    },
    updateRsvpForAthlete: {
      isSuccess: null,
      response: null
    },
    deleteEvent: {
      isSuccess: null
    },
    deleteAllRecurringEvents: {
      isSuccess: null
    },
    lineUps: [
    ],
    eventsLoaded: {
      message: null
    },
    upcomingEventsLoaded: {
      message: null
    },
    isLoadMoreActive: false,
    isLoadMore: true
  }
}

// getters
const getters = {
  events: state => {
    return state.events
  },
  eventDetail: state => {
    return state.eventDetail
  },
  lineUps: state => {
    return state.lineUps
  }
}

// actions
const actions = {
  getEventsData({ commit }, data) {
    commit(EVENTS, data)
  },
  getUpcomingEvents({ commit }, data) {
    commit(UPCOMING_EVENTS, data)
  },
  getEventDetail({ commit }, data) {
    commit(EVENT_DETAIL, data)
  },
  getEventLineUps({ commit }, data) {
    commit(LINE_UPS, data)
  },
  addEvent({ commit }, formData) {
    commit(ADD_EVENT, formData)
  },
  addAthleteToBoat({ commit }, params) {
    commit(ADD_ATHLETE_TO_BOAT, params)
  },
  addBoatToLineUps({ commit }, selectedBoat) {
    commit(ADD_BOAT_TO_LINE_UPS, selectedBoat)
  },
  updateEvent({ commit }, formData) {
    commit(UPDATE_EVENT, formData)
  },
  updateRsvpForAthlete({ commit }, formData) {
    commit(UPDATE_RSVP_FOR_ATHLETE, formData)
  },
  submitLineUp({ commit }, eventId) {
    commit(SUBMIT_LINE_UP, eventId)
  },
  deleteEvent({ commit }, params) {
    commit(DELETE_EVENT, params)
  },
  deleteAllRecurringEvents({ commit }, params) {
    commit(DELETE_ALL_RECURRING_EVENTS, params)
  },
  deleteLineUp({ commit }, params) {
    commit(DELETE_LINE_UP, params)
  }
}

// mutations
const mutations = {
  [EVENTS](state, data) {
    if (data.offset > 0) {
      state.isLoadMoreActive = true
    } else {
      state.eventsLoaded.message = false
    }
    axios
      .get(process.env.VUE_APP_API_BASE_URL + '/api/me/event/list', {
        params: { offset: data.offset }
      })
      .then(response => {
        if (response.status === 200) {
          const formattedEvents = response.data.data.map(event => {
            event.eventDate = moment(
              event.eventDate * 1000
            ).format('dddd MMM Do YY, h:mm a')
            event.eventEndDate = moment(
              event.eventEndDate?.timestamp * 1000
            ).format('dddd MMM Do YY, h:mm a')
            event.rsvps.map(el => {
              if (el?.userId == data?.user?.value) {
                event.userRsvp = el
              }
            })
            return event
          })
          if (data.func == 'loadMore') {
            state.events.push(...formattedEvents)
          } else if (data.func == 'mounted') {
            state.isLoadMore = true
            data.offset = 0
            state.events = []
            state.events.push(...formattedEvents)
          }
          state.isLoadMoreActive = false
          state.eventsLoaded.message = true
          if (formattedEvents.length < 10) {
            state.isLoadMore = false
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
  [UPCOMING_EVENTS](state, data) {
    axios
      .get(process.env.VUE_APP_API_BASE_URL + '/api/me/event/dashboard')
      .then(response => {
        if (response.data.success) {
          state.upcomingEvents = response.data.data
          for (var i = 0; i < state.upcomingEvents.length; i++) {
            state.upcomingEvents[i].eventDate = moment(state.upcomingEvents[i].eventDate * 1000).format('dddd MMM Do YY, h:mm a')
            state.upcomingEvents[i].eventEndDate = moment(state.upcomingEvents[i].eventEndDate.timestamp * 1000).format('dddd MMM Do YY, h:mm a')
          }
          if (response.status == 200) {
            state.upcomingEventsLoaded.message = true
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
  [EVENT_DETAIL](state, id) {
    const store = useStore()
    axios
      .get(process.env.VUE_APP_API_BASE_URL + '/api/event/' + id)
      .then(response => {
        if (response.data.success) {
          state.eventDetail = response.data.data
          state.eventDetail.eventDate = moment(state.eventDetail.eventDate * 1000).format('dddd MMM Do YY, h:mm a')
          state.eventDetail.eventEndDate = moment(state.eventDetail.eventEndDate.timestamp * 1000).format('dddd MMM Do YY, h:mm a')
        }
      })
      .catch(error => {
        console.log(error)
      })
    store.dispatch('events/getEventLineUps', id)
  },
  [LINE_UPS](state, id) {
    axios
      .get(process.env.VUE_APP_API_BASE_URL + '/api/event/' + id + '/lineup')
      .then(response => {
        if (response.data.success) {
          state.lineUps = response.data.data
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
  [ADD_EVENT](state, formData) {
    var eventDate = { date: { start: formData.eventDate.date.start, end: formData.eventDate.date.end } }
    eventDate.date.start = moment(formData.eventDate.date.start).format('X')
    eventDate.date.end = moment(formData.eventDate.date.end).format('X')
    state.addEventForm.isFormSubmitted = true
    if (formData.isRecurring) {
      axios.post(process.env.VUE_APP_API_BASE_URL + '/api/event/add', {
        type: formData.type,
        eventDate: +eventDate.date.start,
        eventEndDate: +eventDate.date.end,
        name: formData.name,
        note: formData.note,
        lockBeforeInHours: formData.lockBeforeInHours,
        recurring: formData.isRecurring,
        eventInterval: formData.eventInterval,
        recurringEndDate: formData.recurringEndDate,
        recurringEndDateDatetime: formData.recurringEndDate,
        userGroups: formData.userGroups.map((group) => { return { id: group } })
      })
        .then(response => {
          if (response.data.message) {
            Toastify({
              duration: 2000,
              text: response.data.message,
              newWindow: true,
              style: {
                backgroundColor: '#91C714',
                color: 'white',
                padding: '10px'
              },
              gravity: 'top',
              position: 'right',
              stopOnFocus: true
            }).showToast()
          } else {
            Toastify({
              duration: 2000,
              text: response.data.message,
              newWindow: true,
              style: {
                backgroundColor: '#D32929',
                color: 'white',
                padding: '10px'
              },
              gravity: 'top',
              position: 'right',
              stopOnFocus: true
            }).showToast()
          }
          state.addEventForm.isSuccess = response.data.success
        })
    } else {
      axios.post(process.env.VUE_APP_API_BASE_URL + '/api/event/add', {
        type: formData.type,
        eventDate: +eventDate.date.start,
        eventEndDate: +eventDate.date.end,
        name: formData.name,
        note: formData.note,
        lockBeforeInHours: formData.lockBeforeInHours,
        recurring: formData.isRecurring,
        userGroups: formData.userGroups.map((group) => { return { id: group } })
      })
        .then(response => {
          if (response.data.message) {
            Toastify({
              duration: 2000,
              text: response.data.message,
              newWindow: true,
              style: {
                backgroundColor: '#91C714',
                color: 'white',
                padding: '10px'
              },
              gravity: 'top',
              position: 'right',
              stopOnFocus: true
            }).showToast()
          } else {
            Toastify({
              duration: 2000,
              text: response.data.message,
              newWindow: true,
              style: {
                backgroundColor: '#D32929',
                color: 'white',
                padding: '10px'
              },
              gravity: 'top',
              position: 'right',
              stopOnFocus: true
            }).showToast()
          }
          state.addEventForm.isSuccess = response.data.success
        })
    }
    state.addEventForm.isSuccess = null
  },
  [ADD_BOAT_TO_LINE_UPS](state, selectedBoat) {
    var lineUpBoat = {
      boatType: selectedBoat.boatType.boatType,
      boatSeats: {}
    }
    if (selectedBoat.boatType.cox) {
      lineUpBoat.boatSeats.c =
      {
        seat: 'c',
        name: null,
        userId: null
      }
    }
    for (var i = 1; i <= selectedBoat.boatType.crewSize; i++) {
      lineUpBoat.boatSeats[i] = {
        seat: i,
        name: null,
        userId: null
      }
    }
    state.lineUps.lineups.push(lineUpBoat)
  },
  [ADD_ATHLETE_TO_BOAT](state, params) {
    var next = state.lineUps.lineups.length - 1
    if (params.index == 'c') {
      state.lineUps.lineups[next].boatSeats.c.name = params.name
      state.lineUps.lineups[next].boatSeats.c.userId = params.id
    } else {
      state.lineUps.lineups[next].boatSeats[params.index].name = params.name
      state.lineUps.lineups[next].boatSeats[params.index].userId = params.id
    }
  },
  [SUBMIT_LINE_UP](state, eventId) {
    axios.post(process.env.VUE_APP_API_BASE_URL + '/api/event/' + eventId + '/' + 'lineup', {
      lineups: state.lineUps.lineups
    })
      .then(response => {
        if (response.data.message) {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#91C714',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        } else {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#D32929',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
  [UPDATE_EVENT](state, formData) {
    axios.put(process.env.VUE_APP_API_BASE_URL + '/api/me/event/' + formData.id + '/' + 'rsvp', {
      rsvp: formData.rsvp
    })
      .then(response => {
        if (response.data.message) {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#91C714',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        } else {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#D32929',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        }
        state.updateRsvp.isSuccess = response.data.success
        state.updateRsvp.response = response.data.message
      })
      .catch(err => {
        console.log(err)
      })
    state.updateRsvp.isSuccess = null
    state.updateRsvp.response = null
  },
  [UPDATE_RSVP_FOR_ATHLETE](state, formData) {
    axios.put(process.env.VUE_APP_API_BASE_URL + '/api/event/' + formData.eventId + '/rsvp-user/' + formData.id, {
      rsvp: formData.rsvp
    })
      .then(response => {
        if (response.data.message) {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#91C714',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        } else {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#D32929',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        }
        state.updateRsvpForAthlete.isSuccess = response.data.success
        state.updateRsvpForAthlete.response = response.data.message
      })
      .catch(err => {
        console.log(err)
      })
    state.updateRsvpForAthlete.isSuccess = null
    state.updateRsvpForAthlete.response = null
  },
  [DELETE_EVENT](state, params) {
    axios.delete(process.env.VUE_APP_API_BASE_URL + '/api/event/' + params)
      .then((response) => {
        state.events.filter((event) => {
          return event.id != params
        })
        state.events
        if (response.data.message) {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#91C714',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        } else {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#D32929',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        }
        state.deleteEvent.isSuccess = response.data.success
      })
    state.deleteEvent.isSuccess = null
  },
  [DELETE_ALL_RECURRING_EVENTS](state, params) {
    axios.delete(process.env.VUE_APP_API_BASE_URL + '/api/event/recurring/' + params)
      .then((response) => {
        state.events.filter((event) => {
          return event.id != params
        })
        state.events
        if (response.data.message) {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#91C714',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        } else {
          Toastify({
            duration: 2000,
            text: response.data.message,
            newWindow: true,
            style: {
              backgroundColor: '#D32929',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        }
        state.deleteAllRecurringEvents.isSuccess = response.data.success
      })
    state.deleteAllRecurringEvents.isSuccess = null
  },
  [DELETE_LINE_UP](state, params) {
    state.lineUps.lineups.splice(params.lineUpIndex, 1)
    axios.post(process.env.VUE_APP_API_BASE_URL + '/api/event/' + params.eventId + '/' + 'lineup', {
      lineups: state.lineUps.lineups
    })
      .then(response => {
        if (response.data.message) {
          Toastify({
            duration: 2000,
            text: 'line up succesfully deleted',
            newWindow: true,
            style: {
              backgroundColor: '#91C714',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        } else {
          Toastify({
            duration: 2000,
            text: 'error',
            newWindow: true,
            style: {
              backgroundColor: '#D32929',
              color: 'white',
              padding: '10px'
            },
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
